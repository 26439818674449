import {useForm} from "react-hook-form";
import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import {CreateJobObject} from "../types";
import {FormToggleButtonGroup, MultiSelectOption} from "../../../components/forms/FormToggleButtonGroup";
import {fetchFieldOfActivities, selectFieldOfActivities} from "../../../state/basedata/fieldOfActivitiesSlice";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";

const baseIconPath = "/areaOfActivityIcons/";

export type CreateJobAreaFormData = {
    areaOfActivity: string | null,

}

function Area({currentObject, onNext, onPrevious}: MultiPageComponentProps<CreateJobObject>) {
    const dispatch = useDispatch();

    const fieldOfActivities = useSelector(selectFieldOfActivities);

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchFieldOfActivities())
    }, [dispatch])


    const formContext = useForm<CreateJobAreaFormData>({
        defaultValues: {
            areaOfActivity: null,
        }
    })
    const {formState: {errors, isValid}, control, handleSubmit, clearErrors} = formContext;

    const saveData = (data: CreateJobAreaFormData) => {
        if(data.areaOfActivity !== currentObject.areaOfActivity) {
            const newCreateJobObject: CreateJobObject = {
                ...currentObject,
                areaOfActivity: data.areaOfActivity != null ? data.areaOfActivity : "",
                skills: [],
                tools: [],
                priorities: [],
            }
            return newCreateJobObject;
        }
        return currentObject;
    }

    const handlePrevious = () => {
        const data: CreateJobAreaFormData = formContext.getValues();
        onPrevious && onPrevious(saveData(data), isValid);
    }

    const onSubmit = (data: CreateJobAreaFormData) => {
        onNext && onNext(saveData(data), true);
    };
    const handleValidationFailed = () => {
        const data: CreateJobAreaFormData = formContext.getValues();
        onNext && onNext(saveData(data), false);
    };

    useEffect(() => {
        if (currentObject) {
            formContext.setValue("areaOfActivity", currentObject.areaOfActivity || null);
        }
    }, [])

    formContext.watch(() => {
        clearErrors();
    })

    const areaOfActivitiesOption: MultiSelectOption[] = fieldOfActivities.map((field) => {
        return {
            businessKey: field.businessKey,
            description: field.description,
            icon: `${baseIconPath}${field.businessKey}.svg`,
        }
    })

    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit, handleValidationFailed)} onReset={handlePrevious}>
            <FormToggleButtonGroup
                control={control}
                name="areaOfActivity"
                label="Bitte gib an, in welcher Branche der Job liegt."
                rules={{required: true}}
                error={!!errors.areaOfActivity}
                errorMessage="Bitte eine Option auswählen"
                options={areaOfActivitiesOption}
                exclusive
                sameSize
            />
        </form>
    );
}

export default Area;