import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";
import {BoxProps} from "@mui/material/Box";

export const ChatCardContainer = styled(Box)<BoxProps>({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '350px',
    '& > *': {
        borderBottom: '1px solid #ccc',
    },
    overflow: 'auto',
})