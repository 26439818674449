import React, {useContext, useState} from 'react';
import {useValidEmail, useValidPassword} from "../../hooks/useAuthHooks";
import {AuthContext} from "../../contexts/authContext";
import {useNavigate} from "react-router-dom";
import {Box, Grid, Paper, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {Email, Password} from "../../components/authComponents";

function SignUp() {
    const {email, setEmail, emailIsValid} = useValidEmail('')
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const {password, setPassword, passwordIsValid} = useValidPassword('')
    const [error, setError] = useState('')
    const [created, setCreated] = useState(false)

    const {
        password: passwordConfirm,
        setPassword: setPasswordConfirm,
        passwordIsValid: passwordConfirmIsValid,
    } = useValidPassword('')

    const isValid =
        !emailIsValid ||
        email.length === 0 ||
        !passwordIsValid ||
        password.length === 0 ||
        !passwordConfirmIsValid ||
        passwordConfirm.length === 0 ||
        firstname.length === 0 ||
        lastname.length === 0

    const navigate = useNavigate()
    const authContext = useContext(AuthContext)

    const signInClicked = async () => {
        try {
            await authContext.signUpWithEmail(email, password, firstname, lastname)
            setCreated(true)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            }
        }
    }

    const signUp = (
        <>
            <Box m={1}>
                <Email emailIsValid={emailIsValid} setEmail={setEmail}/>
            </Box>
            <Box m={1}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label={"Vorname"}
                    onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setFirstname(evt.target.value)
                    }}
                />
            </Box>
            <Box m={1}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label={"Nachname"}
                    onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setLastname(evt.target.value)
                    }}
                />
            </Box>
            <Box m={1}>
                <Password label="Passwort" passwordIsValid={passwordIsValid} setPassword={setPassword}/>
            </Box>
            <Box m={1}>
                <Password label="Passwort wiederholen" passwordIsValid={passwordConfirmIsValid}
                          setPassword={setPasswordConfirm}/>
            </Box>
            <Box mt={2}>
                <Typography color="error" variant="body1">
                    {error}
                </Typography>
            </Box>

            {/* Buttons */}
            <Box mt={2}>
                <Grid container direction="row">
                    <Box m={1}>
                        <Button onClick={() => navigate(-1)} color="inherit" variant="contained">
                            Abbrechen
                        </Button>
                    </Box>
                    <Box m={1}>
                        <Button disabled={isValid} color="primary" variant="contained" onClick={signInClicked}>
                            Account anlegen
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </>
    )

    const accountCreated = (
        <>
            <Typography>{`Es wurde ein Code zur Bestätigung ihrer Email an ${email} gesendet.`}</Typography>
            <Box m={4}>
                <Button onClick={() => navigate('/verify')} color="primary" variant="contained">
                    Jetzt Code eingeben
                </Button>
            </Box>
        </>
    )

    return (
        <Paper style={{width: 480, padding: 16}}>
            <Grid container direction="column">
                {/* Title */}
                <Box m={3}>
                    <Grid container direction="row">
                        <Typography variant="h3">Unternehmens-Account erstellen</Typography>
                    </Grid>
                </Box>
                {!created ? signUp : accountCreated}
            </Grid>
        </Paper>
    )
}

export default SignUp;