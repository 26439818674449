import React, {useContext, useState} from 'react';
import {useValidCode, useValidEmail, useValidPassword} from "../../hooks/useAuthHooks";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/authContext";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Code, Email, Password} from "../../components/authComponents";
import {ROOT} from "../../config/Router";

function ForgotPassword() {
    const {code, setCode, codeIsValid} = useValidCode('')
    const {password, setPassword, passwordIsValid} = useValidPassword('')
    const {email, setEmail, emailIsValid} = useValidEmail('')
    const [error, setError] = useState('')
    const [reset, setReset] = useState(false)

    const {
        password: passwordConfirm,
        setPassword: setPasswordConfirm,
        passwordIsValid: passwordConfirmIsValid,
    } = useValidPassword('')

    const isValid =
        !codeIsValid ||
        code.length === 0 ||
        !emailIsValid ||
        email.length === 0 ||
        !passwordIsValid ||
        password.length === 0 ||
        !passwordConfirmIsValid ||
        passwordConfirm.length === 0

    const navigate = useNavigate()
    const authContext = useContext(AuthContext)

    const resetPassword = async () => {
        try {
            await authContext.forgotPassword(email, code, password)
            setReset(true)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            }
        }
    }

    const updatePassword = (
        <>
            <Box width="80%" m={1}>
                <Code codeIsValid={codeIsValid} setCode={setCode}/>
            </Box>
            <Box width="80%" m={1}>
                <Email emailIsValid={emailIsValid} setEmail={setEmail}/>
            </Box>
            <Box width="80%" m={1}>
                <Password label="Passwort" passwordIsValid={passwordIsValid} setPassword={setPassword}/>
            </Box>
            <Box width="80%" m={1}>
                <Password label="Passwort bestätigen" passwordIsValid={passwordConfirmIsValid}
                          setPassword={setPasswordConfirm}/>
            </Box>

            {/* Error */}
            <Box mt={2}>
                <Typography color="error" variant="body1">
                    {error}
                </Typography>
            </Box>

            {/* Buttons */}
            <Box mt={2}>
                <Grid container direction="row">
                    <Box m={1}>
                        <Button onClick={() => navigate(-1)} color="secondary" variant="contained">
                            Abbrechen
                        </Button>
                    </Box>
                    <Box m={1}>
                        <Button disabled={isValid} color="primary" variant="contained" onClick={resetPassword}>
                            Passwort ändern
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </>
    )

    const passwordReset = (
        <>
            <Typography variant="h5">{`Passwort zurücksetzen`}</Typography>
            <Box m={4}>
                <Button onClick={() => navigate(ROOT)} color="primary" variant="contained">
                    Zum Login
                </Button>
            </Box>
        </>
    )

    return (
        <Paper style={{width: 480, padding: 16}}>
            <Grid container direction="column">
                {/* Title */}
                <Box m={3}>
                    <Grid container direction="row">
                        <Typography variant="h3">Passwort vergessen</Typography>
                    </Grid>
                </Box>

                {!reset ? updatePassword : passwordReset}
            </Grid>
        </Paper>
    )
}

export default ForgotPassword;