import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL || "http://localhost:9000"

export const getAccessToken = () => {
    const accessToken = window.localStorage.getItem("accessToken");
    return accessToken !== null ? accessToken : '';
}

export const axiosInstance = (accessToken: string) => axios.create({
    baseURL: backendUrl,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${accessToken}`,
    },
})
