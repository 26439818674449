import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import DetailPageContainer from "../../components/layout/pageLayouts/DetailPageContainer";
import BreadcrumbNavigation from "../../components/navigation/BreadcrumbNavigation";
import {LinkData} from "../../components/commonTypes";
import {URI_JOBS} from "../../config/Router";
import {Box, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {StyledChip} from "../../components/chips/StyledChip";
import BaseCard from "../../components/cards/BaseCard";
import StyledSlider from "../../components/slider/StyledSlider";
import BenefitsAvatar from "../../components/avatar/BenefitsAvatar";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentApplicationMenuItem} from "../../state/menu/applicationMenuSlice";
import {fetchJob, selectJob, selectJobInEditMode, selectJobStatus} from "../../state/jobs/jobSlice";
import {useLocation, useParams} from "react-router-dom";
import {JobDetailObjectTo} from "../../api/tos/jobs/JobDetailObjectTo";
import {MultilineTypography} from "../../components/typographys/MultilineTypography";
import CustomPopover, {TitleBox} from "../../components/controls/CustomPopover";
import {UpdateJobDetailObjectTo} from "../../api/tos/jobs/UpdateJobDetailObjectTo";
import CustomEditButton from "../../components/buttons/CustomEditButton";
import {selectCreateJobObject, setCreateJobObject} from "../../state/jobs/createJobSlice";
import {
    createJobObjectToUpdateJobTo,
    jobDetailToToCreateJobObject,
    jobToToUpdateJobTo
} from "../../services/jobService";
import {updateCurrentJob} from "../../state/jobs/updateJobSlice";
import {selectFormCurrentPage, setFormCurrentPage} from "../../state/multipageform/multipageFormSlice";
import {newJobPages} from "./NewJob";
import {CreateJobObject, JobStatus} from "./types";
import {displayDate} from "../../services/dateService";
import {MultiSelectOption} from "../../components/forms/FormToggleButtonGroup";
import {selectCompany} from "../../state/company/companySlice";
import {PaymentCancelPopup, PaymentSuccessPopup} from "../../components/controls/JobPaymentPopups";
import InfoPopup from "../../components/controls/InfoPopup";
import {FormTextField} from "../../components/forms/FormTextField";
import {useForm} from "react-hook-form";


function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface PopoverProps {
    anchorEl: HTMLElement | null,
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>,
    jobObject: JobDetailObjectTo,
    updateJobObject: (obj: UpdateJobDetailObjectTo) => void,
}

interface ItemProps {
    job: JobDetailObjectTo,
    updateJob: (updatedJob: UpdateJobDetailObjectTo) => void,
    editable: boolean,
}

const getAnchorElem = () => document.getElementById("detailPageContainer");

function JobPopover({anchorEl, setAnchorEl, jobObject, updateJobObject}: PopoverProps) {
    const dispatch = useDispatch();
    const createJobObject = useSelector(selectCreateJobObject);
    const currentPage = useSelector(selectFormCurrentPage);

    const priorityInfoText = `Bei der Bearbeitung deines Jobs hast du mindestens ${ currentPage === 4 ? "eine priorisierte Fertigkeit" : "ein priorisierten  Programmskill"} gelöscht.\nBitte überprüfe im nächsten Schritt die Priorisierungen und füge ggf. eine neue hinzu.\n\nWichtig: Wenn du die Priorisierung nicht speicherst, werden auch die überarbeiteten ${currentPage === 4 ? 'Fertigkeiten' : 'Programmskills'} nicht übernommen.`
    const [openPriorityInfo, setOpenPriorityInfo] = useState(false)

    const handleAcceptPriorityInfo = () => {
        setOpenPriorityInfo(false);
        dispatch(setFormCurrentPage(6));
    }

    const handleDeclinePriorityInfo = () => {
        setOpenPriorityInfo(false);
    }

    const handleDecline = () => {
        setAnchorEl(null);
        dispatch(setCreateJobObject(jobDetailToToCreateJobObject(jobObject)))
    }

    const onNext = (newObject: CreateJobObject, valid: boolean) => {
        if (valid) {
            if((currentPage === 4 || currentPage === 5) && newObject.priorities?.length !== jobObject.priorities.length) { // currentPage is skills or tools and priorities have been updated -> need to adjust priorities
                dispatch(setCreateJobObject(newObject));
                setOpenPriorityInfo(true);
            } else {
                const updatedJobObject = createJobObjectToUpdateJobTo(newObject, jobObject.id)
                if (updatedJobObject) {
                    updateJobObject(updatedJobObject);
                    setAnchorEl(null);
                }
            }
        } else {
            dispatch(setCreateJobObject(newObject));
        }
    }

    useEffect(() => {
        dispatch(setCreateJobObject(jobDetailToToCreateJobObject(jobObject)));
    }, [dispatch, jobObject])

    const pageObject = newJobPages[currentPage - 1];

    return (
        <>
            <CustomPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} onDecline={handleDecline}>
                {pageObject.page({currentObject: createJobObject, onNext: onNext})}
            </CustomPopover>
            {openPriorityInfo && (
                <InfoPopup text={priorityInfoText} open={openPriorityInfo} onAbort={handleDeclinePriorityInfo} onAccept={handleAcceptPriorityInfo} />
            )}
        </>
    )
}

type ZipCodeFormData = {
    zipCode: string,
}
function ZipCodePopover({anchorEl, setAnchorEl, jobObject, updateJobObject}: PopoverProps) {
    const formContext = useForm<ZipCodeFormData>({
        defaultValues: {
            zipCode: "",
        }
    })
    const {formState: {errors}, control, handleSubmit} = formContext;


    const handleDecline = () => {
        setAnchorEl(null);
        formContext.setValue("zipCode", jobObject.address.postCode)
    }

    const saveData = (data: ZipCodeFormData) => {
        const newJobObject: JobDetailObjectTo = {
            ...jobObject,
            address: {...jobObject.address, postCode: data.zipCode}
        }
        return newJobObject;
    }

    const onNext = (jobTo: JobDetailObjectTo, valid: boolean) => {
        if (valid) {
            updateJobObject(jobToToUpdateJobTo(jobTo));
            setAnchorEl(null);
        }
    }

    const onSubmit = (data: ZipCodeFormData) => {
        onNext(saveData(data), true);
    };

    useEffect(() => {
        formContext.setValue("zipCode", jobObject.address.postCode)
    }, [])

    return (
        <CustomPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} onDecline={handleDecline}>

            <form id="multipage_form" onSubmit={handleSubmit(onSubmit)}>
                <FormTextField
                    control={control}
                    name="zipCode"
                    rules={{required: true}}
                    label="PLZ"
                    error={errors.zipCode}
                    errorMessage="Bitte Postleitzahl hinzufügen oder Unternehmensadresse übernehmen"
                    type="number"
                />
            </form>
        </CustomPopover>
    )
}

function JobDetailsGeneral({job, updateJob, editable, zipCodeEditable}: ItemProps & {zipCodeEditable: boolean}) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [anchorElZipCode, setAnchorElZipCode] = React.useState<HTMLElement | null>(null);

    const handleClick = () => {
        dispatch(setFormCurrentPage(1));
        setAnchorEl(getAnchorElem());
    };
    const handleClickZipCode = () => {
        setAnchorElZipCode(getAnchorElem());
    };

    return (
        <Box mt='20px'>
            {editable
                ? <div>
                    <JobPopover
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        jobObject={job}
                        updateJobObject={updateJob}
                    />
                    <TitleBox>
                        <Typography variant="h1">{job.title}</Typography>
                        <CustomEditButton mode="edit" onClick={handleClick}/>
                    </TitleBox>
                </div>
                : <Typography variant="h1">{job.title}</Typography>
            }
            {zipCodeEditable && (
                <ZipCodePopover
                    anchorEl={anchorElZipCode}
                    setAnchorEl={setAnchorElZipCode}
                    jobObject={job}
                    updateJobObject={updateJob}
                />
            )}
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap" alignItems="center">
                <StyledChip label={job.locationType.description}/>
                <StyledChip label={job.payGrade.description}/>
                {job.furtherEducation &&
                    <StyledChip label="Weiterbildung möglich"/>
                }
                <Typography variant="body1">{job.address.postCode}</Typography>
                {zipCodeEditable && (
                    <CustomEditButton mode="edit" onClick={handleClickZipCode}/>
                )}
            </Stack>
            <Box style={{height: '20px'}} />
            <MultilineTypography variant="body1">
                {job.description}
            </MultilineTypography>
        </Box>
    );
}

function JobDetailsTimeScope({job, updateJob, editable}: ItemProps) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = () => {
        dispatch(setFormCurrentPage(2));
        setAnchorEl(getAnchorElem());
    };

    return (
        <Box mt='20px'>
            {editable
                ? <div>
                    <JobPopover
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        jobObject={job}
                        updateJobObject={updateJob}
                    />
                    <TitleBox>
                        <Typography variant="h2">Umfang & Zeitraum</Typography>
                        <CustomEditButton mode="edit" onClick={handleClick}/>
                    </TitleBox>
                </div>
                : <Typography variant="h2">Umfang & Zeitraum</Typography>
            }
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                {job.scopeOfActivities && job.scopeOfActivities.map((scopeOfActivity, index) => (
                    <StyledChip key={index} label={scopeOfActivity.description}/>
                ))}
                <StyledChip label={`${job.hoursPerWeek} Std./Woche`}/>
                <StyledChip label={`${job.vacationDaysPerYear} Urlaubstage`}/>
            </Stack>
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                <Typography variant="subtitle1">{`Beginn: ${displayDate(job.startDate)}`}</Typography>
                {job.endDate && job.endDate !== ""
                    && <Typography variant="subtitle1">{`Ende: ${displayDate(job.endDate)}`}</Typography>
                }
            </Stack>
        </Box>
    );
}

function JobDetailsFertigkeiten({job, updateJob, editable}: ItemProps) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = () => {
        dispatch(setFormCurrentPage(4));
        setAnchorEl(getAnchorElem());
    };

    return (
        <Box mt='20px'>
            {editable
                ? <div>
                    <JobPopover
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        jobObject={job}
                        updateJobObject={updateJob}
                    />
                    <TitleBox>
                        <Typography variant="h2">Fertigkeiten</Typography>
                        <CustomEditButton mode="edit" onClick={handleClick}/>
                    </TitleBox>
                </div>
                : <Typography variant="h2">Fertigkeiten</Typography>
            }
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                {job.skills.map((skill, key) => (
                    <StyledChip key={key} label={skill.description}/>
                ))}
            </Stack>
        </Box>
    );
}

function JobDetailsProgrammskills({job, updateJob, editable}: ItemProps) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = () => {
        dispatch(setFormCurrentPage(5));
        setAnchorEl(getAnchorElem());
    };

    return (
        <Box mt='20px'>
            {editable
                ? <div>
                    <JobPopover
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        jobObject={job}
                        updateJobObject={updateJob}
                    />
                    <TitleBox>
                        <Typography variant="h2">Programmskills</Typography>
                        <CustomEditButton mode="edit" onClick={handleClick}/>
                    </TitleBox>
                </div>
                : <Typography variant="h2">Programmskills</Typography>
            }
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                {job.noToolsNeeded
                    ? (
                        <Typography>Keine Programmskills erforderlich</Typography>
                    ) : job.tools.map((tool, key) => (
                        <StyledChip key={key} label={tool.description}/>
                    ))
                }
            </Stack>
        </Box>
    );
}

function JobDetailsPriorities({job, updateJob, editable}: ItemProps) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = () => {
        dispatch(setFormCurrentPage(6));
        setAnchorEl(getAnchorElem());
    };

    return (
        <Box mt='20px'>
            {editable
                ? <div>
                    <JobPopover
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        jobObject={job}
                        updateJobObject={updateJob}
                    />
                    <TitleBox>
                        <Typography variant="h2">Priorisierung</Typography>
                        <CustomEditButton mode="edit" onClick={handleClick}/>
                    </TitleBox>
                </div>
                : <Typography variant="h2">Priorisierung</Typography>
            }
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                {job.priorities.length === 0
                    ? (
                        <Typography>Keine Fertigkeiten oder Programme gewählt</Typography>
                    ) : job.priorities.map((priority, key) => (
                        <StyledChip key={key} label={priority.description}/>
                    ))
                }
            </Stack>
        </Box>
    );
}

function JobDetailsSprachen({job, updateJob, editable}: ItemProps) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = () => {
        dispatch(setFormCurrentPage(7));
        setAnchorEl(getAnchorElem());
    };

    return (
        <Box mt='20px'>
            {editable
                ? <div>
                    <JobPopover
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        jobObject={job}
                        updateJobObject={updateJob}
                    />
                    <TitleBox>
                        <Typography variant="h2">Sprachen</Typography>
                        <CustomEditButton mode="edit" onClick={handleClick}/>
                    </TitleBox>
                </div>
                : <Typography variant="h2">Sprachen</Typography>
            }
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                {job.languageRequirements.map((language, key) => (
                    <Grid item key={key}>
                        <BaseCard id={key} title={language.language.description}
                                  description={`${language.languageLevel.description} - ${language.languageRequirement.description}`}/>
                    </Grid>
                ))}
            </Stack>
        </Box>
    );
}

function JobDetailsSoftSkills({job, updateJob, editable}: ItemProps) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = () => {
        dispatch(setFormCurrentPage(8));
        setAnchorEl(getAnchorElem());
    };

    return (
        <Box mt='20px'>
            {editable
                ? <div>
                    <JobPopover
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        jobObject={job}
                        updateJobObject={updateJob}
                    />
                    <TitleBox>
                        <Typography variant="h2">Soft skills</Typography>
                        <CustomEditButton mode="edit" onClick={handleClick}/>
                    </TitleBox>
                </div>
                : <Typography variant="h2">Soft skills</Typography>
            }
            <Grid container spacing={6} rowSpacing={3}>
                <Grid item xs={6}>
                    <Typography variant="h3">Neurotizismus</Typography>
                    <StyledSlider value={job.softSkillDetails.neuroticism}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Extraversion</Typography>
                    <StyledSlider value={job.softSkillDetails.extraversion}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Gewissenhaftigkeit</Typography>
                    <StyledSlider value={job.softSkillDetails.conscientiousness}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Offenheit</Typography>
                    <StyledSlider value={job.softSkillDetails.openness}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Verträglichkeit</Typography>
                    <StyledSlider value={job.softSkillDetails.compatibility}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Machtmotiv</Typography>
                    <StyledSlider value={job.softSkillDetails.powerMotive}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Sicherheitsmotiv</Typography>
                    <StyledSlider value={job.softSkillDetails.safetyMotive}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Leistungsmotiv</Typography>
                    <StyledSlider value={job.softSkillDetails.performanceMotive}/>
                </Grid>
            </Grid>
        </Box>
    );
}

function JobDetailsBenefits() {
    const company = useSelector(selectCompany);

    const benefits: MultiSelectOption[] | undefined = company?.benefits.map((benefit) => {
        return {
            businessKey: benefit.businessKey,
            description: benefit.description,
            icon: `${benefit.businessKey}.svg`,
        }
    })

    if (!benefits) {
        return <></>;
    }

    return (
        <Box my="20px">
            <Typography variant="h2">Benefits</Typography>
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                {benefits.map((benefit, key) => (
                    <BenefitsAvatar key={key} tooltipTitle={benefit.description} src={benefit.icon}/>
                ))}
            </Stack>
        </Box>
    );
}

function JobDetails() {
    let query = useQuery();
    let showPopup = query.get("publishSuccess")
    let showCancelPopup = query.get("publishCancel")
    const dispatch = useDispatch();
    const job = useSelector(selectJob);
    const status = useSelector(selectJobStatus);
    const inEditMode = useSelector(selectJobInEditMode);


    const {jobId} = useParams<{ jobId: string }>()

    useEffect(() => {
        dispatch(setCurrentApplicationMenuItem("Meine Jobs"))
        // @ts-ignore
        dispatch(fetchJob(jobId))
    }, [dispatch, jobId])

    const breadcrumbs: LinkData[] = [
        {title: "Job Übersicht", path: URI_JOBS}
    ];

    if (job === undefined || status === 'loading') {
        return <></>
    }

    const updateJob = (updatedJob: UpdateJobDetailObjectTo) => {
        // @ts-ignore
        dispatch(updateCurrentJob({
            updateJobTo: updatedJob,
            onLoadFunction: () => {
                // @ts-ignore
                dispatch(fetchJob(updatedJob.id))
            }
        }))
    }

    const isNewJob = job.status.businessKey === JobStatus.NEW.toString();

    return (
        <>
            {showPopup && <PaymentSuccessPopup job={job}/>}
            {showCancelPopup && <PaymentCancelPopup job={job}/>}
            <DetailPageContainer>
                <BreadcrumbNavigation links={breadcrumbs} lastItem={job.title}/>
                <JobDetailsGeneral job={job} editable={isNewJob} zipCodeEditable={inEditMode} updateJob={updateJob}/>
                <JobDetailsTimeScope job={job} editable={isNewJob} updateJob={updateJob}/>
                <JobDetailsFertigkeiten job={job} editable={isNewJob || inEditMode} updateJob={updateJob}/>
                <JobDetailsProgrammskills job={job} editable={isNewJob || inEditMode} updateJob={updateJob}/>
                <JobDetailsPriorities job={job} editable={isNewJob || inEditMode} updateJob={updateJob}/>
                <JobDetailsSprachen job={job} editable={isNewJob || inEditMode} updateJob={updateJob}/>
                <JobDetailsSoftSkills job={job} editable={isNewJob || inEditMode} updateJob={updateJob}/>
                <JobDetailsBenefits/>
            </DetailPageContainer>
        </>
    );
}

export default JobDetails;