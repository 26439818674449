import * as React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";

interface ContentPage3ColumnsLayoutProps {
    children: string | React.ReactElement | React.ReactElement[]
    childrenRight?: string | React.ReactElement | React.ReactElement[]
    childrenLeft?: string | React.ReactElement | React.ReactElement[]
}

function ContentPage3ColumnsLayout(props: ContentPage3ColumnsLayoutProps) {
    const style = {marginTop: '18px', padding: '10px'};
    return (
        <>
            <Grid2 container>
                <Grid2 xs={3}>
                    <div style={{...style, marginLeft: '28px'}}>
                        {props.childrenLeft}
                    </div>
                </Grid2>
                <Grid2 xs={6}>
                    <div style={style}>
                        {props.children}
                    </div>
                </Grid2>
                <Grid2 xs={3}>
                    <div style={{...style, marginRight: '28px', display: 'flex', justifyContent: 'left'}}>
                        {props.childrenRight}
                    </div>
                </Grid2>
            </Grid2>
        </>)
}

export default ContentPage3ColumnsLayout;