import {styled} from "@mui/material/styles";
import Chip, {ChipProps} from "@mui/material/Chip";
import {MatchSegmentation} from "../../api/tos/matches/MatchDetailObjectTo";

interface StyledChipProps extends ChipProps {
    match?: MatchSegmentation,
}

export const StyledChip = styled(Chip)<StyledChipProps>(({match, theme}) => {
    let styles;
    let commonStyles = {
        fontWeight: 'bold'
    }

    switch (match) {
        case MatchSegmentation.MATCH:
            styles = {
                ...commonStyles,
                backgroundColor: theme.palette.primary.light,
                border: `2px solid ${theme.palette.primary.main}`,
                color: theme.palette.text.primary
            };
            break;
        case MatchSegmentation.ADDITIONAL:
            styles = {
                ...commonStyles,
                backgroundColor: theme.palette.secondary.light,
                border: `2px solid ${theme.palette.secondary.main}`,
                color: theme.palette.text.primary
            };
            break;
        case MatchSegmentation.MISSING:
            styles = {
                ...commonStyles,
                backgroundColor: theme.palette.background.default,
                border: `2px dashed ${theme.palette.text.disabled}`,
                color: theme.palette.text.disabled
            };
            break;
        default:
            styles = {
                ...commonStyles,
                backgroundColor: 'white',
                border: 'none',
                color: theme.palette.text.primary
            };
            break;
    }

    return styles;
});