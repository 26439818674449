import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './state/store';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./config/theme";
import Router, {SignedOutRouter} from "./config/Router";

import '@fontsource-variable/asap'
import AuthProvider, {AuthIsNotSignedIn, AuthIsSignedIn} from "./contexts/authContext";

import ReactGA from "react-ga4";
import FirestoreContextProvider from "./contexts/firestoreContext";

const container = document.getElementById('root')!;
const root = createRoot(container);

ReactGA.initialize("G-TW3H7VJ1Y4");

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                    <AuthIsSignedIn>
                        <FirestoreContextProvider>
                            <Router />
                        </FirestoreContextProvider>
                    </AuthIsSignedIn>
                    <AuthIsNotSignedIn>
                        <SignedOutRouter />
                    </AuthIsNotSignedIn>
                </AuthProvider>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);
