import {styled} from "@mui/material/styles";
import {Typography} from "@mui/material";
import {ChatBorders} from "./ChatBorders";

export const ChatTitleTypography = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    height: '54px',
    minHeight: '54px',
    borderBottom: ChatBorders,
    paddingLeft: '20px',
    width: '100%',
})