import * as React from 'react';
import BaseCard from "./BaseCard";
import {JobOverviewObjectTo} from "../../api/tos/jobs/JobOverviewObjectTo";
import {URI_JOBS} from "../../config/Router";
import {dayDif, displayDate} from "../../services/dateService";
import JobActionDialog from "../controls/JobActionDialog";
import {ButtonProps} from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import {Box, Button, Typography} from "@mui/material";
import {JobStatus} from "../../pages/jobs/types";


interface JobDetailCardProps {
    job: JobOverviewObjectTo,
}


const StyledButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: theme.palette.background.paper,
    borderRadius: 6,
    paddingTop: '3px',
    paddingBottom: '3px',
}))

function DialogButton(props: ButtonProps) {
    return (
        <StyledButton {...props} variant="contained">
            <Typography variant="subtitle2">{props.children}</Typography>
        </StyledButton>
    );
}

function JobDetailCard({job}: JobDetailCardProps) {
    const {id, title, expirationDate} = job;

    const onClickURI = `${URI_JOBS}/${id}`;
    const difInDays = dayDif(expirationDate, new Date())

    const status = job.status.businessKey;
    let description = "";

    switch (status) {
        case JobStatus.NEW.toString(): {
            description = `Noch nicht veröffentlicht`;
            break;
        }
        case JobStatus.PUBLISHED.toString(): {
            description = `Restlaufzeit: ${difInDays} Tag${difInDays === 1 ? "" : "e"}`;
            break;
        }
        case JobStatus.CLOSED.toString(): {
            description = `Abgelaufen am: ${displayDate(expirationDate)}`;
        }
    }

    return (
        <BaseCard
            id={id}
            title={title}
            description={description}
            onClickURI={onClickURI}
            bottomChild={
                <Box maxWidth="200px">
                    <JobActionDialog job={job} button={DialogButton} loadAllJobs={true}/>
                </Box>
            }
        />
    );
}

export default JobDetailCard;