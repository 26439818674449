import {StatusButton} from "../buttons/CommonButtons";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {Box, MenuItem, useTheme} from "@mui/material";
import {MouseEventHandler, useEffect, useRef, useState} from "react";
import Menu from "@mui/material/Menu";
import {useDispatch, useSelector} from "react-redux";
import {fetchMatchCompanyStatus, selectMatchCompanyStatus} from "../../state/basedata/matchCompanyStatusSlice";
import {styled} from "@mui/material/styles";
import {changeCompanyStatus} from "../../state/matches/matchSlice";
import {fetchMatches} from "../../state/matches/matchesSlice";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled(Menu)(({theme}) => ({
    '.MuiList-root': {
        padding: 0,
    },
}));

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
    }
}));

const StyledResetItem = styled(MenuItem)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
    }
}));

const MenuConnectArrow = styled(Box)(({theme}) => ({
    position: 'absolute',
    right: '-20px',
    color: theme.palette.secondary.light,
    minWidth: 0,
    maxWidth: '20px',
    zIndex: 9000,
}));

interface IProps {
    matchId: number;
    companyStatus: BaseDataObjectTo;
}

const NO_STATUS_TEXT = "Status ändern";
const RESET_STATUS_TEXT = "Kein Status";

function MatchCompanyStatusControl(props: IProps) {
    const {matchId, companyStatus} = props;
    const dispatch = useDispatch();
    const theme = useTheme();

    const arrowRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const matchCompanyStatus = useSelector(selectMatchCompanyStatus);

    const label = companyStatus ? companyStatus.description : NO_STATUS_TEXT;

    const handleClickMenu:MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        setAnchorEl(anchorEl ? null : arrowRef.current);
    }

    const handleRefresh = () => {
        //@ts-ignore
        dispatch(fetchMatches());
    }

    const getHandleClickItem = (value: string | null) => () => {
        //@ts-ignore
        dispatch(changeCompanyStatus({id: matchId, newStatus: value, onSuccess: handleRefresh}));
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchMatchCompanyStatus())
    }, [])

    return (
      <StatusButton sx={{width: '100%', position: 'relative'}} onClick={handleClickMenu}>
          <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              {label}
              {!Boolean(anchorEl) && (
                  <KeyboardArrowRightIcon sx={{fontSize: theme.typography.body1.fontSize}}/>
              )}
              {Boolean(anchorEl) && (
                  <KeyboardArrowDownIcon sx={{fontSize: theme.typography.body1.fontSize}}/>
              )}
          </Box>
          <MenuConnectArrow ref={arrowRef}>
              {Boolean(anchorEl) && (
                  <PlayArrowIcon sx={{transform: 'rotate(180deg) scaleX(2)'}}/>
              )}
          </MenuConnectArrow>
          <StyledMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
          >

              {matchCompanyStatus.map((status) => (
                  <StyledMenuItem
                      key={status.businessKey}
                      onClick={getHandleClickItem(status.businessKey)}
                  >
                      {status.description}
                  </StyledMenuItem>
              ))}
              <StyledResetItem
                  onClick={getHandleClickItem(null)}
              >
                  {RESET_STATUS_TEXT}
              </StyledResetItem>
          </StyledMenu>
      </StatusButton>
    );
}

export default MatchCompanyStatusControl;