import {ReactNode, useEffect} from "react";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {fetchCompany, selectCompany, selectFetchCompanyStatus} from "../state/company/companySlice";
import {Typography} from "@mui/material";
import ImageBackground from "../components/layout/pageContent/ImageBackground";
import {URI_COMPANY_NEW_PROFILE, URI_COMPANY_PROFILE, URI_JOBS_DETAILS} from "./Router";

interface GuardProps {
    children: ReactNode,
}

function Guard({children}: GuardProps) {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const company = useSelector(selectCompany);
    const companyStatus = useSelector(selectFetchCompanyStatus);


    const currentUrl = window.location.pathname;

    useEffect(() => {
        if (!company) {
            // @ts-ignore
            dispatch(fetchCompany());
        }
    }, [company])

    useEffect(() => {
        let publishSuccess = searchParams.get("publishSuccess")
        let publishCancel = searchParams.get("publishCancel")
        let jobId = searchParams.get("jobId")

        if (companyStatus === "ok" && !!company && currentUrl !== URI_COMPANY_PROFILE && currentUrl !== URI_COMPANY_NEW_PROFILE) {
            if ((publishSuccess || publishCancel)) {
                if (publishSuccess) {
                    // @ts-ignore
                    navigate(URI_JOBS_DETAILS.replace(":jobId", jobId) + "?publishSuccess=true")
                }
                if (publishCancel) {
                    // @ts-ignore
                    navigate(URI_JOBS_DETAILS.replace(":jobId", jobId) + "?publishCancel=true")
                }
            } else {
                // navigate(ROOT);
            }
        } else if (companyStatus === "ok" && !company) {
            navigate(URI_COMPANY_NEW_PROFILE);
        }
    }, [currentUrl, navigate, searchParams, company, companyStatus])

    if (companyStatus === "initial" || companyStatus === "loading") {
        return <ImageBackground><Typography variant="h3">Loading...</Typography></ImageBackground>
    } else if (companyStatus === "failed") {
        return <ImageBackground><Typography variant="h3">Fehler...</Typography></ImageBackground>
    }

    return (
        <div>{children}</div>
    )
}

export default Guard;