import {LanguageObject} from "./newJob/Languages";

export interface CreateJobObject {
    title?: string,
    description?: string,
    expirationDate?: string,
    status?: string,
    benefits?: string[],
    remote?: string,
    zipCode?: string,
    useCompanyAddress?: boolean,
    payGrade?: string,
    scopeOfActivities?: string[],
    areaOfActivity?: string,
    skills?: string[],
    noToolsNeeded?: boolean,
    tools?: string[],
    priorities?: string[],
    languages?: LanguageObject[],
    characteristics?: number[],
    trainingPossible?: boolean,
    isLimited?: boolean,
    startDate?: string, // ISO-String
    endDate?: string, // ISO-String
    hoursPerWeek?: string,
    vacationDaysPerYear?: string,
}

export class JobStatus {
    private constructor(public readonly value: string) {}

    toString(): string {
        return this.value;
    }

    static readonly NEW = new JobStatus('NEW');
    static readonly PUBLISHED = new JobStatus('PUBLISHED');
    static readonly CLOSED = new JobStatus('CLOSED');
}