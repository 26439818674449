import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/authContext";
import {useValidCode, useValidEmail} from "../../hooks/useAuthHooks";
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import {Code, Email} from "../../components/authComponents";
import {ROOT} from "../../config/Router";
// import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

function VerifyCode() {
    const {email, setEmail, emailIsValid} = useValidEmail('')
    const {code, setCode, codeIsValid} = useValidCode('')
    const [error, setError] = useState('')

    const isValid = !emailIsValid || email.length === 0 || !codeIsValid || code.length === 0

    const navigate = useNavigate()

    const authContext = useContext(AuthContext)

    const sendClicked = async () => {
        try {
            await authContext.verifyCode(email, code)
            ReactGA4.event("company_register_conversion", {
                category: 'Conversion',
                label: '18rkCITR3dwYEK7p2a0p',
                send_to: 'AW-11101697198/18rkCITR3dwYEK7p2a0p',
            })
            navigate(ROOT)
        } catch (err) {
            setError('Invalid Code')
        }
    }

    const passwordResetClicked = async () => {
        navigate('/resetpassword')
    }

    return (
        <Paper style={{width: 480, padding: 32}}>
            <Grid container direction="column">
                {/* Title */}
                <Box m={2}>
                    <Typography variant="h3">Verifizierungscode eingeben</Typography>
                </Box>
                {/* Sign In Form */}
                <Box width="80%" m={1}>
                    <Email emailIsValid={emailIsValid} setEmail={setEmail}/>{' '}
                </Box>
                <Box width="80%" m={1}>
                    <Code codeIsValid={codeIsValid} setCode={setCode}/>
                    <Grid container direction="row">
                        <Box onClick={passwordResetClicked} mt={2}>
                            <Typography variant="body1">
                                Code erneut senden
                            </Typography>
                            <Box mt={2}>
                                <Typography color="error" variant="body1">
                                    {error}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Box>

                {/* Buttons */}
                <Box mt={2}>
                    <Grid container direction="row">
                        <Box m={1}>
                            <Button color="inherit" variant="contained" onClick={() => navigate(-1)}>
                                Abbrechen
                            </Button>
                        </Box>
                        <Box m={1}>
                            <Button disabled={isValid} color="primary" variant="contained"
                                    onClick={sendClicked}>
                                Senden
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Paper>
    )
}

export default VerifyCode;