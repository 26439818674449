import * as React from 'react';
import {ReactElement} from 'react';
import {Button, MenuList} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import {ButtonProps} from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    selectFormCurrentPage,
    selectFormLastCompletePage,
    setFormCurrentPage
} from "../../state/multipageform/multipageFormSlice";

const StyledButton = styled(Button)<ButtonProps>(({theme}) => ({
    textDecoration: 'none',
    color: theme.palette.text.primary,
    padding: 0,
}))

export interface MultiPageComponentProps<T> {
    currentObject: T,
    onNext?: (newObject: T, valid: boolean) => void,
    onPrevious?: (newObject: T, valid: boolean) => void,
}

export interface MultiFormPageI<T> {
    title: string,
    page: (props: MultiPageComponentProps<T>) => ReactElement<MultiPageComponentProps<T>>,
}
interface MultiPageFormMenuProps<T> {
    items: MultiFormPageI<T>[],
}


function MultiPageFormMenu<T>({items}: MultiPageFormMenuProps<T>) {
    const dispatch = useDispatch();
    const currentPage = useSelector(selectFormCurrentPage);
    const lastCompletePage = useSelector(selectFormLastCompletePage);

    const isLinkAvailable = (pageCount: number) => {
        return pageCount <= lastCompletePage + 1;
    }

    const handleClick = (pageCount: number) => {
        if(isLinkAvailable(pageCount)) {
            dispatch(setFormCurrentPage(pageCount));
        }
    }

    return (
        <MenuList sx={{}}>
            {items.map((menuItem, key) =>
                <MenuItem key={key}>
                    <StyledButton onClick={() => handleClick(key+1)}>
                        <Typography
                            textAlign="center"
                            lineHeight="1.75rem"
                            variant={key+1 === currentPage ? "subtitle1" :  "body1"}
                            color={isLinkAvailable(key+1) ? "text.main" : "text.disabled"}
                        >
                            {`${key + 1}. ${menuItem.title}`}
                        </Typography>
                    </StyledButton>
                </MenuItem>
            )}
        </MenuList>
    );
}

export default MultiPageFormMenu;