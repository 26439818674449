import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getCompanyBenefits} from "../../api/baseDataApi";

export interface LanguageState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: LanguageState = {
    value: [],
    status: 'ok'
}

export const fetchCompanyBenefits = createAsyncThunk(
    'baseData/fetchCompanyBenefits',
    async () => {
        return await getCompanyBenefits().then(value => value.data)
    }
);

export const selectCompanyBenefits = (state: RootState) => state.companyBenefits.value
export const selectCompanyBenefitByBusinessKey = (state: RootState) => {
    const companyBenefits = state.companyBenefits.value;
    return (id: string | null) => companyBenefits.find((language) => language.businessKey === id);
};

export const companyBenefitsSlice = createSlice({
    name: 'companyBenefits',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchCompanyBenefits.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchCompanyBenefits.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchCompanyBenefits.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default companyBenefitsSlice.reducer
