import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getJobLocations} from "../../api/baseDataApi";

export interface JobLocationsState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: JobLocationsState = {
    value: [],
    status: 'ok'
}

export const fetchJobLocations = createAsyncThunk(
    'baseData/fetchLocations',
    async () => {
        return await getJobLocations().then(value => value.data)
    }
);

export const selectJobLocations = (state: RootState) => state.jobLocations.value

export const jobLocationsSlice = createSlice({
    name: 'languageLevels',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchJobLocations.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchJobLocations.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchJobLocations.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default jobLocationsSlice.reducer
