import {Controller, FieldError, FieldPath, FieldValues, UseControllerProps} from "react-hook-form";
import Typography from "@mui/material/Typography";
import {Box, TextField} from "@mui/material";
import React from "react";

export interface TextFieldControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TFieldName> {
  label: string,
  error?: FieldError | undefined,
  errorMessage?: string,
  disabled?: boolean,
  multiline?: boolean,
  minRows?: number,
  maxRows?: number,
  type?: "text" | "number",
}

export function FormTextField<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    control,
    name,
    rules,
    label,
    error,
    errorMessage,
    disabled,
    multiline,
    minRows,
    maxRows,
    type
  }: TextFieldControllerProps<TFieldValues, TFieldName>) {

  return (
    <Controller
      control={control}
      name={name}
      render={({field}) =>
        <Box display="flex" flex={1} flexDirection="column" paddingTop="10px">
          <Typography variant="subtitle1">{label}</Typography>
          <TextField
            {...field}
            error={!!error}
            spellCheck
            disabled={disabled}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            style={{width: type === "number" ? "50%": "100%"}}
            type={type}
            InputProps={{
            }}
          />
          {error?.type === "required" && <Typography color="error">{errorMessage}</Typography>}
          {error?.type === "maxLength" && <Typography color="error">Eintrag zu lang</Typography>}
        </Box>
      }
      rules={rules}
    />
  );
}
