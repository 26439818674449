import {Controller, FieldPath, FieldValues, UseControllerProps} from "react-hook-form";
import Typography, {TypographyProps} from "@mui/material/Typography";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, {AccordionSummaryProps} from "@mui/material/AccordionSummary";
import {AccordionDetails, AccordionProps, Box, ToggleButton, ToggleButtonGroup, ToggleButtonProps} from "@mui/material";
import React, {useState} from "react";
import {BoxProps} from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import FallbackImage from "../images/FallbackImage";
import {SkillDataObjectTo} from "../../api/tos/baseData/SkillDataObjectTo";
import {ArrowBack} from "@mui/icons-material";

export interface MultiSelectOption extends SkillDataObjectTo {
    icon?: string,
    exclusive?: boolean,
}

interface ItemBoxProps extends BoxProps {
    selected?: boolean,
    invalid?: number, // TODO warum funktioniert boolean nicht??
}

const StyledTypography = styled(Typography)<TypographyProps>(() => ({
    display: 'flex',
    textAlign: 'center',
    paddingX: '5px',
    lineHeight: '1.2rem',
    wordBreak: 'break-word',
}))


const LabelBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
}))


const StyledToggleButton = styled(ToggleButton)<ToggleButtonProps>(() => ({
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'none',
    '&.Mui-selected': {
        padding: 0,
        margin: 0,
        border: 'none',
        background: 'none',
    },
    '&.Mui-selected:hover': {
        padding: 0,
        margin: 0,
        border: 'none',
        background: 'none',
    },
    '&:hover': {
        padding: 0,
        margin: 0,
        border: 'none',
        background: 'none',
    },
}))

const StyledBox = styled(Box)<ItemBoxProps>(({selected, invalid, theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '1rem',
    padding: '5px',
    marginLeft: '5px',
    marginRight: '5px',
    width: '100%',
    height: '100%',
    border: invalid ? `2px solid ${theme.palette.error.main}` : selected ? `2px solid ${theme.palette.secondary.main}` : `2px solid ${theme.palette.primary.main}`,
    backgroundColor: selected ? theme.palette.secondary.light : theme.palette.background.paper,
    color: selected ? theme.palette.text.primary : theme.palette.primary.main,
}))


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion defaultExpanded square {...props} />
))(({ theme }) => ({
    backgroundColor: `${theme.palette.background.default}`,
    boxShadow: 'none',
}));

const AccordionSummary = styled(MuiAccordionSummary)<AccordionSummaryProps>(({ theme }) => ({
    backgroundColor: `${theme.palette.text.secondary}`,
    '&.Mui-expanded .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)', // Rotate the expandIcon to 90°
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 0,
        margin: 0,
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
        margin: '10px 0'
    },
    '&.MuiAccordionSummary-root': {
        minHeight: 0,
        margin: 0,
    },
    '.MuiAccordionSummary-content': {
        margin: '10px 0'
    },
}));

export interface ToggleButtonGroupControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TFieldName> {
    label?: string,
    options: MultiSelectOption[],
    exclusive?: boolean,
    maxSelect?: number,
    sameSize?: boolean,
    error?: boolean,
    errorMessage?: string,
}

export function FormToggleButtonGroupWithAccordion<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
      control,
      name,
      rules,
      label,
      options,
      exclusive,
      maxSelect,
      sameSize,
      error,
      errorMessage,
  }: ToggleButtonGroupControllerProps<TFieldValues, TFieldName>) {

    const [tooManySelected, setTooManySelected] = useState(false);

    return (
        <Controller
            control={control}
            name={name}
            render={({field}) => {
                const fieldArray = exclusive ? [field.value] : [...field.value];
                const isSelected = (option: MultiSelectOption) => {
                    return fieldArray.includes(option.businessKey);
                }

                const isExclusive = (value: string) => {
                    return options.find((option) => option.businessKey === value)?.exclusive;
                }

                return (
                    <Box display="flex" flex={1} flexDirection="column" paddingY="10px">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ArrowBack />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>{label}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{padding: '5px 1px'}}>
                                <ToggleButtonGroup
                                    {...field}
                                    onChange={(event, newSelectedValues) => {
                                        if (!exclusive && Array.isArray(newSelectedValues)) {
                                            // in multiselect mode
                                            const hasExclusiveValue = newSelectedValues.some(isExclusive);
                                            if(hasExclusiveValue) {
                                                // some selected value is exclusive, so only last selected value will be used
                                                field.onChange(newSelectedValues.slice(-1));
                                                setTooManySelected(false);
                                            } else if(maxSelect && newSelectedValues.length > maxSelect){
                                                // there is a limit to number of selections, so the last selected values will be ignored
                                                field.onChange(newSelectedValues.slice(0, maxSelect));
                                                setTooManySelected(true);
                                            } else {
                                                // no restrictions
                                                field.onChange(newSelectedValues);
                                                setTooManySelected(false);
                                            }
                                        } else {
                                            // single select or null values
                                            field.onChange(newSelectedValues);
                                            setTooManySelected(false);
                                        }
                                    }}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        rowGap: '10px',
                                    }}
                                    exclusive={exclusive}
                                >
                                    {options.map((option) => (
                                                    <StyledToggleButton
                                                        key={option.businessKey}
                                                        value={option.businessKey}
                                                        sx={sameSize ? {width: {xs: '50%', sm: '33%', md: '25%', lg: '20%'}} : {}}
                                                    >
                                                        <StyledBox selected={isSelected(option)} invalid={+!!error}>
                                                            {option.icon &&
                                                                <FallbackImage
                                                                    alt="icon"
                                                                    src={option.icon}
                                                                    style={{height: '2.75rem', paddingTop: '5px', paddingBottom: '5px'}}/>
                                                            }
                                                            <LabelBox>
                                                                <StyledTypography variant={isSelected(option) ? "subtitle1" : "body1"}>
                                                                    {option.description}
                                                                </StyledTypography>
                                                            </LabelBox>
                                                        </StyledBox>
                                                    </StyledToggleButton>
                                        ))}
                                </ToggleButtonGroup>
                            </AccordionDetails>
                        </Accordion>
                        {/* options.length === 0 && <Typography variant="body1" color="error.main">{"Keine Option verfügbar"}</Typography> */}
                        {error && <Typography variant="body1" color="error.main">{errorMessage}</Typography>}
                        {tooManySelected && <Typography variant="body1" color="error.main">{`Du kannst nicht mehr als ${maxSelect} Optionen auswählen`}</Typography>}
                    </Box>
                );
            }}
            rules={rules}
        />
    );
}
