import {useForm} from "react-hook-form";
import * as React from "react";
import {useEffect, useState} from "react";
import {selectCreateJobObject} from "../../../state/jobs/createJobSlice";
import {useDispatch, useSelector} from "react-redux";
import SearchBox from "../../../components/controls/SearchBox";
import {FormToggleButtonGroup} from "../../../components/forms/FormToggleButtonGroup";
import {Box, Button, Typography} from "@mui/material";
import {CreateJobObject} from "../types";
import {styled} from "@mui/material/styles";
import {BoxProps} from "@mui/material/Box";
import {ButtonProps} from "@mui/material/Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {fetchLanguages, selectLanguageByBusinessKey, selectLanguages} from "../../../state/basedata/languagesSlice";
import {
    fetchLanguageRequirements,
    selectLanguageRequirementByBusinessKey,
    selectLanguageRequirements
} from "../../../state/basedata/languageRequirementsSlice";
import {
    fetchLanguageLevels,
    selectLanguageLevelByBusinessKey,
    selectLanguageLevels
} from "../../../state/basedata/languageLevelsSlice";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";


export interface LanguageObject {
    languageId: string | null,
    levelId: string | null,
    requiredId: string | null,
}


const LanguageContainer = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '10px',
    gap: '10px',
}))
const LanguageBox = styled(Box)<BoxProps>(({theme}) => ({
    display: 'flex',
    flex: 1,
    padding: '5px',
    paddingLeft: '15px',
    flexDirection: 'column',
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.light,
}))

const StyledButton = styled(Button)<ButtonProps>(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    paddingLeft: '15px',
    paddingRight: '15px',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
    },
    '&:disabled': {
        backgroundColor: theme.palette.text.disabled,
        color: theme.palette.background.paper,
    },
}))
function AddButton() {
    return (
        <Box paddingY="10px">
            <StyledButton type="submit" >
                <Typography variant="subtitle1">Hinzufügen</Typography>
            </StyledButton>
        </Box>
    );
}


interface SelectedLanguagesProps {
    items: LanguageObject[],
    updateItems: (items: LanguageObject[]) => void,
}
function SelectedLanguages({items, updateItems}: SelectedLanguagesProps) {
    const createJobObject = useSelector(selectCreateJobObject);
    const getLanguageById = useSelector(selectLanguageByBusinessKey);
    const getLevelById = useSelector(selectLanguageLevelByBusinessKey);
    const getRequirementById = useSelector(selectLanguageRequirementByBusinessKey);

    const removeItem = (item: LanguageObject) => {
        const newLanguages = createJobObject.languages?.filter((language) => language !== item)
        if(newLanguages) {
            updateItems(newLanguages);
        }
    }

    return (
        <LanguageContainer>
            {items.map((item, key) => (
                <LanguageBox key={key}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{getLanguageById(item.languageId)?.description}</Typography>
                        <Button onClick={() => removeItem(item)}>
                            <CloseOutlinedIcon />
                        </Button>
                    </Box>
                    <Typography variant="body1">{getLevelById(item.levelId)?.description} - {getRequirementById(item.requiredId)?.description}</Typography>
                </LanguageBox>
            ))}
        </LanguageContainer>
    )
}


function Languages({currentObject, onNext, onPrevious}: MultiPageComponentProps<CreateJobObject>) {
    const dispatch = useDispatch();

    const languages = useSelector(selectLanguages);
    const languageLevels = useSelector(selectLanguageLevels);
    const languageRequirements = useSelector(selectLanguageRequirements);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);


    useEffect(() => {
        //@ts-ignore
        dispatch(fetchLanguages())
        //@ts-ignore
        dispatch(fetchLanguageLevels())
        //@ts-ignore
        dispatch(fetchLanguageRequirements())
    }, [dispatch])

    const formPageContext = useForm()

    const formContext = useForm<LanguageObject>({
        defaultValues: {
            languageId: null,
            levelId: null,
            requiredId: null,
        }
    })
    const {formState: {errors}, control, handleSubmit, clearErrors} = formContext;

    const updateLanguages = (items: LanguageObject[]) => {
        const newCreateJobObject: CreateJobObject = {
            ...currentObject,
            languages: items,
        }
        onNext && onNext(newCreateJobObject, false);
    }

    const onSubmit = (data: LanguageObject) => {
        const newLanguages: LanguageObject[] = [];
        if (currentObject.languages) {
            newLanguages.push(...currentObject.languages);
        }
        newLanguages.push(data);
        formContext.reset();

        updateLanguages(newLanguages);
    }

    formContext.watch(() => {
        clearErrors();
    })

    const hasLanguageEntry = currentObject.languages && currentObject.languages.length > 0;
    const usedLanguages = currentObject.languages?.map((language) => language.languageId).filter((languageId) => languageId !== null);
    const filteredLanguages = languages
        .filter((language) => {
            return (searchTerm === null || searchTerm === "")
            || language.description.toLowerCase().includes(searchTerm.toLowerCase())
        })
        .filter((language) => !(usedLanguages && usedLanguages.includes(language.businessKey)))


    const onPageSubmit = () => {
        onNext && onNext(currentObject, !!hasLanguageEntry);
    }

    const onPagePrevious = () => {
        onPrevious && onPrevious(currentObject, !!hasLanguageEntry);
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <SearchBox label="Sprache suchen" onKeyChange={(text: string | null) => {setSearchTerm(text)}}/>
                <FormToggleButtonGroup
                    control={control}
                    name="languageId"
                    label=""
                    options={filteredLanguages}
                    exclusive
                    rules={{required: true}}
                    error={!!errors.languageId}
                    errorMessage="Bitte eine Sprache auswählen"
                />
                <Box height="20px"></Box>
                <FormToggleButtonGroup
                    control={control}
                    name="levelId"
                    label=""
                    options={languageLevels}
                    exclusive
                    rules={{required: true}}
                    error={!!errors.levelId}
                    errorMessage="Bitte eine Level auswählen"
                />
                <Box height="20px"></Box>
                <FormToggleButtonGroup
                    control={control}
                    name="requiredId"
                    label=""
                    options={languageRequirements}
                    exclusive
                    rules={{required: true}}
                    error={!!errors.requiredId}
                    errorMessage="Bitte eine Option auswählen"
                />
                <AddButton />
            </form>
            <form id="multipage_form" onSubmit={formPageContext.handleSubmit(onPageSubmit)} onReset={onPagePrevious} />
            <SelectedLanguages items={currentObject.languages || []} updateItems={updateLanguages} />
        </>
    );
}

export default Languages;