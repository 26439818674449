import React from 'react'
import {TextField} from '@mui/material';

export const Email: React.FunctionComponent<{ emailIsValid: boolean; setEmail: (_: string) => void }> =
    ({emailIsValid, setEmail}) => {
        return (
            <TextField
                fullWidth
                variant="outlined"
                label={emailIsValid ? 'Email' : 'Email nicht gültig'}
                error={!emailIsValid}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setEmail(evt.target.value)
                }}
            />
        )
    }

export const Password: React.FunctionComponent<{
    label: string
    passwordIsValid: boolean
    setPassword: (_: string) => void
}> = ({ label, passwordIsValid, setPassword }) => {
    return (
        <TextField
            fullWidth
            type="password"
            variant="outlined"
            label={passwordIsValid ? label : 'Mindestens 8 Zeichen'}
            error={!passwordIsValid}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                setPassword(evt.target.value)
            }}
        />
    )
}

export const Code: React.FunctionComponent<{ codeIsValid: boolean; setCode: (_: string) => void }> = ({
codeIsValid,setCode}) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            label={codeIsValid ? 'Code' : 'Mindestens 6 Zeichen'}
            error={!codeIsValid}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                setCode(evt.target.value)
            }}
        />
    )
}