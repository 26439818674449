import {useForm} from "react-hook-form";
import * as React from "react";
import {useEffect, useState} from "react";
import {FormTextField} from "../../../components/forms/FormTextField";
import {FormSwitch} from "../../../components/forms/FormSwitch";
import {useDispatch, useSelector} from "react-redux";
import {CreateJobObject} from "../types";
import {FormToggleButtonGroup} from "../../../components/forms/FormToggleButtonGroup";
import {fetchJobPayGrades, selectJobPayGrades} from "../../../state/basedata/jobPayGradesSlice";
import {fetchJobLocations, selectJobLocations} from "../../../state/basedata/jobLocationsSlice";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";
import {selectCompany} from "../../../state/company/companySlice";
import {Box, Switch, Typography} from "@mui/material";

export type CreateJobGeneralFormData = {
    title: string,
    description: string,
    remote: string | null,
    useCompanyAddress: boolean,
    zipCode: string,
    payGrade: string | null,
    trainingsPossible: boolean,

}


function General({currentObject, onNext}: MultiPageComponentProps<CreateJobObject>) {
    const dispatch = useDispatch();

    const payGrades = useSelector(selectJobPayGrades);
    const locations = useSelector(selectJobLocations);

    const [useCompanyAddress, setUseCompanyAddress] = useState(false);
    const company = useSelector(selectCompany);


    useEffect(() => {
        //@ts-ignore
        dispatch(fetchJobPayGrades())
        //@ts-ignore
        dispatch(fetchJobLocations())
    }, [dispatch])

    const formContext = useForm<CreateJobGeneralFormData>({
        defaultValues: {
            title: "",
            description: "",
            remote: null,
            useCompanyAddress: false,
            zipCode: "",
            payGrade: null,
            trainingsPossible: false,
        }
    })
    const {formState: {errors}, control, handleSubmit, clearErrors} = formContext;


    const saveData = (data: CreateJobGeneralFormData) => {
        const newCreateJobObject: CreateJobObject = {
            ...currentObject,
            title: data.title,
            description: data.description,
            remote: data.remote != null ? data.remote : "",
            useCompanyAddress: data.useCompanyAddress,
            zipCode: data.zipCode,
            payGrade: data.payGrade != null ? data.payGrade : "",
            trainingPossible: data.trainingsPossible,
        }
        return newCreateJobObject;
    }

    const onSubmit = (data: CreateJobGeneralFormData) => {
        onNext && onNext(saveData(data), true);
    };
    const handleValidationFailed = () => {
        const data: CreateJobGeneralFormData = formContext.getValues();
        onNext && onNext(saveData(data), false);
    };


    useEffect(() => {
        if (currentObject) {
            formContext.setValue("title", currentObject.title || "");
            formContext.setValue("description", currentObject.description || "");
            formContext.setValue("remote", currentObject.remote || null);
            formContext.setValue("zipCode", currentObject.zipCode || "");
            formContext.setValue("payGrade", currentObject.payGrade || null);
            formContext.setValue("trainingsPossible", !!currentObject.trainingPossible);

            setUseCompanyAddress(!!currentObject.useCompanyAddress);
        }
    }, [])

    useEffect(() => {
        formContext.setValue("useCompanyAddress", useCompanyAddress);
        if(useCompanyAddress && !!company) {
            formContext.setValue("zipCode", company.address.postCode);
        }
    }, [formContext, company, useCompanyAddress])

    formContext.watch(() => {
        clearErrors();
    })

    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit, handleValidationFailed)}>
            <FormTextField
                control={control}
                name="title"
                rules={{required: true, maxLength: 300}}
                label="Jobbezeichnung"
                error={errors.title}
                errorMessage="Bitte Jobbezeichnung hinzufügen"
            />
            <FormTextField
                control={control}
                name="description"
                rules={{required: true, maxLength: 20000}}
                label="Über den Job"
                error={errors.description}
                errorMessage="Bitte Beschreibung hinzufügen"
                multiline={true}
                minRows={4}
                maxRows={10}
            />
            <FormToggleButtonGroup
                control={control}
                name="remote"
                label="Standort"
                rules={{required: true}}
                error={!!errors.remote}
                errorMessage="Bitte eine Option auswählen"
                options={locations}
                exclusive={true}
            />
            <Box display="flex" flexDirection="row" alignItems="center" mt="10px" ml="-10px">
                <Switch
                    value={useCompanyAddress}
                    onChange={(e) => setUseCompanyAddress(e.target.checked)}
                />
                <Typography variant="subtitle1">Unternehmensadresse als Standort übernehmen"</Typography>
            </Box>
            <FormTextField
                control={control}
                name="zipCode"
                rules={{required: true}}
                label="PLZ"
                error={errors.zipCode}
                errorMessage="Bitte Postleitzahl hinzufügen oder Unternehmensadresse übernehmen"
                type="number"
                disabled={useCompanyAddress}
            />
            <FormToggleButtonGroup
                control={control}
                name="payGrade"
                label="Gehaltsklasse"
                rules={{required: true}}
                error={!!errors.payGrade}
                errorMessage="Bitte eine Option auswählen"
                options={payGrades}
                exclusive={true}
            />
            <FormSwitch
                control={control}
                name="trainingsPossible"
                label="Es besteht die Möglichkeit eine Weiterbildung zu absolvieren"
            />
        </form>
    );
}

export default General;