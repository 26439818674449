import React, {useContext, useState} from 'react';
import {Box, Grid, Paper, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useValidEmail} from "../../hooks/useAuthHooks";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/authContext";
import {Email} from "../../components/authComponents";
import {LOGGED_OUT_FORGOT_PASSWORD} from "../../config/Router";

function RequestCode() {
    const {email, setEmail, emailIsValid} = useValidEmail('')
    const [error, setError] = useState('')
    const [resetSent, setResetSent] = useState(false)

    const isValid = !emailIsValid || email.length === 0

    const navigate = useNavigate();

    const authContext = useContext(AuthContext)

    const sendCodeClicked = async () => {
        try {
            await authContext.sendCode(email)
            setResetSent(true)
        } catch (err) {
            setError('Benutzer nicht gefunden')
        }
    }

    const emailSent = (
        <>
            <Box mt={1}>
                <Typography
                    variant="h5">{`Code zum zurücksetzen des Passwortes wurde an ${email} gesendet.`}</Typography>
            </Box>
            <Box mt={4}>
                <Button onClick={() => navigate(LOGGED_OUT_FORGOT_PASSWORD)} color="primary" variant="contained">
                    Passwort zurücksetzen
                </Button>
            </Box>
        </>
    )

    const sendCode = (
        <>
            <Box width="80%" m={1}>
                <Email emailIsValid={emailIsValid} setEmail={setEmail}/>
            </Box>
            <Box mt={2}>
                <Typography color="error" variant="body1">
                    {error}
                </Typography>
            </Box>

            <Box mt={2}>
                <Grid>
                    <Box m={1}>
                        <Button color="inherit" variant="contained" onClick={() => navigate(-1)}>
                            Abbrechen
                        </Button>
                    </Box>
                    <Box m={1}>
                        <Button disabled={isValid} color="primary" variant="contained" onClick={sendCodeClicked}>
                            Code senden
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </>
    )

    return (
        <Paper style={{width: 480, padding: 32}}>
            <Grid container direction="column">
                <Box m={2}>
                    <Typography variant="h3">Reset Code senden</Typography>
                </Box>

                {resetSent ? emailSent : sendCode}
            </Grid>
        </Paper>
    )
}

export default RequestCode;