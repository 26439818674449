import * as React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box, LinearProgress, LinearProgressProps} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {selectFormCurrentPage} from "../../../state/multipageform/multipageFormSlice";
import {MultiFormPageI} from "../../menus/MultiPageFormMenu";


interface MultiPageFormProps<T> {
    children: string | React.ReactElement | React.ReactElement[]
    childrenLeft?: string | React.ReactElement | React.ReactElement[]
    childrenRight?: string | React.ReactElement | React.ReactElement[]
    menuItems: MultiFormPageI<T>[]
}

const ProgressBar = styled(LinearProgress)<LinearProgressProps>(({theme}) => ({
    backgroundColor: theme.palette.text.secondary,
    height: 10,
}))


function MultiPageForm<T>(props: MultiPageFormProps<T>) {
    const currentPage = useSelector(selectFormCurrentPage);
    const style = {marginTop: '18px', padding: '10px'};

    const progress = currentPage * 100 / (props.menuItems.length + 1);

    return (
        <>
            <Box sx={{width: '100%'}}>
                <ProgressBar value={progress} variant="determinate" color="secondary"/>
            </Box>
            <Grid2 container>
                <Grid2 xs={3}>
                    <div style={{...style, marginLeft: '28px', marginTop: '46px'}}>
                        {props.childrenLeft}
                    </div>
                </Grid2>
                <Grid2 xs={6}>
                    <div style={style}>
                        {props.children}
                    </div>
                </Grid2>
                <Grid2 xs={3}>
                    <div style={{...style, marginRight: '28px'}}>
                        {props.childrenRight}
                    </div>
                </Grid2>
            </Grid2>
        </>)
}

export default MultiPageForm;