import * as React from 'react';
import BaseCard from "./BaseCard";
import {JobOverviewObjectTo} from "../../api/tos/jobs/JobOverviewObjectTo";
import {URI_JOBS} from "../../config/Router";


function JobOverviewCard(props: JobOverviewObjectTo) {
    const {id, title} = props;

    const onClickURI = `${URI_JOBS}/${id}`;
    const description = ``;

    return (
        <BaseCard
            id={id}
            title={title}
            description={description}
            onClickURI={onClickURI}
        />
    );
}

export default JobOverviewCard;