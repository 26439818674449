import {useEffect, useState} from 'react';

import {db} from "../config/firestoreConfig";
import {collection, onSnapshot, orderBy, query} from "firebase/firestore";
import {ChatOverviewObjectTo} from "../api/tos/chat/ChatOverviewObjectTo";
import {useFirestoreContext} from "../contexts/firestoreContext";

const USER_PATH = "user"
const OVERVIEW_PATH = "overview"

export const useChatOverview = () => {
    const {signedIn, chatUserId} = useFirestoreContext();
    // stores all chatIds
    const [availableChats, setAvailableChats] = useState<ChatOverviewObjectTo[]>([]);
    const [loadingComplete, setLoadingComplete] = useState(false);

    // adds new chats while ensuring unique chatId
    const addChats = (newChats: ChatOverviewObjectTo[]) => {
        setAvailableChats(newChats);
        setLoadingComplete(true);
    }

    // load availableChats
    useEffect(() => {
        if(signedIn && chatUserId) {
            const q = query(
                collection(db, USER_PATH, chatUserId, OVERVIEW_PATH),
                orderBy("lastSent", "desc")
            );
            // update messages and return unsubscriber to destructor
            return onSnapshot(q, (QuerySnapshot) => {
                const fetchedAvailableChats: ChatOverviewObjectTo[] = [];
                QuerySnapshot.forEach((doc) => {
                    fetchedAvailableChats.push(doc.data() as ChatOverviewObjectTo);
                });
                addChats(fetchedAvailableChats)
            });
        }
    }, [signedIn, chatUserId]);

    return { availableChats, loadingComplete };
}