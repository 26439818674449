import React, {ReactNode, useEffect} from 'react';
import PageContainer from "../../components/layout/pageLayouts/PageContainer";
import {useDispatch, useSelector} from "react-redux";
import {fetchJobs, selectJobs} from "../../state/jobs/jobsSlice";
import JobDetailCard from "../../components/cards/JobDetailCard";
import {setCurrentApplicationMenuItem} from "../../state/menu/applicationMenuSlice";
import {JobOverviewObjectTo} from "../../api/tos/jobs/JobOverviewObjectTo";
import {JobStatus} from "./types";
import TogglePages from "../../components/layout/pageContent/TogglePages";
import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    gap: '20px',
    paddingTop: '10px',
});

function JobsOverview() {
    const jobs: JobOverviewObjectTo[] = useSelector(selectJobs);
    const dispatch = useDispatch()

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchJobs())
        dispatch(setCurrentApplicationMenuItem("Meine Jobs"))
    }, [dispatch])

    const currentJobsTitle = `Aktuelle Jobs`;
    const pastJobsTitle = `Abgelaufene Jobs`;
    const unpublishedJobsTitle = `Unveröffentlichte Jobs`;

    const currentJobs = jobs.filter((job) => job.status.businessKey === JobStatus.PUBLISHED.toString());
    const pastJobs = jobs.filter((job) => job.status.businessKey === JobStatus.CLOSED.toString());
    const unpublishedJobs = jobs.filter((job) => job.status.businessKey === JobStatus.NEW.toString());

    const createJobCards = (jobs: JobOverviewObjectTo[]): ReactNode => {
        return (
            <Container>
                {jobs.map((job) => (
                    <JobDetailCard job={job} key={job.id}/>
                ))}
            </Container>
        );
    }

    return (
        <PageContainer title="Jobübersicht"
                       description="">
            <TogglePages
                items={[
                    {
                        title: `${currentJobsTitle} (${currentJobs.length})`,
                        description: "Hier findest du deine aktuellen Jobs, für die wir passende Talente suchen.",
                        content: createJobCards(currentJobs)
                    },
                    {
                        title: `${unpublishedJobsTitle} (${unpublishedJobs.length})`,
                        description: "Hier findest du die Entwürfe deiner noch nicht veröffentlichten Jobs.",
                        content: createJobCards(unpublishedJobs)
                    },
                    {
                        title: `${pastJobsTitle} (${pastJobs.length})`,
                        description: "Die Laufzeit dieser Jobs ist abgelaufen. Du kannst sie jederzeit wieder reaktivieren.",
                        content: createJobCards(pastJobs)
                    },
                ]}
            />
        </PageContainer>
    );
}

export default JobsOverview;