import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getJobPayGrades} from "../../api/baseDataApi";

export interface JobPayGradesState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: JobPayGradesState = {
    value: [],
    status: 'ok'
}

export const fetchJobPayGrades = createAsyncThunk(
    'baseData/fetchPayGrades',
    async () => {
        return await getJobPayGrades().then(value => value.data)
    }
);

export const selectJobPayGrades = (state: RootState) => state.jobPayGrades.value

export const jobPayGradesSlice = createSlice({
    name: 'jobPayGrades',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchJobPayGrades.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchJobPayGrades.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchJobPayGrades.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default jobPayGradesSlice.reducer
