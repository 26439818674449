import React, {SyntheticEvent, useState} from 'react';
import { Tabs, Tab } from '@mui/material';
import {styled} from "@mui/material/styles";

interface IProps {
    tabs: string[];
    setTabIndex: (index: number) => void;
}


const StyledTabs = styled(Tabs)(({theme}) => ({
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.primary.main}`
}));

const StyledTab = styled(Tab)(({theme}) => ({
    display: 'flex',
    flex: 1,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
}));


function NavbarTabs(props: IProps) {
    const {tabs, setTabIndex} = props;

    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setTabIndex(newValue);
    };

    return (
        <StyledTabs value={value} onChange={handleChange}>
            {tabs.map((tab, index) => (
                <StyledTab key={index} value={index} label={tab}/>
            ))}
        </StyledTabs>
    );
}

export default NavbarTabs;
