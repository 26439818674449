import React, {ReactNode} from "react";
import {Box} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ChatTitleTypography} from "./ChatTitleTypography";
import {ChatBorders} from "./ChatBorders";
import ChatEmptyScreen from "./ChatEmptyScreen";

interface IProps {
    searchBox: ReactNode;
    overview: ReactNode;
    details: ReactNode;
    isEmpty: boolean;
}

const Container = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '5px',
    border: ChatBorders,
    backgroundColor: theme.palette.background.paper,
    maxHeight: '80vh',
    height: '80vh',
}))

const NavColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    borderRight: ChatBorders,
})

function ChatOverviewLayout(props: IProps) {
    const {searchBox, overview, details, isEmpty} = props;

    return (
        <Container>
            {isEmpty ? (
                <Box display="flex" flexDirection="column" width="100%">
                    <ChatTitleTypography variant="h1">Nachrichten</ChatTitleTypography>
                    <ChatEmptyScreen />
                </Box>
            ): (
                <>
                    <NavColumn>
                        <ChatTitleTypography variant="h1">Nachrichten</ChatTitleTypography>
                        {searchBox}
                        {overview}
                    </NavColumn>
                    {details}
                </>
            )}
        </Container>
    )
}

export default ChatOverviewLayout