import {Grid} from "@mui/material";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {FormTextField} from "../../../components/forms/FormTextField";
import LabeledSwitch from "../../../components/controls/LabeledSwitch";
import {FormDatePicker} from "../../../components/forms/FormDatePicker";
import {useDispatch, useSelector} from "react-redux";
import {CreateJobObject} from "../types";
import {dateToString, stringToDate} from "../../../services/dateService";
import {FormToggleButtonGroup, MultiSelectOption} from "../../../components/forms/FormToggleButtonGroup";
import {fetchJobScopesOfActivity, selectJobScopesOfActivity} from "../../../state/basedata/jobScopesOfActivitySlice";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";


export type CreateJobTimeScopeFormData = {
    scopeOfActivities: string[] | null,
    startDate: Date,
    isLimited: boolean,
    endDate: Date,
    hoursPerWeek: string,
    vacationDaysPerYear: string,

}

const allowedScopeOfActivityMultiSelects = ['VOLLZEIT', 'TEILZEIT'];


function TimeScope({currentObject, onNext, onPrevious}: MultiPageComponentProps<CreateJobObject>) {
    const dispatch = useDispatch();

    const scopesOfActivity:MultiSelectOption[] = useSelector(selectJobScopesOfActivity)
        .map((scope) => {
            if(allowedScopeOfActivityMultiSelects.includes(scope.businessKey)){
                return scope;
            }
            return ({
                ...scope,
                exclusive: true,
            })
        });

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchJobScopesOfActivity())
    }, [dispatch])


    const formContext = useForm<CreateJobTimeScopeFormData>({
        defaultValues: {
            scopeOfActivities: [],
            startDate: new Date(),
            endDate: new Date(),
            hoursPerWeek: "",
            vacationDaysPerYear: "",
        }
    })
    const {formState: {errors, isValid}, control, handleSubmit, clearErrors} = formContext;
    const [isLimited, setLimited] = useState(false);

    const saveData = (data: CreateJobTimeScopeFormData) => {
        const newCreateJobObject: CreateJobObject = {
            ...currentObject,
            scopeOfActivities: data.scopeOfActivities ?? [],
            isLimited: data.isLimited,
            startDate: dateToString(data.startDate),
            endDate: isLimited ? dateToString(data.endDate) : undefined,
            hoursPerWeek: data.hoursPerWeek,
            vacationDaysPerYear: data.vacationDaysPerYear,
        }
        return newCreateJobObject;
    }

    const handlePrevious = () => {
        const data: CreateJobTimeScopeFormData = formContext.getValues();
        onPrevious && onPrevious(saveData(data), isValid);
    }

    const onSubmit = (data: CreateJobTimeScopeFormData) => {
        onNext && onNext(saveData(data), true);
    };
    const handleValidationFailed = () => {
        const data: CreateJobTimeScopeFormData = formContext.getValues();
        onNext && onNext(saveData(data), false);
    };

    useEffect(() => {
        if (currentObject) {
            formContext.setValue("scopeOfActivities", currentObject.scopeOfActivities ?? []);
            formContext.setValue("isLimited", !!currentObject.isLimited);
            formContext.setValue("startDate", stringToDate(currentObject.startDate));
            formContext.setValue("endDate", stringToDate(currentObject.endDate));
            formContext.setValue("hoursPerWeek", currentObject.hoursPerWeek ?? "");
            formContext.setValue("vacationDaysPerYear", currentObject.vacationDaysPerYear ?? "");
            setLimited(!!currentObject.endDate);
        }
    }, [])

    useEffect(() => {
        formContext.setValue("isLimited", isLimited)
    }, [formContext, isLimited])

    formContext.watch(() => {
        clearErrors();
    })

    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit, handleValidationFailed)} onReset={handlePrevious}>
            <FormToggleButtonGroup
                control={control}
                name="scopeOfActivities"
                label="Tätigkeitsumfang"
                rules={{required: true}}
                error={!!errors.scopeOfActivities}
                errorMessage="Bitte eine Option auswählen"
                options={scopesOfActivity}
                maxSelect={2}
            />
            <LabeledSwitch
                checked={isLimited}
                onChange={(event) => setLimited(event.target.checked)}
                label="Dies ist eine befristete Stelle"
            />
            <Grid container display="flex" flexDirection="row" spacing="10px" paddingTop="10px">
                <Grid item xs={12} md={6}>
                    <FormDatePicker
                        control={control}
                        name="startDate"
                        label="Beginn der Beschäftigung"
                        minDate={new Date()}
                        rules={{required: true}}
                    />
                </Grid>
                {isLimited &&
                    <Grid item xs={12} md={6}>
                        <FormDatePicker
                            control={control}
                            name="endDate"
                            label="Ende der Beschäftigung"
                            minDate={formContext.getValues("startDate")}
                            rules={{required: isLimited}}
                        />
                    </Grid>
                }
            </Grid>
            <Grid container display="flex" flexDirection="column">
                <Grid item xs={12} md={6}>
                    <FormTextField
                        control={control}
                        name="hoursPerWeek"
                        label="Arbeitsstunden pro Woche"
                        error={errors.hoursPerWeek}
                        errorMessage="Bitte Arbeitsstunden hinzufügen"
                        rules={{required: true}}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormTextField
                        control={control}
                        name="vacationDaysPerYear"
                        label="Urlaubstage pro Jahr"
                        error={errors.vacationDaysPerYear}
                        errorMessage="Bitte Urlaubstage hinzufügen"
                        rules={{required: true}}
                        type="number"
                    />
                </Grid>
            </Grid>
        </form>
    );
}

export default TimeScope;