import {useSelector} from "react-redux";
import {selectFormCurrentPage} from "../../state/multipageform/multipageFormSlice";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {MultilineTypography} from "../typographys/MultilineTypography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";

function NewJobControl() {
    const currentPage = useSelector(selectFormCurrentPage)

    if(currentPage === 8) {
        return (
            <Box width="100%" padding="20px" paddingTop="6rem">
                <Box padding="10px" display="flex" flex={1} flexDirection="column" borderRadius="5px" border="1px solid lightgray">
                    <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
                        <InfoOutlinedIcon color="secondary"/>
                        <Typography variant="subtitle1">Info</Typography>
                    </Box>
                    <MultilineTypography variant="body1">
                        {"Wir arbeiten mit B5T, Big-Five-Persönlichkeits-test, welcher seit 2010 zu den am häufgsten genutzten Persönlichkeitstest im deutschsprachigen Raum zählt.\n\nBewertet werde hier die fünf Persönlicheitsdimensionen (Neurotizismus, Extraversion, Gewissenhaftigkeit, Offenheit und Verträglichkeit), sowie die drei Hauptmotive nach Macht, Sicherheit und Leistung. Dadurch scannen wir die KandidatInnen auf ihre tatsächlichen Skills und gleichen diese mit deinem Anforderungsprofil ab.\n\nDer Test enthält zusätzlich eine Kontrolle, ob die KandidatInnen die Fragen wahrheitsgetreu beantwortet haben."}
                    </MultilineTypography>
                </Box>
            </Box>
        )
    }

    return (
        <></>
    )
}

export default NewJobControl;