import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getLanguageRequirements} from "../../api/baseDataApi";

export interface languageRequirementsState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: languageRequirementsState = {
    value: [],
    status: 'ok'
}

export const fetchLanguageRequirements
    = createAsyncThunk(
    'baseData/fetchlanguageRequirements',
    async () => {
        return await getLanguageRequirements().then(value => value.data)
    }
);

export const selectLanguageRequirements = (state: RootState) => state.languageRequirements.value
export const selectLanguageRequirementByBusinessKey = (state: RootState) => {
    const languageRequirements = state.languageRequirements.value;
    return (id: string | null) => languageRequirements.find((requirement) => requirement.businessKey === id);
};

export const languageRequirementsSlice = createSlice({
    name: 'languageRequirements',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchLanguageRequirements.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchLanguageRequirements.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchLanguageRequirements.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default languageRequirementsSlice.reducer
