import React from 'react';

interface DetailPageContainerProps {
    children: React.ReactElement | React.ReactElement[]
}

function DetailPageContainer(props: DetailPageContainerProps) {

    return (
        <div id="detailPageContainer">
            {props.children}
        </div>);
}

export default DetailPageContainer;
