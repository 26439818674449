import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

export interface ApplicationMenuState {
    currentItem: string;
}
const initialState: ApplicationMenuState = {
    currentItem: ""
}

export const selectCurrentApplicationMenuItem = (state: RootState) => state.applicationMenu.currentItem

export const applicationMenuSlice = createSlice({
    name: 'applicationMenu',
    initialState,
    reducers: {
        setCurrentApplicationMenuItem: (state, action: PayloadAction<string>) => {
            state.currentItem = action.payload;
        },
    },
})

export const {setCurrentApplicationMenuItem} = applicationMenuSlice.actions;

export default applicationMenuSlice.reducer