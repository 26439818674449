// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
// https://firebase.google.com/docs/web/setup#available-libraries
import {getFirestore, Timestamp as TS} from "firebase/firestore";
import {format} from "date-fns";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
    authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIRESTORE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIRESTORE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIRESTORE_APP_ID,
    measurementId: process.env.REACT_APP_FIRESTORE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);



export type Timestamp = TS;

export const getTimestampLabel = (timestamp: Timestamp) => {
    if(!timestamp) {
        return "";
    }
    const date = timestamp.toDate();
    return format(date, "dd.MM.yyyy - HH:mm");
}

export const compareTimestamps = (timestampA: Timestamp, timeStampB: Timestamp) => {
    if(!timestampA || !timeStampB) {
        return 0;
    }

    return timestampA.toDate().getTime() - timeStampB.toDate().getTime();
}