import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {CompanyTo} from "../../api/tos/company/CompanyTo";
import {getCompany, newCompany, updateCurrentCompany} from "../../api/companyApi";
import {CreateCompanyTo} from "../../api/tos/company/CreateCompanyTo";
import {UpdateCompanyTo} from "../../api/tos/company/UpdateCompanyTo";

export interface CompanyState {
    value: CompanyTo | undefined
    status: 'initial' | 'ok' | 'loading' | 'failed';
}
const initialState: CompanyState = {
    value: undefined,
    status: 'initial'
}

export const fetchCompany = createAsyncThunk(
    'jobs/fetchCompany',
    async () => {
        return await getCompany().then(value => value.data)
    }
);

export const createCompany = createAsyncThunk(
    'jobs/createCompany',
    async (company: CreateCompanyTo) => {
        return await newCompany(company).then(value => value.data)
    }
);

export const updateCompany = createAsyncThunk(
    'jobs/updateCompany',
    async (company: UpdateCompanyTo) => {
        return await updateCurrentCompany(company).then(value => value.data)
    }
);

export const selectCompany = (state: RootState) => state.company.value
export const selectFetchCompanyStatus = (state: RootState) => state.company.status

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchCompany.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchCompany.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchCompany.rejected, (state) => {
            state.status = 'failed'
        })
        builder.addCase(createCompany.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(createCompany.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(createCompany.rejected, (state) => {
            state.status = 'failed'
        })
        builder.addCase(updateCompany.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(updateCompany.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(updateCompany.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default companySlice.reducer
