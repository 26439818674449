import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {
    selectFormCurrentPage,
    selectFormLastCompletePage,
    setFormCurrentPage,
    setFormLastCompletePage
} from "../../state/multipageform/multipageFormSlice";
import {MultiFormPageI} from "../../components/menus/MultiPageFormMenu";
import Overview from "./newCompanyProfile/Overview";
import About from "./newCompanyProfile/About";
import {newJobPages} from "../jobs/NewJob";
import BottomNavigation from "../../components/navigation/BottomNavigation";
import {CreateCompanyDetailObject} from "./types";
import {useNavigate} from "react-router-dom";
import {
    selectCreateCompanyProfileObject,
    setCreateCompanyProfileObject
} from "../../state/company/createNewProfileSlice";
import {URI_COMPANY_PROFILE_WELCOME} from "../../config/Router";
import {createCompanyObjectToCreateCompanyTo} from "../../services/companyService";
import {createCompany, selectCompany} from "../../state/company/companySlice";
import ReactGA from "react-ga4";
import ValuesAndVision from "./newCompanyProfile/ValuesAndVision";

export const newProfilePages: MultiFormPageI<CreateCompanyDetailObject>[] = [
    {title: "Unternehmensinfos & Adresse", page: (props) => <Overview {...props}/>,},
    {title: "Über uns", page: (props) => <About {...props}/>,},
    {title: "Werte und Vision", page: (props) => <ValuesAndVision {...props}/>,},
    //{title: "Ansprechpartner", page: (props) => <Contact {...props}/>,},
]

const createProfileText = "Profil anlegen"

function NewProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentPage = useSelector(selectFormCurrentPage);
    const lastCompletePage = useSelector(selectFormLastCompletePage);
    const companyProfile = useSelector(selectCreateCompanyProfileObject);
    const company = useSelector(selectCompany);

    useEffect(() => {
        if (company) {
            navigate(`${URI_COMPANY_PROFILE_WELCOME}`)
        }
    }, [company, navigate])

    const onNext = (newObject: CreateCompanyDetailObject, valid: boolean) => {
        if (valid) {
            if (currentPage === newProfilePages.length) {
                const createObj = createCompanyObjectToCreateCompanyTo(newObject)
                if (createObj) {
                    dispatch(setFormLastCompletePage(0))
                    dispatch(setFormCurrentPage(1))
                    // @ts-ignore
                    dispatch(createCompany(createObj));
                    ReactGA.event({
                        category: 'company',
                        action: 'UNTERNEHMENSPROFIL_PUBLISH',
                    });
                    return <></>
                }
            } else {
                dispatch(setFormLastCompletePage(Math.max(currentPage, lastCompletePage)))
                dispatch(setFormCurrentPage(Math.min(currentPage + 1, newJobPages.length)))
                dispatch(setCreateCompanyProfileObject(newObject));
            }
        } else {
            dispatch(setFormLastCompletePage(currentPage - 1))
            dispatch(setCreateCompanyProfileObject(newObject));
        }
    }

    const onPrevious = (newObject: CreateCompanyDetailObject, valid: boolean) => {
        if (valid) {
            dispatch(setFormLastCompletePage(Math.max(currentPage, lastCompletePage)))
        } else {
            dispatch(setFormLastCompletePage(currentPage - 1))
        }
        dispatch(setFormCurrentPage(Math.max(currentPage - 1, 1)))
        dispatch(setCreateCompanyProfileObject(newObject));
    }

    const pageObject: MultiFormPageI<CreateCompanyDetailObject> = newProfilePages[currentPage - 1];
    return (
        <>
            <Typography variant="h2" paddingBottom="4px">{pageObject.title}</Typography>
            {pageObject.page({currentObject: companyProfile, onNext: onNext, onPrevious: onPrevious})}
            <BottomNavigation
                showPreviousButton={currentPage > 1}
                showNextButton
                nextButtonText={currentPage === newProfilePages.length ? createProfileText : undefined}
            />
        </>
    );
}

export default NewProfile;