import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {CreateJobObject} from "../../pages/jobs/types";
import {createJob} from "../../api/jobsApi";
import {CreateJobDetailObjectTo} from "../../api/tos/jobs/CreateJobDetailObjectTo";
import {JobDetailObjectTo} from "../../api/tos/jobs/JobDetailObjectTo";

export interface CreateJobState {
    newJob: CreateJobObject;
    createdJob: JobDetailObjectTo | undefined;
    status: 'ok' | 'loading' | 'failed';
}

const initialState: CreateJobState = {
    newJob: {},
    createdJob: undefined,
    status: 'ok',
}

export const createNewJob = createAsyncThunk(
    'jobs/createJob',
    async (newJobTo: CreateJobDetailObjectTo) => {
        return await createJob(newJobTo).then(value => value.data);
    }
);

export const selectCreateJobObject = (state: RootState) => state.createJob.newJob;
export const selectCreatedJobObject = (state: RootState) => state.createJob.createdJob;

export const createJobSlice = createSlice({
    name: 'createJob',
    initialState,
    reducers: {
        setCreateJobObject: (state, action: PayloadAction<CreateJobObject>) => {
            state.newJob = action.payload;
        },
        resetJobObjects: (state) => {
            state.newJob = {};
            state.createdJob = undefined;
        }
    },
    extraReducers: builder => {
        builder.addCase(createNewJob.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(createNewJob.fulfilled, (state, action) => {
            state.status = 'ok'
            state.createdJob = action.payload
        })
        builder.addCase(createNewJob.rejected, (state) => {
            state.status = 'failed'
        })
    }

})

export const {setCreateJobObject, resetJobObjects} = createJobSlice.actions;

export default createJobSlice.reducer
