import {axiosInstance, getAccessToken} from "../config/apiConfig";
import {AxiosResponse} from "axios";
import {JobOverviewObjectTo} from "./tos/jobs/JobOverviewObjectTo";
import {CreateJobDetailObjectTo} from "./tos/jobs/CreateJobDetailObjectTo";
import {JobDetailObjectTo} from "./tos/jobs/JobDetailObjectTo";
import {UpdateJobDetailObjectTo} from "./tos/jobs/UpdateJobDetailObjectTo";
import {PublishReturnType} from "../state/jobs/publishJobSlice";

const JOBS_BASE_API = "api/jobs"

export async function getJobs(): Promise<AxiosResponse<JobOverviewObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${JOBS_BASE_API}`)
}

export async function getJob(id: number): Promise<AxiosResponse<JobDetailObjectTo>> {
    return axiosInstance(getAccessToken()).get(`${JOBS_BASE_API}/${id}`)
}

export async function createJob(newJob: CreateJobDetailObjectTo): Promise<AxiosResponse<JobDetailObjectTo>> {
    return axiosInstance(getAccessToken()).post(`${JOBS_BASE_API}`, newJob)
}

export async function updateJob(updatedJob: UpdateJobDetailObjectTo): Promise<AxiosResponse<JobDetailObjectTo>> {
    return axiosInstance(getAccessToken()).put(`${JOBS_BASE_API}/${updatedJob.id}`, updatedJob)
}

export async function publishJob(id: number): Promise<AxiosResponse<PublishReturnType>> {
    return axiosInstance(getAccessToken()).post(`${JOBS_BASE_API}/${id}/publish`)
}

export async function deleteJob(id: number): Promise<AxiosResponse> {
    return axiosInstance(getAccessToken()).delete(`${JOBS_BASE_API}/${id}`)
}
