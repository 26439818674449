import {Controller, FieldPath, FieldValues, UseControllerProps} from "react-hook-form";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import React from "react";
import {Box, TextField} from "@mui/material";
import {getDateDisplayFormat} from "../../services/dateService";

export interface DatePickerControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TFieldName> {
    label: string,
    disabled?: boolean,
    minDate?: Date,
}

export function FormDatePicker<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
      control,
      name,
      rules,
      label,
      disabled,
      minDate,
  }: DatePickerControllerProps<TFieldValues, TFieldName>) {

    return (
        <Controller
            control={control}
            name={name}
            render={({field}) =>
                <Box display="flex" flex={1} flexDirection="column">
                    <Typography variant="subtitle1" paddingBottom="5px">{label}</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={field.value}
                            onChange={(newValue) => {
                                if (newValue) {
                                    // @ts-ignore
                                    field.onChange(newValue);
                                }
                            }}
                            renderLoading={() =>
                                <TextField
                                    fullWidth
                                />}
                            disabled={disabled}
                            format={getDateDisplayFormat()}
                            minDate={minDate}
                        />
                    </LocalizationProvider>
                </Box>
            }
            rules={rules}
        />
    );
}
