import {
    FieldValues,
    FieldPath,
    UseControllerProps, Controller
} from "react-hook-form";
import React from "react";
import LabeledCheckbox from "../controls/LabeledCheckbox";

export interface CheckboxControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TFieldName> {
    disabled?: boolean,
    label: string,
}

export function FormCheckbox<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
      control,
      name,
      label,
      rules,
      disabled
  }: CheckboxControllerProps<TFieldValues, TFieldName>) {

    return (
        <Controller
            control={control}
            name={name}
            render={({field}) =>
                <LabeledCheckbox
                    checked={field.value}
                    onChange={(newValue) => {
                        if (newValue) {
                            field.onChange(newValue);
                        }
                    }}
                    disabled={disabled}
                    label={label}
                />
            }
            rules={rules}
        />
    );
}
