import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {updateJob} from "../../api/jobsApi";
import {UpdateJobDetailObjectTo} from "../../api/tos/jobs/UpdateJobDetailObjectTo";

export interface UpdateJobState {
    status: 'ok' | 'loading' | 'failed';
}

const initialState: UpdateJobState = {
    status: 'ok',
}


interface UpdateJobProps {
    updateJobTo: UpdateJobDetailObjectTo,
    onLoadFunction?: () => void,
}
export const updateCurrentJob = createAsyncThunk(
    'jobs/updateJob',
    async ({updateJobTo, onLoadFunction}: UpdateJobProps) => {
        return await updateJob(updateJobTo).then(() => {onLoadFunction && onLoadFunction()});
    }
);


export const updateJobSlice = createSlice({
    name: 'updateJob',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateCurrentJob.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(updateCurrentJob.fulfilled, (state) => {
            state.status = 'ok'
        })
        builder.addCase(updateCurrentJob.rejected, (state) => {
            state.status = 'failed'
        })
    }

})

export default updateJobSlice.reducer
