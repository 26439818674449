import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Logo from "./logo";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Link} from "react-router-dom";
import {ROOT} from "../../config/Router";
import AppBarMenu from "../menus/AppBarMenu";

interface ResponsiveAppBarProps {
    disableMenu?: boolean
}
function ResponsiveAppBar(props: ResponsiveAppBarProps) {

    return (
        <AppBar elevation={0} position="static" sx={{bgcolor: "white"}}>
            <Toolbar disableGutters>
                <Box sx={{flexGrow: 1}}>
                    <Grid2 container spacing={0} alignItems="center">
                        <Grid2 xsOffset={4} xs={4}>
                            <div style={{textAlign: "center"}}>
                                <Link to={ROOT}>
                                    <Logo/>
                                </Link>
                            </div>
                        </Grid2>
                        <Grid2 xs={4}>
                            { !props.disableMenu ? <AppBarMenu/> : ''}
                        </Grid2>
                    </Grid2>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default ResponsiveAppBar;
