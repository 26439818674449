import {
    FieldValues,
    FieldPath,
    UseControllerProps, Controller
} from "react-hook-form";
import React from "react";
import LabeledSwitch from "../controls/LabeledSwitch";

export interface SwitchControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TFieldName> {
    disabled?: boolean,
    label: string,
}

export function FormSwitch<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
      control,
      name,
      label,
      rules,
      disabled
  }: SwitchControllerProps<TFieldValues, TFieldName>) {

    return (
        <Controller
            control={control}
            name={name}
            render={({field}) =>
                <LabeledSwitch
                        checked={field.value}
                        onChange={(newValue) => {
                            if (newValue) {
                                field.onChange(newValue);
                            }
                        }}
                        disabled={disabled}
                        label={label}
                    />
            }
            rules={rules}
        />
    );
}
