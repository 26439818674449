import {AxiosResponse} from "axios";
import {axiosInstance, getAccessToken} from "../config/apiConfig";
import {CompanyTo} from "./tos/company/CompanyTo";
import {UpdateCompanyTo} from "./tos/company/UpdateCompanyTo";
import {CreateCompanyTo} from "./tos/company/CreateCompanyTo";

const COMPANY_BASE_PATH = "api/company";

export async function getCompany(): Promise<AxiosResponse<CompanyTo>> {
    return axiosInstance(getAccessToken()).get(`${COMPANY_BASE_PATH}`)
}
export async function newCompany(newCompany: CreateCompanyTo): Promise<AxiosResponse<CompanyTo>> {
    return axiosInstance(getAccessToken()).post(`${COMPANY_BASE_PATH}`, newCompany)
}
export async function updateCurrentCompany(updatedCompany: UpdateCompanyTo): Promise<AxiosResponse<CompanyTo>> {
    return axiosInstance(getAccessToken()).put(`${COMPANY_BASE_PATH}`, updatedCompany)
}
