import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getMatchCompanyStatus} from "../../api/baseDataApi";

export interface MatchCompanyStatusState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: MatchCompanyStatusState = {
    value: [],
    status: 'ok'
}

export const fetchMatchCompanyStatus = createAsyncThunk(
    'baseData/fetchMatchCompanyStatus',
    async () => {
        return await getMatchCompanyStatus().then(value => value.data)
    }
);

export const selectMatchCompanyStatus = (state: RootState) => state.matchCompanyStatus.value
export const selectMatchCompanyStatusByBusinessKey = (state: RootState) => {
    const companyStatus = state.matchCompanyStatus.value;
    return (id: string | null) => companyStatus.find((status) => status.businessKey === id);
};

export const matchCompanyStatusSlice = createSlice({
    name: 'matchCompanyStatus',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchMatchCompanyStatus.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchMatchCompanyStatus.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchMatchCompanyStatus.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default matchCompanyStatusSlice.reducer
