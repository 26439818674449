import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {publishJob} from "../../api/jobsApi";

export interface PublishJobState {
    status: 'ok' | 'loading' | 'failed';
    redirectUrl: string;
}

const initialState: PublishJobState = {
    status: 'ok',
    redirectUrl: '',
}

export interface PublishReturnType {
    redirectUrl: string,
}

export const publishCurrentJob = createAsyncThunk(
    'jobs/publishJob',
    async (id: number) => {
        return await publishJob(id).then(response => response.data);
    }
);


export const publishJobSlice = createSlice({
    name: 'publishJob',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(publishCurrentJob.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(publishCurrentJob.fulfilled, (state, action) => {
            state.status = 'ok';
            state.redirectUrl = action.payload.redirectUrl
        })
        builder.addCase(publishCurrentJob.rejected, (state) => {
            state.status = 'failed';
        })
    }

})

export default publishJobSlice.reducer
