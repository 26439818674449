import * as React from 'react';
import {MenuList, Paper} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {applicationMenuItems} from "../../config/Router";
import {Link, LinkProps} from "react-router-dom";
import Typography, {TypographyProps} from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {selectCurrentApplicationMenuItem} from "../../state/menu/applicationMenuSlice";

const StyledLink = styled(Link)<LinkProps>(({theme}) => ({
    textDecoration: 'none',
    color: theme.palette.accentColor.main,
}))

const SelectedTypography = styled(Typography)<TypographyProps>(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
    width: '100%',
    lineHeight: '1rem',
    padding: '0.5rem',
}))
const UnselectedTypography = styled(Typography)<TypographyProps>(() => ({
    width: '100%',
    lineHeight: '1rem',
    padding: '0.5rem',
}))

function ApplicationMenu() {
    const currentItem = useSelector(selectCurrentApplicationMenuItem);

    return (
        <Paper sx={{width: 202, maxWidth: '100%'}}>
            <MenuList sx={{}}>
                {applicationMenuItems.map((menuItem, key) =>
                    <StyledLink key={key} to={menuItem.path}>
                        <MenuItem key={key}>
                            {menuItem.title === currentItem
                                ? <SelectedTypography variant="subtitle1">{menuItem.title}</SelectedTypography>
                                : <UnselectedTypography variant="body1">{menuItem.title}</UnselectedTypography>
                            }
                        </MenuItem>
                    </StyledLink>
                )}
            </MenuList>
        </Paper>
    );
}

export default ApplicationMenu;