import {createContext, ReactNode, useContext} from "react";
import {IFirestoreContext, useFirestore} from "../hooks/useFirestoreHook";

interface IProps {
    children: ReactNode;
}

const FirestoreContext = createContext<IFirestoreContext>({
    signedIn: false,
    chatUserId: null,
    reload: () => {},
    signOut: () => {},
});

const FirestoreContextProvider = ({children}: IProps) => {
    const value = useFirestore();

    return (
        <FirestoreContext.Provider value={value}>
            {children}
        </FirestoreContext.Provider>
    )
}

export default FirestoreContextProvider

export const useFirestoreContext = () => useContext(FirestoreContext);