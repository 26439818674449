import {Box, Switch, SwitchProps, Typography} from "@mui/material";
import React from "react";

interface LabeledSwitchProps extends SwitchProps {
    label: string,
}

function LabeledSwitch ({label, ...props}: LabeledSwitchProps) {
    return (
        <Box padding={0} display="flex" flexDirection="row" alignItems="center" marginLeft="-10px">
            {/*TODO andere möglickeit um links zu zentrieren als negativ margin?*/}
            <Switch {...props}/>
            {label &&
                <Typography variant="subtitle1">{label}</Typography>
            }
        </Box>
    )
}

export default LabeledSwitch;