import React from 'react';
import Button from "@mui/material/Button";
import {useNavigate} from 'react-router-dom'
import {Typography} from "@mui/material";
import {ROOT} from "../../config/Router";

function LandingPage() {
    const navigate = useNavigate();
    const signIn = () => {
        navigate(ROOT)
    }
    return (<div>
        <Typography variant="h1" textTransform="uppercase">Du hast dich abgemeldet!</Typography>
        <Button onClick={signIn} variant="contained" color="primary">
            Zum Login
        </Button>
    </div>)
}

export default LandingPage;