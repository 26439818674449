import React from 'react'
import {LinkData} from "../commonTypes";
import {Breadcrumbs} from "@mui/material";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";

interface BreadcrumbNavigationProps {
    links: LinkData[]
    lastItem: string
}

function BreadcrumbNavigation(props: BreadcrumbNavigationProps) {
    return (
        <Breadcrumbs>
            {props.links.map((link, key) => <Link to={link.path} key={key} style={{textDecoration: 'none'}}><Typography variant="subtitle1" color="primary">{link.title}</Typography></Link>)}
            <Typography variant="subtitle1">{props.lastItem}</Typography>
        </Breadcrumbs>)
}

export default BreadcrumbNavigation;