import {ChatOverviewObjectTo} from "../../api/tos/chat/ChatOverviewObjectTo";
import {Box, Tooltip, Typography, useTheme} from "@mui/material";
import BaseCard from "../cards/BaseCard";
import {URI_CHAT} from "../../config/Router";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import {styled} from "@mui/material/styles";
import {BoxProps} from "@mui/material/Box";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {compareTimestamps, getTimestampLabel, Timestamp as TS} from "../../config/firestoreConfig";
import CircleIcon from '@mui/icons-material/Circle';
import {Timestamp} from "firebase/firestore";
import {MultilineTypography} from "../typographys/MultilineTypography";

const deleteAfterDays = (process.env.REACT_APP_DELETE_AFTER_DAYS || 60) as number;
const days_before_warning = 7;
const MS_IN_DAYS = 1000 * 60 * 60 * 24;

interface IProps {
    overviewObj: ChatOverviewObjectTo;
    selected?: boolean;
}

const StyledBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
}))

interface ImageAvatarProps {
    imageURL?: string,
}

function ImageAvatar(props: ImageAvatarProps) {
    const image = props.imageURL ?? "/matches/matchPlaceholder.png"
    const theme = useTheme();

    return (
        <StyledBox margin="5px">
            <Avatar alt="chat image" src={image} style={{border: `1px solid ${theme.palette.secondary.main}`}}/>
        </StyledBox>
    )
}

function StatusBox(props: IProps) {
    const {overviewObj} = props;
    const hasNewMessages = compareTimestamps(overviewObj.lastSent, overviewObj.lastRead) > 0;
    const remainingDays = deleteAfterDays - (compareTimestamps(Timestamp.now() as TS, overviewObj.lastSent) / MS_IN_DAYS);
    const isAboutToExpire = remainingDays <= days_before_warning;

    return (
        <Box display="flex" flexDirection="column" alignItems="end" flex={1} justifyContent="flex-end"
             paddingY="10px" gap="10px">
            <Box display="flex" flexDirection="row">
                {hasNewMessages && (
                    <Tooltip
                        title={(
                            <MultilineTypography>
                                {`Du hast neue Nachrichten.`}
                            </MultilineTypography>
                        )}
                    >
                        <CircleIcon sx={{color: 'secondary.main'}}/>
                    </Tooltip>
                )}
                {isAboutToExpire && (
                    <Tooltip
                        title={(
                            <MultilineTypography>
                                {`Der Chat läuft demnächst ab und wird aus datenschutzrechtlichen Gründen gelöscht. Falls du den Chat weiterhin behalten willst, schreibe eine neue Nachricht. `}
                            </MultilineTypography>
                        )}
                    >
                        <WarningRoundedIcon sx={{color: 'error.main'}}/>
                    </Tooltip>
                )}
            </Box>
            <Typography variant="subtitle2">{getTimestampLabel(overviewObj.lastSent)}</Typography>
        </Box>
    )
}

function ChatOverviewCard(props: IProps) {
    const {overviewObj, selected} = props;

    const link = `${URI_CHAT}?chatId=${overviewObj.chatId}`

    return (
        <BaseCard
            id={overviewObj.chatId}
            title={overviewObj.title}
            description={overviewObj.subtitle}
            leftChild={<ImageAvatar/>}
            rightChild={<StatusBox overviewObj={overviewObj}/>}
            onClickURI={link}
            bgColor={selected ? '#ededee' : undefined}
        />
    )
}

export default ChatOverviewCard