import {ChatOverviewObjectTo} from "../../api/tos/chat/ChatOverviewObjectTo";
import {useChat} from "../../hooks/useChatHook";
import {ChatCardContainer} from "./ChatCardContainer";
import ChatMessageCard from "./ChatMessageCard";
import {styled} from "@mui/material";
import Box from "@mui/material/Box";
import {ChatTitleTypography} from "./ChatTitleTypography";
import ChatTextField from "./ChatTextField";
import {useEffect, useRef, useState} from "react";
import {compareTimestamps} from "../../config/firestoreConfig";

interface IProps {
    chat: ChatOverviewObjectTo | undefined;
}

const DetailsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
});

function ChatDetails(props: IProps) {
    const {chat} = props;
    const [ignoreScroll, setIgnoreScroll] = useState(false);
    const {messages, postMessage} = useChat(chat);
    const scrollRef = useRef(null);

    useEffect(() => {
        setIgnoreScroll(false)
    }, [chat])

    if(!chat) {
        return null;
    }

    const handlePostMessage: (text: string) => Promise<void> = (text) => {
        setIgnoreScroll(false)
        return postMessage(text);
    }

    const handleRefRender = () => {
        if(scrollRef.current && !ignoreScroll) {
            // @ts-ignore
            scrollRef.current.scrollIntoView({block: 'start' });
            setIgnoreScroll(true);
        }
    }

    return (
        <DetailsContainer>
            <ChatTitleTypography variant="h5">{chat?.title}</ChatTitleTypography>
            <ChatCardContainer sx={{width: '100%'}}>
                {messages.map((message, index) => {
                    const isLastMessage = index+1 === messages.length;
                    const isPreviousUnread = index > 0 && compareTimestamps(messages[index-1].sentAt, chat!.lastRead) > 0;
                    const isUnread = isPreviousUnread || compareTimestamps(message.sentAt, chat!.lastRead) > 0;

                    const showNew = !isPreviousUnread && isUnread;
                    const addScrollRef = (isLastMessage && !isUnread) || showNew;
                    return (
                        <ChatMessageCard
                            key={index}
                            ref={addScrollRef ? scrollRef : null}
                            message={message}
                            isNew={showNew}
                            onRender={handleRefRender}
                        />
                    )}
                )}
            </ChatCardContainer>
            <ChatTextField postMessage={handlePostMessage}/>
        </DetailsContainer>
    )
}

export default ChatDetails;