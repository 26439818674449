import * as React from 'react';
import {URI_JOBS_NEW} from "../../config/Router";
import {StyledButton} from "./StyledButton";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setFormCurrentPage, setFormLastCompletePage} from "../../state/multipageform/multipageFormSlice";
import {resetJobObjects} from "../../state/jobs/createJobSlice";
import ReactGA from "react-ga4";


function CreateNewJobButton() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setFormCurrentPage(1))
        dispatch(setFormLastCompletePage(0))
        dispatch(resetJobObjects())
        ReactGA.event({
            category: 'job',
            action: 'JOB_START',
        })
        navigate(URI_JOBS_NEW);
    }

    return <StyledButton variant="contained" onClick={handleClick}>Neuen Job anlegen</StyledButton>
}

export default CreateNewJobButton