import {useForm} from "react-hook-form";
import * as React from "react";
import {useEffect} from "react";
import {CreateCompanyDetailObject} from "../types";
import {FormTextField} from "../../../components/forms/FormTextField";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";


export type CreateProfileVisionAndValuesFormData = {
    companyValues: string,
    vision: string,
}

function ValuesAndVision({currentObject, onNext, onPrevious}: MultiPageComponentProps<CreateCompanyDetailObject>) {

    const formContext = useForm<CreateProfileVisionAndValuesFormData>({
        defaultValues: {
            companyValues: "",
            vision: "",
        }
    })
    const {formState: {isValid}, control, handleSubmit, clearErrors} = formContext;

    const saveData = (data: CreateProfileVisionAndValuesFormData) => {
        const newProfileObject: CreateCompanyDetailObject = {
            ...currentObject,
            companyValues: data.companyValues,
            vision: data.vision,
        }
        return newProfileObject;
    }

    const handlePrevious = () => {
        const data: CreateProfileVisionAndValuesFormData = formContext.getValues();
        onPrevious && onPrevious(saveData(data), isValid);
    }

    const onSubmit = (data: CreateProfileVisionAndValuesFormData) => {
        onNext && onNext(saveData(data), true);
    };
    const handleValidationFailed = () => {
        const data: CreateProfileVisionAndValuesFormData = formContext.getValues();
        onNext && onNext(saveData(data), false);
    };

    useEffect(() => {
        if (currentObject) {
            formContext.setValue("companyValues", currentObject.companyValues || "");
            formContext.setValue("vision", currentObject.vision || "");
        }
    }, [])

    formContext.watch(() => {
        clearErrors();
    })

    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit, handleValidationFailed)} onReset={handlePrevious}>
            <FormTextField
                control={control}
                label="Welche Werte vertritt dein Unternehmen?"
                rules={{required: false}}
                name="companyValues"
                multiline
                minRows={5}
                maxRows={10}
            />
            <FormTextField
                control={control}
                label="Welche Vision verfolgt dein Unternehmen?"
                rules={{required: false}}
                name="vision"
                multiline
                minRows={5}
                maxRows={10}
            />
        </form>
    )
}

export default ValuesAndVision;