import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getLanguageLevels} from "../../api/baseDataApi";

export interface LanguageLevelsState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: LanguageLevelsState = {
    value: [],
    status: 'ok'
}

export const fetchLanguageLevels = createAsyncThunk(
    'baseData/fetchLanguageLevels',
    async () => {
        return await getLanguageLevels().then(value => value.data)
    }
);

export const selectLanguageLevels = (state: RootState) => state.languageLevels.value
export const selectLanguageLevelByBusinessKey = (state: RootState) => {
    const languageLevels = state.languageLevels.value;
    return (id: string | null) => languageLevels.find((level) => level.businessKey === id);
};

export const languageLevelsSlice = createSlice({
    name: 'languageLevels',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchLanguageLevels.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchLanguageLevels.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchLanguageLevels.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default languageLevelsSlice.reducer
