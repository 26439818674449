import {Dialog, DialogActions, DialogContent} from "@mui/material";
import {MultilineTypography} from "../typographys/MultilineTypography";
import {DeclineButton, OutlinedButton} from "../buttons/CommonButtons";
import Typography from "@mui/material/Typography";
import React from "react";

interface InfoPopupProps {
    text: string;
    open: boolean;
    abortText?: string;
    onAbort?: () => void;
    acceptText?: string;
    onAccept: () => void;
}
function InfoPopup(props: InfoPopupProps) {
    const {text, open, abortText, onAbort, acceptText, onAccept} = props;

    return (
        <Dialog open={open} PaperProps={{sx: {maxWidth: 'none', width: '45rem'}}}>
            <DialogContent>
                <MultilineTypography variant="subtitle1">{text}</MultilineTypography>
            </DialogContent>
            <DialogActions>
                {onAbort && (
                    <DeclineButton onClick={onAbort}><Typography>{abortText ?? 'Abbrechen'}</Typography></DeclineButton>
                )}
                <OutlinedButton onClick={onAccept}><Typography variant="subtitle1">{acceptText ?? 'Weiter'}</Typography></OutlinedButton>
            </DialogActions>
        </Dialog>
    )
}

export default InfoPopup;