import {styled} from "@mui/material/styles";
import {Box, Grid, Typography} from "@mui/material";
import {BoxProps} from "@mui/material/Box";
import {GridProps} from "@mui/material/Grid";
import React from "react";

const OverviewContainer = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '40px',
    paddingTop: '30px',
}))

const ColumnContainer = styled(Grid)<GridProps>(() => ({
    display: 'flex',
    flexDirection: 'column',
}))

interface ColumnTitleProps {
    title: string,
}

function ItemContainer(props: GridProps) {
    return (
        <Grid item>
            <ColumnContainer {...props}>
                {props.children}
            </ColumnContainer>
        </Grid>
    );
}

function ColumnTitle(props: ColumnTitleProps) {
    return (
        <Grid item>
            <Typography variant="h2">{props.title}</Typography>
        </Grid>
    );
}


interface OverviewElement {
    name: string,
    content: React.ReactNode[],
}

interface OverviewTableProps {
    elements: OverviewElement[][],
}

function OverviewTable(props: OverviewTableProps) {
    return (
        <OverviewContainer>
            {props.elements.map((column, key) => (
                <ColumnContainer container key={key} spacing="30px">
                    {column.map((elem, key) => (
                        <ItemContainer container key={key} spacing="10px">
                            <ColumnTitle title={elem.name}/>
                            {elem.content}
                        </ItemContainer>
                    ))}
                </ColumnContainer>
            ))}
        </OverviewContainer>
    );
}

export default OverviewTable;