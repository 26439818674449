import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import SearchBox from "../../../components/controls/SearchBox";
import {useDispatch, useSelector} from "react-redux";
import {CreateJobObject} from "../types";
import {FormToggleButtonGroup} from "../../../components/forms/FormToggleButtonGroup";
import {fetchToolsByFieldOfActivityBusinessKey, selectTools} from "../../../state/basedata/toolsSlice";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";
import {FormCheckbox} from "../../../components/forms/FormCheckbox";


export type CreateJobToolsFormData = {
    noToolsNeeded: boolean,
    tools: string[],
}

function Tools({currentObject, onNext, onPrevious}: MultiPageComponentProps<CreateJobObject>) {
    const dispatch = useDispatch();

    const tools = useSelector(selectTools);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);

    useEffect(() => {
        if(currentObject.areaOfActivity && currentObject.areaOfActivity !== "") {
            //@ts-ignore
            dispatch(fetchToolsByFieldOfActivityBusinessKey(currentObject.areaOfActivity))
        }
    }, [dispatch, currentObject])

    const formContext = useForm<CreateJobToolsFormData>({
        defaultValues: {
            noToolsNeeded: false,
            tools: [],
        }
    })
    const {formState: {errors, isValid}, control, handleSubmit, clearErrors} = formContext;
    const noToolsNeeded = formContext.getValues("noToolsNeeded");

    const saveData = (data: CreateJobToolsFormData) => {
        const tools = data.noToolsNeeded ? [] : data.tools;
        const mergedToolsSkills = [...currentObject.skills || [], ...tools];
        const filteredPriorities = currentObject.priorities?.filter((priority) => mergedToolsSkills.includes(priority));

        const newCreateJobObject: CreateJobObject = {
            ...currentObject,
            noToolsNeeded: data.noToolsNeeded,
            tools: tools,
            priorities: filteredPriorities,
        }
        return newCreateJobObject;
    }

    const handlePrevious = () => {
        const data: CreateJobToolsFormData = formContext.getValues();
        onPrevious && onPrevious(saveData(data), isValid);
    }

    const onSubmit = (data: CreateJobToolsFormData) => {
        onNext && onNext(saveData(data), true);
    };
    const handleValidationFailed = () => {
        const data: CreateJobToolsFormData = formContext.getValues();
        onNext && onNext(saveData(data), false);
    };

    useEffect(() => {
        if (currentObject) {
            formContext.setValue("tools", currentObject.tools || []);
            formContext.setValue("noToolsNeeded", !!currentObject.noToolsNeeded);
        }
    }, [])

    formContext.watch(() => {
        clearErrors();
    })

    const filteredTools = searchTerm === null || searchTerm === "" ? tools : tools.filter((tool) => tool.description.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit, handleValidationFailed)} onReset={handlePrevious}>
            <Typography variant="body1" paddingTop="10px">Bitte markiere alle Programme, die dein/e Kandidat/in "Gut" bis "Sehr gut" beherrschen sollte.</Typography>
            <FormCheckbox
                control={control}
                name="noToolsNeeded"
                label="Keine Programmskills erforderlich"
            />
            {!noToolsNeeded && (
                <>
                    <SearchBox label="Programme suchen" onKeyChange={(text: string | null) => setSearchTerm(text)}/>
                    <FormToggleButtonGroup
                        control={control}
                        name="tools"
                        label=""
                        rules={{required: true}}
                        error={!!errors.tools}
                        errorMessage="Bitte mindestens eine Option auswählen"
                        options={filteredTools}
                        sameSize
                    />
                </>
            )}
        </form>
    );
}

export default Tools;