import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import * as React from "react";
import {useEffect} from "react";
import {CreateCompanyDetailObject} from "../types";
import {FormTextField} from "../../../components/forms/FormTextField";
import {FormToggleButtonGroup} from "../../../components/forms/FormToggleButtonGroup";
import {Box} from "@mui/material";
import {fetchCompanyBenefits, selectCompanyBenefits} from "../../../state/basedata/companyBenefitsSlice";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";


export type CreateProfileAboutFormData = {
    description: string,
    employees: string,
    benefits: string[],
    // todo impressions

}

function About({currentObject, onNext, onPrevious}: MultiPageComponentProps<CreateCompanyDetailObject>) {
    const dispatch = useDispatch();

    const benefits = useSelector(selectCompanyBenefits);

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchCompanyBenefits())
    }, [dispatch])

    const formContext = useForm<CreateProfileAboutFormData>({
        defaultValues: {
            description: "",
            employees: "",
            benefits: [],
        }
    })
    const {formState: {errors, isValid}, control, handleSubmit, clearErrors} = formContext;

    const saveData = (data: CreateProfileAboutFormData) => {
        const newProfileObject: CreateCompanyDetailObject = {
            ...currentObject,
            description: data.description,
            employees: data.employees,
            benefits: data.benefits,
        }
        return newProfileObject;
    }

    const handlePrevious = () => {
        const data: CreateProfileAboutFormData = formContext.getValues();
        onPrevious && onPrevious(saveData(data), isValid);
    }

    const onSubmit = (data: CreateProfileAboutFormData) => {
        onNext && onNext(saveData(data), true);
    };
    const handleValidationFailed = () => {
        const data: CreateProfileAboutFormData = formContext.getValues();
        onNext && onNext(saveData(data), false);
    };

    useEffect(() => {
        if (currentObject) {
            formContext.setValue("description", currentObject.description || "");
            formContext.setValue("employees", currentObject.employees || "");
            formContext.setValue("benefits", currentObject.benefits || []);
        }
    }, [])

    formContext.watch(() => {
        clearErrors();
    })

    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit, handleValidationFailed)} onReset={handlePrevious}>
            <FormTextField
                control={control}
                label="Über uns"
                rules={{required: true}}
                error={errors.description}
                errorMessage="Bitte beschreibe Dein Unternehmen kurz"
                name="description"
                multiline
                minRows={5}
                maxRows={10}
            />
            <Box width="50%">
                <FormTextField
                    control={control}
                    label="Anzahl Mitarbeitende"
                    rules={{required: true}}
                    error={errors.employees}
                    errorMessage="Bitte gib die Anzahl der Mitarbeitenden an"
                    name="employees"
                    type="number"
                />
            </Box>
            <FormToggleButtonGroup
                control={control}
                label="Benefits"
                options={benefits}
                name="benefits"
            />
            {/*<ImageUpload*/}
            {/*    label="Impressionen"*/}
            {/*    label2="Lade hier weitere Bilder von deinem Unternehmen hoch"*/}
            {/*    setSelected={() => {*/}
            {/*    }}*/}
            {/*/>*/}
        </form>
    )
}

export default About;