import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import SearchBox from "../../../components/controls/SearchBox";
import {useDispatch, useSelector} from "react-redux";
import {CreateJobObject} from "../types";
import {fetchSkillsByFieldOfActivityBusinessKey, selectSkills} from "../../../state/basedata/skillsSlice";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";
import {FormToggleButtonGroupWithAccordion} from "../../../components/forms/FormToggleButtonGroupWithAccordion";


export type CreateJobSkillsFormData = {
    skills: string[],
}


const PERSONAL_CLASSIFICATION = 'PERSONAL';

function Skills({currentObject, onNext, onPrevious}: MultiPageComponentProps<CreateJobObject>) {
    const dispatch = useDispatch();

    const skills = useSelector(selectSkills);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);

    useEffect(() => {
        if(currentObject.areaOfActivity && currentObject.areaOfActivity !== "") {
            //@ts-ignore
            dispatch(fetchSkillsByFieldOfActivityBusinessKey(currentObject.areaOfActivity))
        }
    }, [dispatch, currentObject])

    const formContext = useForm<CreateJobSkillsFormData>({
        defaultValues: {
            skills: [],
        }
    })
    const {formState: {errors, isValid}, control, handleSubmit, clearErrors} = formContext;

    const saveData = (data: CreateJobSkillsFormData) => {
        const mergedToolsSkills = [...currentObject.tools || [], ...data.skills];
        const filteredPriorities = currentObject.priorities?.filter((priority) => mergedToolsSkills.includes(priority));

        const newCreateJobObject: CreateJobObject = {
            ...currentObject,
            skills: data.skills,
            priorities: filteredPriorities,
        }
        return newCreateJobObject;
    }

    const handlePrevious = () => {
        const data: CreateJobSkillsFormData = formContext.getValues();
        onPrevious && onPrevious(saveData(data), isValid);
    }

    const onSubmit = (data: CreateJobSkillsFormData) => {
        onNext && onNext(saveData(data), true);
    };
    const handleValidationFailed = () => {
        const data: CreateJobSkillsFormData = formContext.getValues();
        onNext && onNext(saveData(data), false);
    };

    useEffect(() => {
        if (currentObject) {
            formContext.setValue("skills", currentObject.skills || []);
        }
    }, [])

    formContext.watch(() => {
        clearErrors();
    })

    const filteredSkills = searchTerm === null || searchTerm === "" ? skills : skills.filter((skill) => skill.description.toLowerCase().includes(searchTerm.toLowerCase()));
    const personalSkills = filteredSkills.filter((skill) => skill.classification === PERSONAL_CLASSIFICATION);
    const technicalSkills = filteredSkills.filter((skill) => skill.classification !== PERSONAL_CLASSIFICATION);

    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit, handleValidationFailed)} onReset={handlePrevious}>
            <Typography variant="body1" paddingTop="10px">Bitte markiere alle Fertigkeiten, die dein/e Kandidat/in "Gut" bis "Sehr gut" beherrschen sollte.</Typography>
            <SearchBox label="Fertigkeiten suchen" onKeyChange={(text: string | null) => setSearchTerm(text)}/>
            <FormToggleButtonGroupWithAccordion
                control={control}
                name="skills"
                label="Fachliche Skills"
                rules={{required: true}}
                error={!!errors.skills}
                errorMessage="Bitte mindestens eine Option in einer der Listen auswählen"
                options={technicalSkills}
                sameSize
            />
            <FormToggleButtonGroupWithAccordion
                control={control}
                name="skills"
                label="Persönliche Skills"
                rules={{required: true}}
                error={!!errors.skills}
                errorMessage="Bitte mindestens eine Option in einer der Listen auswählen"
                options={personalSkills}
                sameSize
            />
        </form>
    );
}

export default Skills;