import ResponsiveAppBar from "./response-app-bar";
import * as React from "react";
import {ReactNode} from "react";
import {Box} from "@mui/material";
import ResponsiveBottomBar from "./responsive-bottom-bar";
import MobileGuard from "../../config/MobileGuard";


interface PageContainerProps{
    children: ReactNode | ReactNode[],
    disableMenu?: boolean,
}
function PageLayout({children, disableMenu}: PageContainerProps) {
    return (
        <MobileGuard>
            <Box display="flex" flexDirection="column" width="100%" minHeight="100vh" justifyContent="space-between">
                <Box display="flex" flexDirection="column" flex={1}>
                    <ResponsiveAppBar disableMenu={!!disableMenu}/>
                    {children}
                </Box>
                <ResponsiveBottomBar />
            </Box>
        </MobileGuard>
    )
}

export default PageLayout;