import {Controller, FieldPath, FieldValues, UseControllerProps} from "react-hook-form";
import Typography from "@mui/material/Typography";
import {Box, Tooltip} from "@mui/material";
import React from "react";
import StyledSlider from "../slider/StyledSlider";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export interface SliderControllerProps<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TFieldName> {
    label: string,
    disabled?: boolean,
    infoText?: string,
}

export function FormSlider<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
      control,
      name,
      rules,
      label,
      disabled,
      infoText,
  }: SliderControllerProps<TFieldValues, TFieldName>) {

    return (
        <Controller
            control={control}
            name={name}
            render={({field: {onChange, value}}) =>
                <Box display="flex" flex={1} flexDirection="column" paddingTop="10px">
                    <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between">
                        <Typography variant="subtitle1">{label}</Typography>
                        {infoText &&
                            <Tooltip title={<Typography>{infoText}</Typography>}>
                                <InfoOutlinedIcon color="primary"/>
                            </Tooltip>
                        }
                    </Box>
                    <Box paddingX="10px">
                        <StyledSlider
                            value={value}
                            // @ts-ignore
                            onChange={(event, newValue) => onChange(newValue)}
                            disabled={disabled}
                        />
                    </Box>
                </Box>
            }
            rules={rules}
        />
    );
}
