import * as React from "react";
import Box from "@mui/material/Box";
import {Button, Tooltip, Typography} from "@mui/material";


const FEEDBACK_MAILTO = "contact@skillbased.de"

interface ItemProps {
    text: string,
    tooltip?: string,
}

function Item(props: ItemProps) {
    return (
        <Tooltip title={<Typography>{props.tooltip}</Typography>}>
            <Button style={{minWidth: 0}}>
                <Typography color="primary.main">{props.text}</Typography>
            </Button>
        </Tooltip>
    );
}

interface BottomBarProps {
    mobile?: boolean,
}

function ResponsiveBottomBar({mobile}: BottomBarProps) {
    return (
        <Box display="flex" width="100%" flexDirection="row" justifyContent="space-between" bgcolor="background.paper"
             alignItems="center">
            <Box display="flex" flexDirection="row" paddingX="10px" gap="5px" alignItems="center">
                {!mobile && <Typography variant="subtitle1" color="text.primary">SKILLBASED</Typography>}
                {!mobile && <Typography variant="body1" color="text.primary">2024</Typography>}
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap" paddingRight="20px" columnGap="10px">
                {!mobile && <a href={`mailto:${FEEDBACK_MAILTO}`} target="_blank" rel="noopener noreferrer">
                    <Item text="Feedback geben" tooltip="Klicke um eine Mail an contact@skillbased.de zu senden"/>
                </a>}
                {!mobile && <a href={`mailto:${FEEDBACK_MAILTO}`} target="_blank" rel="noopener noreferrer">
                    <Item text="Kontakt" tooltip="Klicke um eine Mail an contact@skillbased.de zu senden"/>
                </a>}
                <a href={`https://skillbased.de/skillbased-fuer-unternehmen-2`} target="_blank"
                   rel="noopener noreferrer">
                    <Item text="Preise"
                          tooltip="Klicke um zu den Informationen für Unternehmen zu gelangen"/>
                </a>
                <a href={`https://skillbased.de/allgemeine-geschaeftsbedingungen`} target="_blank"
                   rel="noopener noreferrer">
                    <Item text="AGB"
                          tooltip="Klicke um auf skillbased.de/allgemeine-geschaeftsbedingungen zu gelangen"/>
                </a>
                <a href={`https://skillbased.de/datenschutz`} target="_blank" rel="noopener noreferrer">
                    <Item text="Datenschutz" tooltip="Klicke um auf skillbased.de/datenschutz zu gelangen"/>
                </a>
                <a href={`https://skillbased.de/impressum`} target="_blank" rel="noopener noreferrer">
                    <Item text="Impressum" tooltip="Klicke um auf skillbased.de/impressum zu gelangen"/>
                </a>
            </Box>
        </Box>
    );
}

export default ResponsiveBottomBar;
