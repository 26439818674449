import {CSSProperties, useState} from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface FallbackImageProps {
    alt: string,
    src: string,
    style?: CSSProperties | undefined,
}

function FallbackImage({alt, src, style}: FallbackImageProps) {
    const [error, setError] = useState(false);

    const handleError = () => {
        setError(true);
    };

    return (
        <>
            {!error
                ? <img alt={alt} src={src} onError={handleError} style={style}/>
                : <CheckBoxOutlineBlankIcon />
            }

        </>
    )
}

export default FallbackImage;