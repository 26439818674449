import React, {ReactNode} from "react";

interface ImageBackgroundProps {
    children: ReactNode | ReactNode[],
}
function ImageBackground ({children}: ImageBackgroundProps) {
    return (
        <main style={{
            flex: "1",
            margin: 0,
            backgroundImage: "url(./Farbverlauf.jpg)",
            backgroundSize: "cover",
            width: '100%',
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            textAlign: "center"
        }}>
            {children}
        </main>
    )
}

export default ImageBackground;