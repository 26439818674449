import * as React from "react";
import {MouseEventHandler, ReactNode, useState} from "react";
import Box from "@mui/material/Box";
import {Dialog, DialogContent, Typography} from "@mui/material";
import {MultilineTypography} from "../typographys/MultilineTypography";
import {AcceptButton, DeclineButton} from "../buttons/CommonButtons";
import {ButtonProps} from "@mui/material/Button";
import {JobOverviewObjectTo} from "../../api/tos/jobs/JobOverviewObjectTo";
import {JobStatus} from "../../pages/jobs/types";
import {useDispatch, useSelector} from "react-redux";
import {fetchJob, selectJob} from "../../state/jobs/jobSlice";
import {publishCurrentJob} from "../../state/jobs/publishJobSlice";
import ReactGA4 from "react-ga4";

interface JobActionDialogProps {
    job: JobOverviewObjectTo,
    button: (props: ButtonProps) => ReactNode,
    loadAllJobs?: boolean,
}

function JobActionDialog({job, button, loadAllJobs}: JobActionDialogProps) {
    const dispatch = useDispatch();
    const jobDetail = useSelector(selectJob);
    const [open, setOpen] = useState(false);

    const status = job.status.businessKey;
    let btText = "";
    let imgSrc = "";
    let infoText = "";
    let acceptText = "";
    let declineText = "";

    switch (status) {
        case JobStatus.NEW.toString(): {
            btText = "Veröffentlichen";
            imgSrc = "/jobIcons/arrow-up-right-from-square.svg";
            infoText = " Möchtest du deinen Job für 28 Tage veröffentlichen?\nDu kannst ihn nach dem Veröffentlichen nicht mehr bearbeiten";
            acceptText = "Weiter zur Zahlung";
            declineText = "Abbrechen";
            break;
        }
        case JobStatus.PUBLISHED.toString(): {
            btText = "Laufzeit verlängern";
            imgSrc = "/jobIcons/calendar-circle-plus.svg";
            infoText = "Möchtest du deinen Job für weitere 28 Tage veröffentlichen?\nDu kannst ihn nach dem Veröffentlichen nicht mehr bearbeiten";
            acceptText = "Weiter zur Zahlung";
            declineText = "Abbrechen";
            break;
        }
        case JobStatus.CLOSED.toString(): {
            btText = "Reaktivieren";
            imgSrc = "/jobIcons/arrow-up-right-from-square.svg";
            infoText =  "Möchtest du deinen Job für 28 Tage reaktivieren?";
            acceptText = "Weiter zur Zahlung";
            declineText = "Abbrechen";
        }
    }

    const handlePublish = async () => {
        if(jobDetail) {
            // @ts-ignore
            const resultAction = await dispatch(publishCurrentJob(jobDetail.id));
            if (publishCurrentJob.fulfilled.match(resultAction)) {
                window.location.replace(resultAction.payload.redirectUrl)
            }
            ReactGA4.event({
                category: 'job',
                action: 'JOB_PUBLISH',
            })
        }
    }

    const onClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        if(!jobDetail || jobDetail.id !== job.id){
            // @ts-ignore
            dispatch(fetchJob(job.id)); // TODO partial update statt kompletten job laden
        }
        setOpen(true);
    }
    const handleDecline: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        setOpen(false);
    }
    const handleAccept: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        setOpen(false);
        switch (status) {
            case JobStatus.NEW.toString(): {
                handlePublish();
                break;
            }
            case JobStatus.PUBLISHED.toString(): {
                handlePublish();
                break;
            }
            case JobStatus.CLOSED.toString(): {
                handlePublish();
            }
        }
    }

    return (
        <Box display="flex" flex={1}>
            {button({onClick: onClick, children: btText, fullWidth: true})}
            <Dialog open={open}>
                <DialogContent style={{margin: '0px', padding: 0, backgroundColor: '#444', border: 'none'}}>
                    <Box display="flex" width="100%" height="100%" flexDirection="column" alignItems="center" gap="10px"
                         padding="20px">
                        <img alt="icon" src={imgSrc} height="40px"/>
                        <MultilineTypography display="flex" textAlign="center" variant="subtitle1"
                                             color="background.paper">
                            {infoText}
                        </MultilineTypography>
                        <Box display="flex" flexDirection="row" justifyContent="center" gap="10px">
                            <DeclineButton variant="outlined" onClick={handleDecline}>
                                <Typography variant="subtitle1" color="background.paper">{declineText}</Typography>
                            </DeclineButton>
                            <AcceptButton onClick={handleAccept}>
                                <Typography variant="subtitle1" color="background.paper">{acceptText}</Typography>
                            </AcceptButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default JobActionDialog;