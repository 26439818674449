import {useEffect, useState} from 'react';

import {db, Timestamp} from "../config/firestoreConfig";
import {addDoc, collection, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc,} from "firebase/firestore";
import {MessageObjectTo} from "../api/tos/chat/MessageObjectTo";
import {useSelector} from "react-redux";
import {selectCompany} from "../state/company/companySlice";
import {ChatOverviewObjectTo} from "../api/tos/chat/ChatOverviewObjectTo";
import {useFirestoreContext} from "../contexts/firestoreContext";
import {notifyAboutNewMessage} from "../api/chatApi";

export const CHATS_PATH = "chats"
export const MESSAGES_PATH = "messages"
export const USER_PATH = "user"
export const OVERVIEW_PATH = "overview"


export const useChat = (chat: ChatOverviewObjectTo | undefined) => {
    const {chatId, owner, participants, matchId} = chat ?? {};

    const {signedIn} = useFirestoreContext();

    const company = useSelector(selectCompany);
    // stores current author
    const authorId = owner;
    const authorName = company?.name;


    // stores messages of selected chat
    const [messages, setMessages] = useState<MessageObjectTo[]>([]);

    const addMessages = (newMessages: MessageObjectTo[]) => {
        setMessages(newMessages);
    }

    // Function to post a new message to Firestore
    const postMessage = async (text: string) => {
        const messageText = text.trim();
        if(!authorId || ! authorName) {
            return Promise.reject("no author")
        }
        if(!chatId) {
            return Promise.reject("no chat selected")
        }
        if(messageText.length === 0) {
            return Promise.reject("no message to send")
        }

        const sentAt = serverTimestamp() as Timestamp;
        const data: MessageObjectTo = {
            authorId,
            authorName,
            sentAt,
            text: messageText,
        }
        return addDoc(collection(db, CHATS_PATH, chatId, MESSAGES_PATH), data)
            .then(() => {
                updateOverviews(sentAt);
                if(matchId) {
                    notifyAboutNewMessage(matchId);
                }
            });
    };

    const updateOverviews = (sentAt: any) => {
        // owner
        if(owner) {
            updateOverviewSentAt(owner, sentAt, true);
        }
        // participants
        if(participants) {
            participants.forEach((participant) => {
                if(participant !== owner) {
                    updateOverviewSentAt(participant, sentAt);
                }
            })
        }
    }
    const updateOverviewSentAt = (userId: string, sentAt: any, forOwner?: boolean) => {
        const docId = `${userId}_${chatId}`;
        updateDoc(doc(db, USER_PATH, userId, OVERVIEW_PATH, docId), {
            lastSent: sentAt,
            ...(forOwner && { lastRead: sentAt })
        })
    }
    const updateOverviewRead = () => {
        if(owner) {
            const docId = `${owner}_${chatId}`;
            updateDoc(doc(db, USER_PATH, owner, OVERVIEW_PATH, docId), {
                lastRead: serverTimestamp(),
            })
        }
    }

    // load messages
    useEffect(() => {
        if(!chatId || !signedIn) {
            return;
        }

        const q = query(
            collection(db, CHATS_PATH, chatId, MESSAGES_PATH),
            orderBy("sentAt", "asc")
        );

        // update messages and return unsubscriber to destructor
        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            const fetchedMessages: MessageObjectTo[] = [];
            QuerySnapshot.forEach((doc) => {
                fetchedMessages.push(doc.data() as MessageObjectTo);
            });
            addMessages(fetchedMessages)
        });

        return () => {
            unsubscribe();
            updateOverviewRead();
        }
    }, [chatId, signedIn]);

    return { authorId, authorName,  messages, postMessage };
}