import React, {useEffect} from 'react';
import PageContainer from "../components/layout/pageLayouts/PageContainer";
import MatchOverviewCard from "../components/cards/MatchOverviewCard";
import JobOverviewCard from "../components/cards/JobOverviewCard";
import OverviewTable from "../components/layout/tables/overviewTable";
import {useDispatch, useSelector} from 'react-redux';
import {fetchMatches, selectMatches} from "../state/matches/matchesSlice";
import {fetchJobs, selectJobs} from "../state/jobs/jobsSlice";
import {setCurrentApplicationMenuItem} from "../state/menu/applicationMenuSlice";
import {JobStatus} from "./jobs/types";
import {selectCompany} from "../state/company/companySlice";
import {Typography} from "@mui/material";

function Dashboard() {

    const matches = useSelector(selectMatches).filter((match) => !match.interested) // only new (unaccepted) matches
    const jobs = useSelector(selectJobs).filter((job) => job.status.businessKey === JobStatus.PUBLISHED.toString())

    const company = useSelector(selectCompany);

    const dispatch = useDispatch();

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchMatches())
        //@ts-ignore
        dispatch(fetchJobs())
        dispatch(setCurrentApplicationMenuItem("Übersicht"))
    }, [dispatch])

    const matchesTitle = () => {
    if (matches.length > 5) {
            return "Hier sind deine 5 aktuellsten Matches";
    } else if (matches.length > 0) {
            return "Hier sind deine aktuellsten Matches";
        } else {
            return "Aktuell gibt es noch keine Matches. Das kann sich aber jederzeit ändern!";
        }
    }
    const moreMatches = matches.length > 5 ? "Weitere Matches findest du links im Menü unter \"Matches\"" : "";
    const jobsTitle = `Meine aktuellen Jobs`;

    // @ts-ignore
    return (
            <PageContainer title={`Moin${!!company ? ", " + company.name : ""}!`}>
                <OverviewTable
                    elements={[
                        [
                            {
                                name: matchesTitle(),
                                content: matches
                                    .slice(0, 5)
                                    .map((match) => (
                                        <MatchOverviewCard {...match} key={match.id}/>
                                    ))
                            },
                        ],
                        [
                            {
                                name: jobsTitle,
                                content: jobs
                                    .map((job) => (
                                        <JobOverviewCard {...job} key={job.id}/>
                                    ))
                            },
                        ],
                    ]}
                />
                <Typography mt="20px">{moreMatches}</Typography>
            </PageContainer>
    );
}

export default Dashboard;
