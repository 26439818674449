import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import General from "./newJob/General";
import TimeScope from "./newJob/TimeScope";
import Area from "./newJob/Area";
import Skills from "./newJob/Skills";
import Tools from "./newJob/Tools";
import Languages from "./newJob/Languages";
import SoftSkills from "./newJob/SoftSkills";
import {
    selectFormCurrentPage,
    selectFormLastCompletePage,
    setFormCurrentPage,
    setFormLastCompletePage
} from "../../state/multipageform/multipageFormSlice";
import {MultiFormPageI} from "../../components/menus/MultiPageFormMenu";
import {useNavigate} from "react-router-dom";
import {
    createNewJob,
    selectCreatedJobObject,
    selectCreateJobObject,
    setCreateJobObject
} from "../../state/jobs/createJobSlice";
import {URI_JOBS} from "../../config/Router";
import BottomNavigation from "../../components/navigation/BottomNavigation";
import {CreateJobObject} from "./types";
import {createJobObjectToCreateJobTo} from "../../services/jobService";
import Priorities from "./newJob/Priorities";


export const newJobPages: MultiFormPageI<CreateJobObject>[] = [
    {title: "Allgemeine Informationen", page: (props) => <General {...props}/>,},
    {title: "Umfang & Zeitraum", page: (props) => <TimeScope {...props}/>,},
    {title: "Tätigkeitsbereich", page: (props) => <Area {...props}/>,},
    {title: "Fertigkeiten", page: (props) => <Skills {...props}/>,},
    {title: "Programmskills", page: (props) => <Tools {...props}/>,},
    {title: "Priorisierung", page: (props) => <Priorities {...props}/>,},
    {title: "Sprachen", page: (props) => <Languages {...props}/>,},
    {title: "Softskills", page: (props) => <SoftSkills {...props}/>,},
]
const createJobText = "Job prüfen"

function NewJob() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentPage = useSelector(selectFormCurrentPage);
    const lastCompletePage = useSelector(selectFormLastCompletePage);
    const createJobObject = useSelector(selectCreateJobObject);
    const createdJobObjectTo = useSelector(selectCreatedJobObject);

    useEffect(() => {
        if(createdJobObjectTo) {
            navigate(`${URI_JOBS}/${createdJobObjectTo.id}`)
        }
    }, [createdJobObjectTo, navigate])

    if (currentPage > lastCompletePage + 1 || currentPage > newJobPages.length) {
        dispatch(setFormCurrentPage(lastCompletePage + 1));
        return (<></>);
    }

    const onNext = (newObject: CreateJobObject, valid: boolean) => {
        if(valid) {
            if(currentPage === newJobPages.length) {
                const createJobTo = createJobObjectToCreateJobTo(newObject);
                if(createJobTo){
                    // @ts-ignore
                    dispatch(createNewJob(createJobTo));
                }
            } else {
                const hasEffectOnFollowPages = [3, 4, 5].includes(currentPage); // changing area, skills or tools

                dispatch(setFormLastCompletePage(hasEffectOnFollowPages ? currentPage : Math.max(currentPage, lastCompletePage))) // if hasEffectOnFollowPages every following page needs to be reviewed
                dispatch(setFormCurrentPage(Math.min(currentPage+1, newJobPages.length)))
                dispatch(setCreateJobObject(newObject));
            }
        } else {
            dispatch(setFormLastCompletePage(currentPage-1))
            dispatch(setCreateJobObject(newObject));
        }
    }

    const onPrevious = (newObject: CreateJobObject, valid: boolean) => {
        if(valid) {
            dispatch(setFormLastCompletePage(Math.max(currentPage, lastCompletePage)))
        } else {
            dispatch(setFormLastCompletePage(currentPage-1))
        }
        dispatch(setFormCurrentPage(Math.max(currentPage-1, 1)))
        dispatch(setCreateJobObject(newObject));
    }

    const pageObject: MultiFormPageI<CreateJobObject> = newJobPages[currentPage - 1];
    return (
        <>
            <Typography variant="h2" paddingBottom="4px">{pageObject.title}</Typography>
            {pageObject.page({currentObject: createJobObject, onNext: onNext, onPrevious: onPrevious})}
            <BottomNavigation
                showPreviousButton={currentPage > 1}
                showNextButton
                nextButtonText={currentPage === newJobPages.length ? createJobText : undefined}
            />
        </>
    );
}

export default NewJob;