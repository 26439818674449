import React from 'react';
import Typography from "@mui/material/Typography";

interface PageContainerProps {
    title?: string
    description?: string,
    children: React.ReactElement | React.ReactElement[]
}

function PageContainer(props: PageContainerProps) {

    return (
        <div>
            {props.title ?
                <Typography variant="h1">
                    {props.title}
                </Typography> : ''
            }
            {props.description ?
                <Typography variant="body1" paddingTop="10px">
                    {props.description}
                </Typography> : ''
            }
            {props.children}
        </div>
    );
}

export default PageContainer;