import {Link, useLocation} from 'react-router-dom';
import React, {Dispatch, MouseEventHandler, ReactNode, SetStateAction, useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogContent, Grid, Paper, styled, Typography, useTheme} from '@mui/material';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import {BoxProps} from "@mui/material/Box";
import {TypographyProps} from "@mui/material/Typography";
import {URI_COMPANY_PROFILE, URI_JOBS_NEW} from "../../config/Router";
import ContentPage3ColumnsLayout from "../../components/layout/pageContent/ContentPage3ColumnsLayout";
import {CompanyDetailObject} from "./types";
import CreateNewJobButton from "../../components/buttons/CreateNewJobButton";
import {StyledChip} from "../../components/chips/StyledChip";
import {ButtonProps} from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import {MultilineTypography} from "../../components/typographys/MultilineTypography";
import {FormToggleButtonGroup} from "../../components/forms/FormToggleButtonGroup";
import {useForm} from "react-hook-form";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ImageUpload, {FileData, ImageUploadBox} from "../../components/controls/ImageUpload";
import {AcceptButton, DeclineButton} from "../../components/buttons/CommonButtons";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCompanyBenefits,
    selectCompanyBenefitByBusinessKey,
    selectCompanyBenefits
} from "../../state/basedata/companyBenefitsSlice";
import CustomEditButton from "../../components/buttons/CustomEditButton";
import CustomPopover, {TitleBox} from "../../components/controls/CustomPopover";
import {selectCompany, updateCompany} from "../../state/company/companySlice";
import {companyObjectToUpdateCompanyTo, companyToToCompanyObject} from "../../services/companyService";
import {FormTextField} from "../../components/forms/FormTextField";
import {MatchSegmentation} from "../../api/tos/matches/MatchDetailObjectTo";

const DialogBox = styled(Box)<BoxProps>(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "30px",
    borderRadius: '5px',
    gap: '5px',
    backgroundColor: '#444444',
}))
const StyledTypography = styled(Typography)<TypographyProps>(({theme}) => ({
    color: theme.palette.background.paper,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
}))

function WelcomeDialog() {
    const imgPath = "/backgrounds/Farbverlauf.jpg"

    return (
        <Box position="fixed"
             zIndex={2}>
            <Box
                position="fixed"
                top={0}
                left={0}
                width="100%"
                height="100%"
            >
                <img alt="bgImg" src={imgPath} height="100%" width="100%" style={{
                    height: '100%',
                    width: '100%',
                    opacity: 0.5
                }}></img>
            </Box>
            <Box
                position="fixed"
                top={0}
                left={0}
                zIndex={3}
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <DialogBox>
                    <CelebrationOutlinedIcon sx={{scale: "200%"}} color="secondary"/>
                    <StyledTypography variant="h4" paddingTop="20px">WILLKOMMEN BEI SKILLBASED!</StyledTypography>
                    <StyledTypography variant="subtitle1">Dein Profil wurde erfolgreich angelegt!</StyledTypography>
                    <StyledTypography variant="subtitle1">Lege jetzt los und erstelle deinen ersten
                        Job.</StyledTypography>
                    <Link to={URI_JOBS_NEW}>
                        <Button sx={{backgroundColor: 'primary.main', '&:hover': {backgroundColor: 'primary.dark'}}}>
                            <Typography variant="subtitle1" color="background.paper">Neuen Job anlegen</Typography>
                        </Button>
                    </Link>
                    <Link to={URI_COMPANY_PROFILE}>
                        <Button sx={{backgroundColor: '#444444', '&:hover': {backgroundColor: '#383838'}}}>
                            <Typography variant="subtitle1" color="background.paper">Vielleicht später</Typography>
                        </Button>
                    </Link>
                </DialogBox>
            </Box>
        </Box>
    );
}

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}


interface CompanyDetailsProps {
    companyObject: CompanyDetailObject,
    updateCompanyObject: (obj: CompanyDetailObject) => void,
}


interface PopoverProps {
    anchorEl: HTMLElement | null,
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>,
    companyObject: CompanyDetailObject,
    updateCompanyObject: (obj: CompanyDetailObject) => void,
}


function LogoPopover({anchorEl, setAnchorEl, companyObject, updateCompanyObject}: PopoverProps) {
    const [imgData, setImgData] = useState<FileData[]>([]);

    const formContext = useForm();
    const {handleSubmit} = formContext;

    const logoData = imgData.length > 0 ? imgData[0].base64Data : "";

    const onSubmit = () => {
        const newCompanyObject: CompanyDetailObject = {
            ...companyObject,
            logo: logoData,
        }
        updateCompanyObject(newCompanyObject);
        setAnchorEl(null);
    }

    const handleDecline = () => {
        setImgData([]);
        setAnchorEl(null);
    }

    return (
        <CustomPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} onDecline={handleDecline}>
            <form id="multipage_form" onSubmit={handleSubmit(onSubmit)}>
            {logoData
                ? <>
                    <Typography variant="subtitle1" pt={2}>Firmenlogo</Typography>
                    <img src={logoData} alt="logo" style={{width: '100%', maxWidth: '100%', maxHeight: '60%'}}/>
                </>
                : <>
                    <ImageUpload
                        name="logo-upload"
                        label="Firmenlogo"
                        setSelected={setImgData}
                    />
                </>
            }
            </form>
        </CustomPopover>
    )
}

function Logo({companyObject, updateCompanyObject}: CompanyDetailsProps) {
    const logoData = companyObject.logo ? companyObject.logo : "";

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    return (
        <Box position="relative" display="inline-block" width="80%" paddingTop="80%">
            <LogoPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                companyObject={companyObject}
                updateCompanyObject={updateCompanyObject}
            />
            <CenteredImageBox backgroundColor={theme.palette.background.paper}
                              style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                <img src={logoData} alt="logo"
                     style={{maxWidth: '90%', maxHeight: '90%', margin: 'auto'}}/>
            </CenteredImageBox>
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                p="5px"
                display="flex"
                flexDirection="row"
                justifyContent="right"
            >
                <CustomEditButton mode="edit" onClick={handleClick}/>
            </Box>
        </Box>
    )
}

type TitleFormData = {
    title: string,
}

function TitlePopover({anchorEl, setAnchorEl, companyObject, updateCompanyObject}: PopoverProps) {
    const formContext = useForm<TitleFormData>({
        defaultValues: {
            title: "",
        }
    })
    const {control, handleSubmit, formState: {errors}} = formContext;

    const onSubmit = (data: TitleFormData) => {
        if (data.title !== companyObject.companyName) {
            const newCompanyObject: CompanyDetailObject = {
                ...companyObject,
                companyName: data.title
            }
            updateCompanyObject(newCompanyObject);
        }
        setAnchorEl(null);
    }

    const handleDecline = () => {
        formContext.setValue("title", companyObject.companyName);
        setAnchorEl(null);
    }

    useEffect(() => {
        formContext.setValue("title", companyObject.companyName);
    }, [companyObject])

    return (
        <CustomPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} onDecline={handleDecline}>
            <form id="multipage_form" onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h3">Firmenname</Typography>
                <FormTextField
                    control={control}
                    name="title"
                    label=""
                    rules={{required: true}}
                    error={errors.title}
                    errorMessage="Bitte Firmennamen angeben"
                />
            </form>
        </CustomPopover>
    )
}

function Title({companyObject, updateCompanyObject}: CompanyDetailsProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    return (
        <div>
            <TitlePopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                companyObject={companyObject}
                updateCompanyObject={updateCompanyObject}
            />
            <TitleBox>
                <Typography variant="h2">{companyObject.companyName}</Typography>
                <CustomEditButton mode="edit" onClick={handleClick}/>
            </TitleBox>
        </div>
    )
}

type DescriptionFormData = {
    description: string,
    employees: string,
}

function DescriptionPopover({anchorEl, setAnchorEl, companyObject, updateCompanyObject}: PopoverProps) {
    const formContext = useForm<DescriptionFormData>({
        defaultValues: {
            description: "",
            employees: "",
        }
    })
    const {control, handleSubmit, formState: {errors}} = formContext;

    const onSubmit = (data: DescriptionFormData) => {
        if (data.description !== companyObject.description
            || data.employees !== companyObject.employees
        ) {
            const newCompanyObject: CompanyDetailObject = {
                ...companyObject,
                description: data.description,
                employees: data.employees
            }
            updateCompanyObject(newCompanyObject);
        }
        setAnchorEl(null);
    }

    const handleDecline = () => {
        formContext.setValue("description", companyObject.description);
        formContext.setValue("employees", companyObject.employees);
        setAnchorEl(null);
    }

    useEffect(() => {
        formContext.setValue("description", companyObject.description);
        formContext.setValue("employees", companyObject.employees);
    }, [companyObject])

    return (
        <CustomPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} onDecline={handleDecline}>
            <form id="multipage_form" onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h3">Über uns</Typography>
                <FormTextField
                    control={control}
                    name="description"
                    label=""
                    rules={{required: true}}
                    error={errors.description}
                    errorMessage="Bitte Beschreibung hinzufügen"
                    defaultValue={companyObject.description}
                    multiline
                    minRows={5}
                    maxRows={15}
                />
                <Typography variant="subtitle1">Anzahl Mitarbeiter</Typography>
                <FormTextField
                    control={control}
                    name="employees"
                    label=""
                    rules={{required: true}}
                    error={errors.employees}
                    errorMessage="Bitte Mitarbeiteranzahl angeben"
                    defaultValue={companyObject.employees}
                    type="number"
                />
            </form>
        </CustomPopover>
    )
}

function Description({companyObject, updateCompanyObject}: CompanyDetailsProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    return (
        <div>
            <DescriptionPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                companyObject={companyObject}
                updateCompanyObject={updateCompanyObject}
            />
            <TitleBox>
                <Typography variant="h3">Über uns</Typography>
                <CustomEditButton mode="edit" onClick={handleClick}/>
            </TitleBox>
            <MultilineTypography width="100%" variant="body1"
                                 paddingY="10px">{companyObject.description}</MultilineTypography>
            <Typography variant="subtitle1">{`Anzahl Mitarbeitende: ${companyObject.employees}`}</Typography>
        </div>
    )
}

type ValuesAndVisionFormData = {
    companyValues: string,
    vision: string,
}

function ValuesAndVisionPopover({anchorEl, setAnchorEl, companyObject, updateCompanyObject}: PopoverProps) {
    const formContext = useForm<ValuesAndVisionFormData>({
        defaultValues: {
            companyValues: "",
            vision: "",
        }
    })
    const {control, handleSubmit} = formContext;

    const onSubmit = (data: ValuesAndVisionFormData) => {
        if (data.companyValues !== companyObject.companyValues
            || data.vision !== companyObject.vision
        ) {
            const newCompanyObject: CompanyDetailObject = {
                ...companyObject,
                companyValues: data.companyValues,
                vision: data.vision,
            }
            updateCompanyObject(newCompanyObject);
        }
        setAnchorEl(null);
    }

    const handleDecline = () => {
        formContext.setValue("companyValues", companyObject.companyValues ?? '');
        formContext.setValue("vision", companyObject.vision ?? '');
        setAnchorEl(null);
    }

    useEffect(() => {
        formContext.setValue("companyValues", companyObject.companyValues ?? '');
        formContext.setValue("vision", companyObject.vision ?? '');
    }, [companyObject])

    return (
        <CustomPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} onDecline={handleDecline}>
            <form id="multipage_form" onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h3">Werte und Vision</Typography>
                <FormTextField
                    control={control}
                    label="Welche Werte vertritt dein Unternehmen?"
                    rules={{required: false}}
                    name="companyValues"
                    multiline
                    minRows={5}
                    maxRows={10}
                />
                <FormTextField
                    control={control}
                    label="Welche Vision verfolgt dein Unternehmen?"
                    rules={{required: false}}
                    name="vision"
                    multiline
                    minRows={5}
                    maxRows={10}
                />
            </form>
        </CustomPopover>
    )
}

function ValuesAndVision({companyObject, updateCompanyObject}: CompanyDetailsProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    return (
        <div>
            <ValuesAndVisionPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                companyObject={companyObject}
                updateCompanyObject={updateCompanyObject}
            />
            <TitleBox>
                <Typography variant="h3">Werte und Vision</Typography>
                <CustomEditButton mode="edit" onClick={handleClick}/>
            </TitleBox>
            <Typography variant="subtitle1" pt={1}>Welche Werte vertritt dein Unternehmen?</Typography>
            <MultilineTypography width="100%" variant="body1"
                                 paddingY="10px">{companyObject.companyValues}</MultilineTypography>
            <Typography variant="subtitle1" pt={1}>Welche Vision verfolgt dein Unternehmen?</Typography>
            <MultilineTypography width="100%" variant="body1"
                                 paddingY="10px">{companyObject.vision}</MultilineTypography>
        </div>
    )
}

type BenefitsFormData = {
    benefits: string[],
}

function BenefitsPopover({anchorEl, setAnchorEl, companyObject, updateCompanyObject}: PopoverProps) {
    const dispatch = useDispatch();
    const benefits = useSelector(selectCompanyBenefits);

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchCompanyBenefits())
    }, [dispatch])

    const formContext = useForm<BenefitsFormData>({
        defaultValues: {
            benefits: [],
        }
    })
    const {control, handleSubmit} = formContext;

    const onSubmit = (data: BenefitsFormData) => {
        if (data.benefits !== companyObject.benefits) {
            const newCompanyObject: CompanyDetailObject = {
                ...companyObject,
                benefits: data.benefits
            }
            updateCompanyObject(newCompanyObject);
        }
        setAnchorEl(null);
    }

    const handleDecline = () => {
        formContext.setValue("benefits", companyObject.benefits);
        setAnchorEl(null);
    }

    useEffect(() => {
        formContext.setValue("benefits", companyObject.benefits);
    }, [companyObject])

    return (
        <CustomPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} onDecline={handleDecline}>
            <Typography variant="h3">Benefits</Typography>
            <form id="multipage_form" onSubmit={handleSubmit(onSubmit)}>
                <FormToggleButtonGroup
                    control={control}
                    name="benefits"
                    options={benefits}
                />
            </form>
        </CustomPopover>
    )
}

function Benefits({companyObject, updateCompanyObject}: CompanyDetailsProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const getBenefitByBusinessKey = useSelector(selectCompanyBenefitByBusinessKey);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    return (
        <div>
            <BenefitsPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                companyObject={companyObject}
                updateCompanyObject={updateCompanyObject}
            />
            <TitleBox>
                <Typography variant="h3">Benefits</Typography>
                <CustomEditButton mode="edit" onClick={handleClick}/>
            </TitleBox>
            <Grid container display="flex" mt="10px" spacing={2} direction="row">
                {companyObject.benefits.map((benefit, key) => (
                    <Grid item key={key}>
                        <StyledChip match={MatchSegmentation.ADDITIONAL} label={getBenefitByBusinessKey(benefit)?.description}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

type AddressFormData = {
    street: string,
    zipCode: string,
    city: string,
}

function ContactPopover({anchorEl, setAnchorEl, companyObject, updateCompanyObject}: PopoverProps) {

    // const [contacts, setContacts] = useState<ContactObject[]>([]);
    //
    //
    // const formContextContacts = useForm<ContactObject>({
    //     defaultValues: {
    //         name: "",
    //         email: "",
    //         // roleId: null,
    //     }
    // })
    //
    // const onSubmitContacts = (data: ContactObject) => {
    //     const newContacts: ContactObject[] = [];
    //
    //     newContacts.push(...contacts);
    //
    //     if (data.name !== ""
    //         && data.email !== "") {
    //         // && data.roleId !== null && data.roleId !== "") {
    //         newContacts.push(data);
    //         formContextContacts.reset();
    //     }
    //
    //     setContacts(newContacts);
    // }

    const formContextAddress = useForm<AddressFormData>({
        defaultValues: {
            street: "",
            zipCode: "",
            city: "",
        }
    })

    const onSubmit = (data: AddressFormData) => {
        if (data.street !== companyObject.street
        || data.zipCode !== companyObject.zipCode
        || data.city !== companyObject.city) {
            const newCompanyObject: CompanyDetailObject = {
                ...companyObject,
                street: data.street,
                zipCode: data.zipCode,
                city: data.city
            }
            updateCompanyObject(newCompanyObject);
        }
        setAnchorEl(null);
    }

    const handleDecline = () => {
        //setContacts(companyObject.contacts);
        formContextAddress.setValue("street", companyObject.street);
        formContextAddress.setValue("zipCode", companyObject.zipCode);
        formContextAddress.setValue("city", companyObject.city);
    }

    useEffect(() => {
        //setContacts(companyObject.contacts);
        formContextAddress.setValue("street", companyObject.street);
        formContextAddress.setValue("zipCode", companyObject.zipCode);
        formContextAddress.setValue("city", companyObject.city);
    }, [companyObject])

    return (
        <CustomPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} onDecline={handleDecline}>
            <Typography variant="h3">Unsere Adresse</Typography>
            <Typography variant="subtitle2">Bitte trage hier die Adresse eures Firmensitzes ein. In den jeweiligen Jobs kann der Ort der Arbeitserbringung angepasst werden.</Typography>
            {/*<Typography variant="subtitle1">Dein/e Ansprechpartner*in</Typography>*/}
            {/*<form*/}
            {/*    onSubmit={formContextContacts.handleSubmit(onSubmitContacts)}*/}
            {/*>*/}
            {/*    <FormTextField*/}
            {/*        control={formContextContacts.control}*/}
            {/*        label="Name"*/}
            {/*        name="name"*/}
            {/*    />*/}
            {/*    <FormTextField*/}
            {/*        control={formContextContacts.control}*/}
            {/*        label="E-Mailaddresse"*/}
            {/*        name="email"*/}
            {/*    />*/}
            {/*    <FormToggleButtonGroup*/}
            {/*        control={formContextContacts.control}*/}
            {/*        name="roleId"*/}
            {/*        label=""*/}
            {/*        options={sampleRoleOptions}*/}
            {/*        exclusive*/}
            {/*    />*/}
            {/*    <AddButton/>*/}
            {/*</form>*/}
            {/*<DisplayContacts items={contacts} setItems={setContacts}/>*/}
            {/*<Typography variant="subtitle1">Unsere Adresse</Typography>*/}
            <form id="multipage_form" onSubmit={formContextAddress.handleSubmit(onSubmit)}>
                <FormTextField
                    control={formContextAddress.control}
                    label="Straße, Hausnummer"
                    error={formContextAddress.formState.errors.street}
                    errorMessage="Bitte Straße und Hausnummer angeben"
                    name="street"
                    rules={{required: true}}
                />
                <Grid container columns={3} spacing="10px">
                    <Grid item xs={1}>
                        <FormTextField
                            control={formContextAddress.control}
                            label="PLZ"
                            error={formContextAddress.formState.errors.zipCode}
                            errorMessage="Bitte Postleitzahl angeben"
                            name="zipCode"
                            type="number"
                            rules={{required: true}}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormTextField
                            control={formContextAddress.control}
                            label="Stadt"
                            error={formContextAddress.formState.errors.city}
                            errorMessage="Bitte Stadt angeben"
                            name="city"
                            rules={{required: true}}
                        />
                    </Grid>
                </Grid>
            </form>
        </CustomPopover>
    )
}

function Contact({companyObject, updateCompanyObject}: CompanyDetailsProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    return (
        <div>
            <ContactPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                companyObject={companyObject}
                updateCompanyObject={updateCompanyObject}
            />
            <TitleBox>
                <Typography variant="h3">Unsere Adresse</Typography>
                <CustomEditButton mode="edit" onClick={handleClick}/>
            </TitleBox>
            {/*<Typography variant="subtitle1">Dein/e Ansprechpartner*in</Typography>*/}
            {/*{companyObject.contacts.map((contact, key) => (*/}
            {/*    <Box key={key}>*/}
            {/*        <Typography variant="body1">{contact.name}</Typography>*/}
            {/*        <Button startIcon={<EmailOutlinedIcon/>}*/}
            {/*                sx={{backgroundColor: 'background.paper'}}>{contact.email}</Button>*/}
            {/*    </Box>*/}
            {/*))}*/}
            {/*<Typography variant="subtitle1" pt="20px">Unsere Adresse</Typography>*/}
            <Typography variant="body1">{companyObject.street} {companyObject.streetNumber}</Typography>
            <Typography variant="body1">{`${companyObject.zipCode} ${companyObject.city}`}</Typography>
        </div>
    )
}

interface CenteredImageBoxProps extends BoxProps {
    children: ReactNode;
    backgroundColor: string,
    withoutPaper?: boolean,
}

function CenteredImageBox({children, backgroundColor, withoutPaper, ...props}: CenteredImageBoxProps) {

    return (
        <Box {...props} display="flex" justifyContent="center" height="100%" width="100%">
            {!!withoutPaper
                ? <>{children}</>
                : <Paper style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: 0,
                    backgroundColor: backgroundColor
                }}>
                    {children}
                </Paper>
            }
        </Box>
    );
}

interface ImagePopoverProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
}

function ImagePopover({open, setOpen}: ImagePopoverProps) {
    const images: string[] = ["/company/picture_1.jpg", "/company/picture_2.jpg"]

    const theme = useTheme();

    const handleDecline: MouseEventHandler<HTMLButtonElement> = () => {
        setOpen(false);
    }
    const handleAccept: MouseEventHandler<HTMLButtonElement> = () => {
        setOpen(false);
    }

    return (
        <Dialog open={open}>
            <DialogContent style={{backgroundColor: '#444', border: 'none'}}>
                <Box display="flex" flexDirection="column" alignItems="center" padding="40px" gap="20px">
                    <Typography variant="h3" color="background.paper">Fotos editieren</Typography>
                    <MultilineTypography variant="body1" color="background.paper" width="85%">Hier kannst du
                        Unternehmensbilder
                        hinzufügen,<br/>löschen und die Reihenfolge ändern.</MultilineTypography>
                    <Grid container display="flex" flexDirection="row" width="85%">
                        {images.map((image, key) => (
                            <Grid item key={key} height="100px" width="100px">
                                <Box position="relative" display="inline-block" width="90%" height="90%">
                                    <CenteredImageBox backgroundColor={theme.palette.text.primary}>
                                        <img src={image} alt="img"
                                             style={{maxWidth: '100%', maxHeight: '100%', margin: 'auto'}}/>
                                    </CenteredImageBox>
                                    <Box
                                        position="absolute"
                                        top={0}
                                        left={0}
                                        width="100%"
                                        height="100%"
                                        p="5px"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="right"
                                        alignItems="start"
                                        border={`1.5px dashed ${theme.palette.text.disabled}`}
                                    >
                                        <Button style={{
                                            minWidth: 0,
                                            minHeight: 0,
                                            padding: 0,
                                            border: `1.5px solid ${theme.palette.text.disabled}`,
                                            color: theme.palette.text.disabled
                                        }}>
                                            <CloseIcon style={{fontSize: "0.8rem"}}/>
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                        <Grid item height="90px" width="90px">
                            <CenteredImageBox backgroundColor={theme.palette.text.primary}>
                                <ImageUploadBox setSelected={() => {
                                }} color={theme.palette.text.disabled}/>
                            </CenteredImageBox>
                        </Grid>
                    </Grid>
                    <Box display="flex" flexDirection="row" justifyContent="center" flex={1} gap="10px">
                        <DeclineButton variant="outlined" onClick={handleDecline}>
                            <Typography variant="body1" color="background.paper">Schließen</Typography>
                        </DeclineButton>
                        <AcceptButton onClick={handleAccept}>
                            <Typography variant="body1" color="background.paper">Speichern</Typography>
                        </AcceptButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const ImageNavButton = styled(Button)<ButtonProps>(() => ({
    minWidth: 0,
    width: '2rem',
    height: '2rem',
    backgroundColor: 'rgba(200,200,200,0.8)',
}))

function ImageView() {
    const images: string[] = ["/company/picture_1.jpg", "/company/picture_2.jpg"]

    const [currentImage, setCurrentImage] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();
    const hasNext = () => {
        return currentImage + 1 < images.length;
    }
    const hasPrevious = () => {
        return currentImage > 0;
    }
    const handleNext = () => {
        if (hasNext()) {
            setCurrentImage(currentImage + 1);
        }
    }

    const handlePrevious = () => {
        if (hasPrevious()) {
            setCurrentImage(currentImage - 1);
        }
    }

    return (
        <Box paddingTop="30px">
            {images.length > 0 &&
                <Box position="relative" display="inline-block" width="100%" paddingTop="100%">
                    <CenteredImageBox backgroundColor={theme.palette.background.paper}
                                      style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                        <img alt="img" src={images[currentImage]}
                             style={{maxWidth: '100%', maxHeight: '100%', margin: 'auto'}}/>
                    </CenteredImageBox>
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        p="5px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                    >
                        <Box display="flex" justifyContent="right" height="30px">
                            <CustomEditButton mode="edit" onClick={() => setDialogOpen(true)}/>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <ImageNavButton disabled={!hasPrevious()} onClick={handlePrevious}>
                                <ArrowBackIosNewOutlinedIcon/>
                            </ImageNavButton>
                            <ImageNavButton disabled={!hasNext()} onClick={handleNext}>
                                <ArrowForwardIosOutlinedIcon/>
                            </ImageNavButton>
                        </Box>
                        <Box height="30px"></Box>
                    </Box>
                </Box>
            }
            <Grid container display="flex" flexDirection="row" maxWidth="100%" spacing="5px">
                {images.map((image, key) => (
                    <Grid item key={key} onClick={() => setCurrentImage(key)} height="60px" width="60px">
                        <CenteredImageBox backgroundColor={theme.palette.background.paper}>
                            <img alt="img" src={image}
                                 style={{
                                     maxWidth: '100%',
                                     maxHeight: '100%',
                                     margin: 'auto',
                                     opacity: key === currentImage ? 0.5 : 1.0
                                 }}/>
                        </CenteredImageBox>
                    </Grid>
                ))}
                <Grid item height="60px" width="60px">
                    <CenteredImageBox backgroundColor={theme.palette.background.paper}>
                        <ImageUploadBox setSelected={() => {
                        }} color={theme.palette.primary.main}/>
                    </CenteredImageBox>
                </Grid>
            </Grid>
            <ImagePopover open={dialogOpen} setOpen={setDialogOpen}/>
        </Box>
    )
}

function RightColumn() {
    return (
        <Box display="flex" flexDirection="column" gap="18px">
            <Box display="flex" justifyContent="right">
                <CreateNewJobButton/>
            </Box>
            {/*<ImageView/> TODO insert again*/}
        </Box>
    )
}

function CompanyProfile() {
    let query = useQuery();
    let welcome = query.get("welcome")
    const dispatch = useDispatch();
    const company = useSelector(selectCompany);

    if (!company) {
        return <>Hier erscheint das Profil</>
    }

    const updateCompanyObject = (updateCompanyObject: CompanyDetailObject) => {
        const updateTo = companyObjectToUpdateCompanyTo(updateCompanyObject, company.id);
        if (updateTo) {
            // @ts-ignore
            dispatch(updateCompany(updateTo));
        }
    }
    const companyObject = companyToToCompanyObject(company);

    return (
        <div>
            {welcome && <WelcomeDialog/>}
            {companyObject === null
                ? <Typography variant="subtitle1">Hier erscheint Dein Company Profil.</Typography>
                : <ContentPage3ColumnsLayout
                    childrenLeft={<Logo companyObject={companyObject} updateCompanyObject={updateCompanyObject}/>}
                    childrenRight={<RightColumn/>}
                >
                    <Box display="flex" flexDirection="column" gap="30px">
                        <Title companyObject={companyObject} updateCompanyObject={updateCompanyObject}/>
                        <Description companyObject={companyObject} updateCompanyObject={updateCompanyObject}/>
                        <ValuesAndVision companyObject={companyObject} updateCompanyObject={updateCompanyObject}/>
                        <Benefits companyObject={companyObject} updateCompanyObject={updateCompanyObject}/>
                        <Contact companyObject={companyObject} updateCompanyObject={updateCompanyObject}/>
                    </Box>
                </ContentPage3ColumnsLayout>
            }
        </div>
    );
}

export default CompanyProfile;