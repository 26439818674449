import * as React from 'react';
import {useContext} from 'react';
import {appBarMenuItems, ROOT} from "../../config/Router";
import MenuItem from "@mui/material/MenuItem";
import {Link, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import Menu from "@mui/material/Menu";
import {OverviewButton, ProfileImageButton} from "../layout/menuButtons";
import Box from "@mui/material/Box";
import {AuthContext} from "../../contexts/authContext";
import {useSelector} from "react-redux";
import {selectCompany} from "../../state/company/companySlice";
import ReactGA from "react-ga4";
import {useFirestoreContext} from "../../contexts/firestoreContext";


function AppBarMenu() {
    const navigate = useNavigate();
    const company = useSelector(selectCompany)
    const companyName = company?.name || "Deine Firma";
    const auth = useContext(AuthContext);
    const {signOut} = useFirestoreContext();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const logout = async () => {
        await navigate(ROOT);
        ReactGA.event({
            category: 'user',
            action: 'LOGOUT',
        })
        signOut();
        auth.signOut();
    };

    const handleClose = () => {
        setAnchorElUser(null);
    }

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    return (
        <Box sx={{flexGrow: 0, textAlign: "right", paddingRight: '47px'}}>
            <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center">
                <Link to={ROOT} style={{textDecoration: 'none'}}>
                    <OverviewButton />
                </Link>
                <Typography variant="h5" color="text.primary" paddingX="10px">|</Typography>
                <ProfileImageButton companyName={companyName}
                                    onOpenMenuClick={handleOpenUserMenu}/>
            </Box>
            <Menu
                sx={{mt: "45px"}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleClose}
            >
                {appBarMenuItems.map((menuItem) => (
                    <MenuItem key={menuItem.title}>
                        <Link to={menuItem.path} style={{textDecoration: 'none'}} onClick={handleClose}>
                            <Typography color="primary">{menuItem.title}</Typography>
                        </Link>
                    </MenuItem>
                ))}
                <Divider/>
                <MenuItem key="logout"
                          onClick={logout}>
                    Logout
                </MenuItem>
            </Menu> </Box>
    );
}

export default AppBarMenu;