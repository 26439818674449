import {BaseDataObjectTo} from "../baseData/BaseDataObjectTo";
import {LanguageRequirementsTo} from "../common/LanguageRequirementsTo";
import {SoftSkillDetailsTo} from "../common/SoftSkillDetailsTo";
import {MatchOverviewObjectTo} from "./MatchOverviewObjectTo";

export interface MatchDetailObjectTo extends MatchOverviewObjectTo {
    email: string,
    aboutMe: string,
    aboutMeExtended: string,
    vision: string,
    hoursPerWeek: number,
    vacationDaysPerYear: number,
    workload: MatchDataTo,
    earliestEntryDate: Date,
    limitedJobOffer: boolean,
    jobDuration: MatchDataTo,
    workplaces: MatchDataTo[],
    jobLocationPostCode: string,
    jobLocationCity: string,
    skills: MatchDataTo[],
    tools: MatchDataTo[],
    languageRequirements: MatchLanguageRequirementsTo[],
    jobSoftSkills: SoftSkillDetailsTo,
    candidateSoftSkills: SoftSkillDetailsTo,
}

export interface MatchDataTo extends BaseDataObjectTo {
    segmentation: MatchSegmentation,
}

export interface MatchLanguageRequirementsTo extends LanguageRequirementsTo{
    segmentation: MatchSegmentation,
}

export enum MatchSegmentation {
    MATCH= "MATCH",
    ADDITIONAL = "ADDITIONAL",
    MISSING ="MISSING",
    NONE = "NONE",
}