import {Box, Button, styled, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {BoxProps} from "@mui/material/Box";
import {useRef} from "react";


const InnerBox = styled(Box)<BoxProps>(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `2px solid ${theme.palette.text.disabled}`,
    color: theme.palette.text.disabled,
    borderRadius: '5px',
    minHeight: '2.75rem'
}))

interface SearchBoxProps {
    label?: string,
    onKeyChange?: (input: string | null) => void,
}
function SearchBox({label, onKeyChange}: SearchBoxProps) {
    const textFieldRef = useRef<HTMLInputElement | null>(null);

    const handleClear = () => {
        if (textFieldRef.current) {
            textFieldRef.current.value = '';
            if(onKeyChange) {
                onKeyChange("");
            }
        }
    }

    return(
        <Box display="flex" flex={1} flexDirection="column" paddingY="10px">
            <Typography variant="subtitle1">{label}</Typography>
            <InnerBox>
                <SearchOutlinedIcon />
                <TextField
                    inputRef={textFieldRef}
                    onChange={(event) => {onKeyChange && onKeyChange(event.target.value)}}
                    fullWidth
                    variant="standard"
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            border: 'none',
                        },
                    }}
                />
                <Button onClick={handleClear} style={{paddingRight: '20px'}}>
                    Zurücksetzen
                </Button>
            </InnerBox>
        </Box>
    )
}

export default SearchBox;