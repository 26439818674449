import {ReactNode, useEffect, useState} from "react";
import ResponsiveAppBar from "../components/layout/response-app-bar";
import {Box, styled} from "@mui/material";
import * as React from "react";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import Typography, {TypographyProps} from "@mui/material/Typography";
import ResponsiveBottomBar from "../components/layout/responsive-bottom-bar";

const MOBILE_WIDTH = 1024;

const StyledTypography = styled(Typography)<TypographyProps>(() => ({
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
}));

function InfoScreen() {
    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" flex={1} bgcolor="#444" height="100vh">
            <ResponsiveAppBar disableMenu={true}/>
            <Box display="flex" flexDirection="column" flex={1} justifyContent="space-between" alignItems="center" padding="30px" maxHeight="700px">
                <div></div>
                <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                    <CelebrationOutlinedIcon color="secondary" fontSize="large"/>
                    <StyledTypography variant="h6">WILKOMMEN BEI SKILLBASED!</StyledTypography>
                </Box>
                <StyledTypography variant="subtitle1">{"Unsere Webapp für Unternehmen ist derzeit nur über Tablet (Querformat) oder Laptop/ Computer verfügbar.\nAn einer Mobilen Version arbeiten wir noch."}</StyledTypography>
                <StyledTypography variant="body1">Als Kanditat*In kannst du dir unsere mobile App im Appstore oder im Google Play Store herunterladen.</StyledTypography>
                <div></div>
            </Box>
            <ResponsiveBottomBar mobile={true}/>
        </Box>
    )
}

interface MobileGuardProps {
    children: ReactNode | ReactNode[] | undefined
}
function MobileGuard({children}: MobileGuardProps) {

    const [isMobile, setIsMobile] = useState(false);

    window.addEventListener('resize', () => {
        setIsMobile(window.innerWidth < MOBILE_WIDTH);
    })

    useEffect(() => {
        setIsMobile(window.innerWidth < MOBILE_WIDTH);
    }, [])

    return (
        <>
            {isMobile && <InfoScreen />}
            {!isMobile && children}
        </>
    )
}

export default MobileGuard;