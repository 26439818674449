import {addDays, differenceInCalendarDays, format} from "date-fns";

const DATE_DISPLAY_FORMAT = "dd.MM.yyyy";
const DATE_TIME_FORMAT = "dd.MM.yyyy hh:mm";

export function stringToDate(value: string | undefined) {
  if(!value || value === "") {
    return new Date();
  }
  return new Date(value);
}

export function dateToString(date: Date | undefined) {
  return date?.toISOString() || "";
}

export function displayDate(date: Date | string) {
  const dateD = date instanceof Date ? date : stringToDate(date);
  return format(dateD, DATE_DISPLAY_FORMAT);
}
export function displayDateAndTime(date: Date | string) {
  const dateD = date instanceof Date ? date : stringToDate(date);
  return format(dateD, DATE_TIME_FORMAT);
}

export function getDateDisplayFormat() {
  return DATE_DISPLAY_FORMAT;
}

export function compareDates(a: string | Date, b: string | Date): number {
  const dateA = a instanceof Date ? a : stringToDate(a);
  const dateB = b instanceof Date ? b : stringToDate(b);
  return dateA.getTime() - dateB.getTime();
}

export function dayDif(a: string | Date, b: string | Date): number {
  const dateA = a instanceof Date ? a : stringToDate(a);
  const dateB = b instanceof Date ? b : stringToDate(b);
  return differenceInCalendarDays(dateA, dateB);
}

export function plusDays(date: string | Date, days: number): Date {
  const dateD = date instanceof Date ? date : stringToDate(date);
  return addDays(dateD, days);
}