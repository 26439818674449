import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

export interface MultiPageFormState {
    currentPage: number;
    lastCompletePage: number,
}

const initialState: MultiPageFormState = {
    currentPage: 1,
    lastCompletePage: 0,
}

export const selectFormCurrentPage = (state: RootState) => state.multiPageForm.currentPage;
export const selectFormLastCompletePage = (state: RootState) => state.multiPageForm.lastCompletePage;

export const createMultiPageFormSlice = createSlice({
    name: 'multiPageForm',
    initialState,
    reducers: {
        setFormCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        setFormLastCompletePage: (state, action: PayloadAction<number>) => {
            state.lastCompletePage = action.payload;
        },
    }
})

export const {setFormCurrentPage, setFormLastCompletePage} = createMultiPageFormSlice.actions;

export default createMultiPageFormSlice.reducer
