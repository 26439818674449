import React, {ReactNode, useEffect} from 'react';
import PageContainer from "../../components/layout/pageLayouts/PageContainer";
import MatchOverviewCard from "../../components/cards/MatchOverviewCard";
import {useDispatch, useSelector} from "react-redux";
import {fetchMatches, selectMatches} from "../../state/matches/matchesSlice";
import {setCurrentApplicationMenuItem} from "../../state/menu/applicationMenuSlice";
import {MatchOverviewObjectTo} from "../../api/tos/matches/MatchOverviewObjectTo";
import TogglePages from "../../components/layout/pageContent/TogglePages";
import CollapseGroupCard from "../../components/cards/CollapseGroupCard";
import {fetchJobs, selectJobs} from "../../state/jobs/jobsSlice";
import {JobOverviewObjectTo} from "../../api/tos/jobs/JobOverviewObjectTo";
import {JobStatus} from "../jobs/types";


function MatchesOverview() {

    const matches: MatchOverviewObjectTo[] = useSelector(selectMatches);
    const jobs: JobOverviewObjectTo[] = useSelector(selectJobs);
    const dispatch = useDispatch()

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchMatches())
        //@ts-ignore
        dispatch(fetchJobs())
        dispatch(setCurrentApplicationMenuItem("Meine Matches"))
    }, [dispatch])

    const newMatchesTitle = `Matches`;
    const openMatchesTitle = `Angefragte Talente`;
    const acceptedMatchesTitle = `Perfect Matches`;

    const newMatches = matches.filter((match) => !match.interested);
    const openMatches = matches.filter((match) => match.interested && !match.accepted);
    const acceptedMatches = matches.filter((match) => match.interested && match.accepted);

    const publishedJobs = jobs.filter((job) => job.status.businessKey === JobStatus.PUBLISHED.value)
        .sort((a, b) => a.expirationDate.localeCompare(b.expirationDate));

    const groupByJobTitle = (items: MatchOverviewObjectTo[]): Map<string, MatchOverviewObjectTo[]> => {
        const emptyGroups: Map<string, MatchOverviewObjectTo[]> = publishedJobs.reduce((group: Map<string, MatchOverviewObjectTo[]>, product: JobOverviewObjectTo) => {
            const {title} = product;
            group.set(title, []);
            return group;
        }, new Map<string, MatchOverviewObjectTo[]>([]));

        return items.reduce((group: Map<string, MatchOverviewObjectTo[]>, product: MatchOverviewObjectTo) => {
            const {jobTitle} = product;

            if (!group.has(jobTitle)) {
                group.set(jobTitle, [product]);
            } else {
                group.get(jobTitle)?.push(product);
            }

            return group;
        }, new Map<string, MatchOverviewObjectTo[]>(emptyGroups));
    };

    const produceGroupedMatches = (matches: MatchOverviewObjectTo[], groupId: string): ReactNode[] => {
        const result: ReactNode[] = [];
        const groupedByJobTitle = groupByJobTitle(matches);

        groupedByJobTitle
            .forEach((value, jobTitle ) => {
                const groupItems = value.sort((a, b) => b.matchRate - a.matchRate)
                    .map((match) => (
                        <MatchOverviewCard {...match} key={match.id}/>
                    ));
                result.push(
                    <CollapseGroupCard key={groupId + jobTitle} title={`${jobTitle} (${groupItems.length})`} items={groupItems}/>
                )
            })
        return result
    }

    return (
        <PageContainer title="Matches">
            <TogglePages
                items={[
                    {
                        title: `${newMatchesTitle} (${newMatches.length})`,
                        description: "Hier findest du neue Talente für deine offenen Stellen.\nBitte beachte, dass deine Matches nur innerhalb der jeweiligen Joblaufzeit sichtbar sind.",
                        content: produceGroupedMatches(newMatches, "matches")
                    },
                    {
                        title: `${openMatchesTitle} (${openMatches.length})`,
                        description: "Hier findest du die Talente, die du bereits angefragt hast.\nBitte beachte, dass deine angefragten Talente nur innerhalb der jeweiligen Joblaufzeit sichtbar sind.",
                        content: produceGroupedMatches(openMatches, "openMatches")
                    },
                    {
                        title: `${acceptedMatchesTitle} (${acceptedMatches.length})`,
                        description: "Yeah! Nimm jetzt Kontakt zu deinen Perfect Matches auf.\nBitte beachte, dass deine Perfect Matches nur innerhalb der jeweiligen Joblaufzeit sichtbar sind.",
                        content: produceGroupedMatches(acceptedMatches, "perfectMatches")
                    },
                ]}
            />
        </PageContainer>
    );
}

export default MatchesOverview;