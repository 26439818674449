import {ReactNode, useState} from "react";
import {Box, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {BoxProps} from "@mui/material/Box";

interface IProps {
    title: string;
    items: ReactNode[];
}

interface RotatingBoxProps extends BoxProps{
    deg: number;
}
const RotatingBox = styled(Box)<RotatingBoxProps>(({ deg }) => ({
    minWidth: 0,
    minHeight: 0,
    display: 'flex',
    transition: 'transform 0.3s ease',
    transform: `rotate(${deg}deg)`,
}));

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    gap: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
});

interface TitleBoxProps extends BoxProps {
    disabled?: boolean;
}

const TitleBox = styled(Box)<TitleBoxProps>(({disabled}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: disabled ? '#ededee' : '#bbb',
}));

function CollapseGroupCard(props: IProps) {
    const { title, items } = props;

    const [open, setOpen] = useState(false);

    const disabled = !items || items.length === 0;

    const handleClick = () => {
        if (disabled) return;

        setOpen(!open);
    }

    return (
        <Container>
            <TitleBox onClick={handleClick} disabled={disabled}>
                <Typography variant="subtitle1">{title}</Typography>
                <RotatingBox deg={open ? -90 : 0}>
                    <ArrowBackIcon/>
                </RotatingBox>
            </TitleBox>
            {open && items}
        </Container>
    )
}

export default CollapseGroupCard;