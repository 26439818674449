import React from 'react';
import {Slider, SliderProps, SliderThumb, styled, Typography} from "@mui/material";
import {MatchSegmentation} from "../../api/tos/matches/MatchDetailObjectTo";


interface StyledSliderProps extends SliderProps {
    thumb1type?: MatchSegmentation,
    thumb2type?: MatchSegmentation,
    labels?: string[],
    hideTrackLabels?: boolean,
}

//  |---white---|light|----secondary----|light|---white---|
//  |  1  |  2  |  3  |  4  |  5  |  6  |  7  |  8  |  9  |
//  0   11.11 22.22 33.33 44.44 55.56 66.67 77.78 88.89  100

const ColoredSlider = styled(Slider)<StyledSliderProps>(({thumb1type, thumb2type, theme}) => {
    const getThumbStyle = (thumbType: MatchSegmentation | undefined) => {
        switch (thumbType) {
            case MatchSegmentation.MATCH:
                return {
                    height: 25,
                    width: 50,
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.primary.light,
                    border: `2px solid ${theme.palette.primary.main}`
                };
            case MatchSegmentation.ADDITIONAL:
                return {
                    height: 25,
                    width: 50,
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.light,
                    border: `2px solid ${theme.palette.secondary.main}`
                };
            case MatchSegmentation.MISSING:
                return {
                    height: 25,
                    width: 50,
                    color: theme.palette.text.primary,
                    backgroundColor: 'white',
                    border: `2px dashed ${theme.palette.text.disabled}`

                };
            case MatchSegmentation.NONE:
                return {
                    height: 25,
                    width: 35,
                    color: theme.palette.text.primary,
                    backgroundColor: 'white',
                    border: `1px solid ${theme.palette.text.disabled}`,
                    opacity: 0.7,
                };
            default:
                return {
                    height: 30,
                    width: 15,
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.text.primary}`,
                };
        }
    }

    return {
        color: 'transparent', // Make the default color transparent
        left: '11px',
        '& .MuiSlider-thumb': {
            borderRadius: 5,
            '&.first-thumb': getThumbStyle(thumb1type),
            '&.second-thumb': getThumbStyle(thumb2type),
        },
        '& .MuiSlider-markLabel': {
            color: theme.palette.text.primary,
        },
        '& .MuiSlider-mark': {
            color: theme.palette.text.disabled,
            width: 2,
            height: 10,
        },
        '& .MuiSlider-rail': {
            height: 10,
            width: 'calc(100% + 20px)',
            left: '-11px',
            backgroundImage: `linear-gradient(to right, 
            ${theme.palette.background.paper} 0%, ${theme.palette.background.paper} 22.22%, 
            ${theme.palette.background.paper} 22.22%, ${theme.palette.secondary.main} 44.44%, 
            ${theme.palette.secondary.main} 44.44%, ${theme.palette.secondary.main} 55.56%, 
            ${theme.palette.secondary.main} 55.56%, ${theme.palette.background.paper} 77.78%, 
            ${theme.palette.background.paper} 77.78%, ${theme.palette.background.paper} 100%)`,
            border: `1px solid ${theme.palette.text.disabled}`,
            borderRadius: 4,
            opacity: 1,
        },
        '&:hover': {
            cursor: 'default'
        }
    }
});

function ThumbComponent(disabled: boolean | undefined, labels?: string[]) {

    return (props: any) => {
        const {className, ...other} = props;

        const index = other["data-index"];
        const extraClassName = index === 0 ? "first-thumb" : "second-thumb";

        const label = labels && labels.length > index ? labels[index] : undefined;

        if(!disabled || !label) {
            return (
                <SliderThumb {...other} className={`${className} ${extraClassName}`}/>
            );
        }

        return (
            <SliderThumb {...other} className={`${className} ${extraClassName}`}>
                <Typography>{label}</Typography>
            </SliderThumb>
        );
    }
}


function StyledSlider(props: StyledSliderProps) {
    let marks = [];
    for(let i = 1; i <= 9; i++) {
        marks.push({
            value: i,
            label: props.hideTrackLabels ? undefined : i.toString(),
        })
    }

    return (
        <ColoredSlider
            {...props}
            components={{Thumb: ThumbComponent(props.disabled, props.labels)}}
            marks={marks}
            track={false}
            min={1} max={9}
        />
    );
}

export default StyledSlider;