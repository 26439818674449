import {Button, styled} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const StyledButton = styled(Button)({
    maxWidth: "26px",
    minWidth: "26px",
    maxHeight: "22px",
    minHeight: "22px",
    padding: "4px",
    borderRadius: "4px"
});

interface CustomEditButtonProps extends ButtonProps {
    mode: "edit" | "accept" | "decline",
}


function CustomEditButton({mode, ...props}: CustomEditButtonProps) {
    return <StyledButton {...props} variant="contained" color="primary">
        {mode === "edit" &&
            <ModeEditOutlineOutlinedIcon fontSize="small"/>
        }
        {mode === "accept" &&
            <CheckIcon fontSize="small"/>
        }
        {mode === "decline" &&
            <CloseIcon fontSize="small"/>
        }
    </StyledButton>
}

export default CustomEditButton;