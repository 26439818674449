import {useForm} from "react-hook-form";
import * as React from "react";
import {useEffect, useState} from "react";
import {CreateCompanyDetailObject} from "../types";
import {FormTextField} from "../../../components/forms/FormTextField";
import {Box, Grid, IconButton, Typography} from "@mui/material";
import ImageUpload, {FileData} from "../../../components/controls/ImageUpload";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";
import CloseIcon from "@mui/icons-material/Close";

export type CreateProfileOverviewFormData = {
    companyName: string,
    street: string,
    zipCode: string,
    city: string,
    logo: string,

}

function Overview({currentObject, onNext}: MultiPageComponentProps<CreateCompanyDetailObject>) {
    const [imgData, setImgData] = useState<FileData[]>([]);

    const formContext = useForm<CreateProfileOverviewFormData>({
        defaultValues: {
            companyName: "",
            street: "",
            zipCode: "",
            city: "",
            logo: "",
        }
    })
    const {formState: {errors}, control, handleSubmit, clearErrors} = formContext;

    const saveData = (data: CreateProfileOverviewFormData) => {
        const newProfileObject: CreateCompanyDetailObject = {
            ...currentObject,
            companyName: data.companyName,
            street: data.street,
            zipCode: data.zipCode,
            city: data.city,
            logo: data.logo,
        }
        return newProfileObject
    }
    const onSubmit = (data: CreateProfileOverviewFormData) => {
        onNext && onNext(saveData(data), true);
    };
    const handleValidationFailed = () => {
        const data: CreateProfileOverviewFormData = formContext.getValues();
        onNext && onNext(saveData(data), false);
    };

    useEffect(() => {
        if (currentObject) {
            formContext.setValue("companyName", currentObject.companyName || "");
            formContext.setValue("street", currentObject.street || "");
            formContext.setValue("zipCode", currentObject.zipCode || "");
            formContext.setValue("city", currentObject.city || "");
            formContext.setValue("logo", currentObject.logo || "");
        }
    }, [])

    useEffect(() => {
            formContext && formContext.setValue("logo", imgData.length > 0 ? imgData[0].base64Data : "");
    }, [imgData])

    formContext.watch(() => {
        clearErrors();
    })

    const logoData = formContext.getValues("logo");
    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit, handleValidationFailed)}>
            <FormTextField
                control={control}
                label="Firmenname"
                rules={{required: true}}
                error={errors.companyName}
                errorMessage="Bitte trage einen Firmennamen ein"
                name="companyName"
            />
            <FormTextField
                control={control}
                label="Straße, Hausnummer"
                rules={{required: true}}
                error={errors.street}
                errorMessage="Bitte füge Straße und Hausnummer hinzu"
                name="street"
            />
            <Grid container columns={3} spacing="10px">
                <Grid item xs={1}>
                    <FormTextField
                        control={control}
                        name="zipCode"
                        label="PLZ"
                        error={errors.zipCode}
                        errorMessage="Bitte PLZ eintragen"
                        rules={{required: true}}
                        type="number"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormTextField
                        control={control}
                        label="Stadt"
                        rules={{required: true}}
                        error={errors.city}
                        errorMessage="Bitte trage die Stadt ein"
                        name="city"
                    />
                </Grid>
            </Grid>
            {logoData
                ? <>
                    <Box display="flex" flexDirection="row" textAlign="center" pt="20px">
                        <Typography variant="subtitle1" display="flex" alignItems="center">Firmenlogo</Typography>
                        <IconButton onClick={() => {setImgData([])}}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <img src={logoData} alt="logo" style={{width: '100%', maxWidth: '400px'}}/>
                </>
                : <>
                    <ImageUpload
                        label="Firmenlogo"
                        setSelected={setImgData}
                    />
                    <Box style={{opacity: 0, height: 0, zIndex: -1}}>
                        <FormTextField
                            control={control}
                            label=""
                            name="logo"
                            disabled={true}
                            rules={{required: true}}
                        />
                    </Box>
                    {errors.logo &&
                        <Typography color="error.main">Bitte Firmenlogo hochladen</Typography>
                    }
                </>
            }
        </form>
    )
}

export default Overview;