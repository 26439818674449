import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getJobScopesOfActivity} from "../../api/baseDataApi";

export interface JobScopesOfActivityState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: JobScopesOfActivityState = {
    value: [],
    status: 'ok'
}

export const fetchJobScopesOfActivity = createAsyncThunk(
    'baseData/fetchScopesOfActivity',
    async () => {
        return await getJobScopesOfActivity().then(value => value.data)
    }
);

export const selectJobScopesOfActivity = (state: RootState) => state.jobScopesOfActivity.value

export const jobScopesOfActivitySlice = createSlice({
    name: 'scopesOfActivity',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchJobScopesOfActivity.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchJobScopesOfActivity.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchJobScopesOfActivity.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default jobScopesOfActivitySlice.reducer
