import {Box, Button, styled} from "@mui/material";
import {BoxProps} from "@mui/material/Box";
import Typography, {TypographyProps} from "@mui/material/Typography";
import {JobDetailObjectTo} from "../../api/tos/jobs/JobDetailObjectTo";
import {Link} from "react-router-dom";
import {URI_JOBS, URI_JOBS_DETAILS, URI_JOBS_NEW, URI_MATCHES} from "../../config/Router";
import React from "react";

const DialogBox = styled(Box)<BoxProps>(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "30px",
    borderRadius: '5px',
    gap: '10px',
    backgroundColor: '#444444',
}))
const StyledTypography = styled(Typography)<TypographyProps>(({theme}) => ({
    color: theme.palette.background.paper,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
}))

interface ReleasePopupProps {
    job: JobDetailObjectTo,
}
export function PaymentSuccessPopup({job}: ReleasePopupProps) {
    const imgPath = "/backgrounds/Farbverlauf.jpg"

    return (
        <Box position="fixed"
             zIndex={2}>
            <Box
                position="fixed"
                top={0}
                left={0}
                width="100%"
                height="100%"
            >
                <img alt="bgImg" src={imgPath} height="100%" width="100%" style={{
                    height: '100%',
                    width: '100%',
                    opacity: 0.5
                }}></img>
            </Box>
            <Box
                position="fixed"
                top={0}
                left={0}
                zIndex={3}
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <DialogBox>
                    <StyledTypography variant="h4" paddingTop="20px">{`Dein Job "${job.title}" wurde erfolgreich veröffentlicht!`}</StyledTypography>
                    <StyledTypography variant="subtitle1" paddingTop="10px">Wir suchen nach passenden Matches für deine offene Stelle.</StyledTypography>
                    <StyledTypography variant="subtitle1" paddingTop="10px">Dies kann bis zu zehn Minuten dauern.</StyledTypography>
                    <StyledTypography variant="subtitle1" paddingBottom="10px">Sobald wir welche gefunden haben, werden sie dir unter "Matches" angezeigt.</StyledTypography>
                    <Link to={URI_MATCHES}>
                        <Button sx={{backgroundColor: 'primary.main', '&:hover': {backgroundColor: 'primary.dark'}, width: '15rem'}}>
                            <Typography variant="subtitle1" color="background.paper">Matches ansehen</Typography>
                        </Button>
                    </Link>
                    <Link to={URI_JOBS_NEW}>
                        <Button sx={{backgroundColor: 'primary.main', '&:hover': {backgroundColor: 'primary.dark'}, width: '15rem'}}>
                            <Typography variant="subtitle1" color="background.paper">Weiteren Job anlegen</Typography>
                        </Button>
                    </Link>
                </DialogBox>
            </Box>
        </Box>
    );
}

export function PaymentCancelPopup({job}: ReleasePopupProps) {
    const imgPath = "/backgrounds/Farbverlauf.jpg"

    return (
        <Box position="fixed"
             zIndex={2}>
            <Box
                position="fixed"
                top={0}
                left={0}
                width="100%"
                height="100%"
            >
                <img alt="bgImg" src={imgPath} height="100%" width="100%" style={{
                    height: '100%',
                    width: '100%',
                    opacity: 0.5
                }}></img>
            </Box>
            <Box
                position="fixed"
                top={0}
                left={0}
                zIndex={3}
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <DialogBox>
                    <StyledTypography variant="h4" paddingTop="20px">{`Dein Job "${job.title}" wurde nicht veröffentlicht!`}</StyledTypography>
                    <StyledTypography variant="subtitle1" paddingTop="10px">Der Zahlungsprozess konnte nicht erfolgreich abgeschlossen werden.</StyledTypography>
                    <StyledTypography variant="subtitle1" paddingBottom="10px">
                        Solltest du deinen Job zu einem späteren Zeitpunkt veröffentlichen wollen, findest du diesen unter
                        dem Reiter "Meine Jobs" in der Kategorie "Neue Jobs (nicht veröffentlicht)"
                    </StyledTypography>
                    <Link to={URI_JOBS_DETAILS.replace(":jobId", `${job.id}`)} >
                        <Button sx={{backgroundColor: 'primary.main', '&:hover': {backgroundColor: 'primary.dark'}, width: '15rem'}}>
                            <Typography variant="subtitle1" color="background.paper">Zum aktuellen Job</Typography>
                        </Button>
                    </Link>
                    <Link to={URI_JOBS}>
                        <Button sx={{backgroundColor: 'primary.main', '&:hover': {backgroundColor: 'primary.dark'}, width: '15rem'}}>
                            <Typography variant="subtitle1" color="background.paper">Zu allen Jobs</Typography>
                        </Button>
                    </Link>
                </DialogBox>
            </Box>
        </Box>
    );
}