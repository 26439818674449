import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {getSkillsByFieldOfActivity} from "../../api/baseDataApi";
import {SkillDataObjectTo} from "../../api/tos/baseData/SkillDataObjectTo";

export interface SkillsState {
    value: SkillDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: SkillsState = {
    value: [],
    status: 'ok'
}

export const fetchSkillsByFieldOfActivityBusinessKey = createAsyncThunk(
    'baseData/fetchSkills',
    async (businessKey: string) => {
        return await getSkillsByFieldOfActivity(businessKey).then(value => value.data)
    }
);

export const selectSkills = (state: RootState) => state.skills.value

export const skillsSlice = createSlice({
    name: 'skills',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchSkillsByFieldOfActivityBusinessKey.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchSkillsByFieldOfActivityBusinessKey.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchSkillsByFieldOfActivityBusinessKey.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default skillsSlice.reducer
