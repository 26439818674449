import React, {useEffect} from 'react';
import DetailPageContainer from "../../components/layout/pageLayouts/DetailPageContainer";
import BreadcrumbNavigation from "../../components/navigation/BreadcrumbNavigation";
import {LinkData} from "../../components/commonTypes";
import {URI_MATCHES} from "../../config/Router";
import Typography from "@mui/material/Typography";
import {Badge, Box, Grid, Stack, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";
import Avatar, {AvatarProps} from "@mui/material/Avatar";
import BaseCard from "../../components/cards/BaseCard";
import {StyledChip} from "../../components/chips/StyledChip";
import StyledSlider from "../../components/slider/StyledSlider";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentApplicationMenuItem} from "../../state/menu/applicationMenuSlice";
import {fetchMatch, selectMatch, selectMatchStatus} from "../../state/matches/matchSlice";
import {useParams} from "react-router-dom";
import {
    MatchDataTo,
    MatchDetailObjectTo,
    MatchLanguageRequirementsTo,
    MatchSegmentation
} from "../../api/tos/matches/MatchDetailObjectTo";
import {SoftSkillDetailsTo} from "../../api/tos/common/SoftSkillDetailsTo";
import ReactGA from "react-ga4";
import {BoxProps} from "@mui/material/Box";

const StyledBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    paddingBottom: '10px',
    paddingLeft: '10px',
}))
const SmallAvatar = styled(Avatar)<AvatarProps>(({theme}) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    width: 54,
    height: 54,
    left: '-5px',
    top: '5px'

}))
interface ImageAvatarProps {
    imageURL: string | undefined,
    matchRate: number,
}
function ImageAvatar(props: ImageAvatarProps) {
    const image = "/matches/matchPlaceholder.png" // TODO show actual img
    const theme = useTheme();

    return (
        <StyledBox margin="5px">
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                style={{left: '5px'}}
                badgeContent={
                    <SmallAvatar>
                        <Typography variant="body1" color="primary.main">{`${Math.round(props.matchRate)}%`}</Typography>
                    </SmallAvatar>
                }
            >
                <StyledAvatar alt="match image" src={image} style={{border: `1px solid ${theme.palette.secondary.main}`}}/>
            </Badge>
        </StyledBox>
    )
}

//TODO Komponente bauen
const StyledAvatar = styled(Avatar)<AvatarProps>(({theme}) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    width: 110,
    height: 110
}))


function MatchDetailsHeader(props: MatchDetailObjectTo) {
    return (
        <Box mt='20px'>
            <Stack spacing={2} direction="row" alignItems="center">
                {(props.accepted && props.interested)
                    ? <ImageAvatar matchRate={props.matchRate} imageURL={props.imgURL}/>
                    : <StyledAvatar>
                        <div style={{textAlign: "center"}}>
                            <Typography variant="h1" color="primary.main">
                                {Math.round(props.matchRate)}%
                            </Typography>
                            <Typography variant="subtitle2" component="p" color="text.secondary">
                                Match factor
                            </Typography>
                        </div>
                    </StyledAvatar>
                }
                <Stack>
                    <Typography variant="h1">{props.accepted && props.interested && !!props.name ? props.name : props.jobTitle}</Typography>
                    <Stack mt="15px" gap={2} direction="row" flexWrap="wrap" alignItems="center">
                        <StyledChip label={props.workload.description} match={props.workload.segmentation}/>
                        <StyledChip label={`${props.hoursPerWeek} Std./Woche`} match={MatchSegmentation.MATCH}/>
                        {props.workplaces.map((item, index) => (
                            <StyledChip key={index} label={item.description} match={item.segmentation}/>
                        ))}
                        <Typography fontWeight="bold" variant="body1">{props.jobLocationPostCode}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Box>);
}

function FaehigkeitenBox(props: { skills: MatchDataTo[] }) {
    return (
        <Box mt='20px'>
            <Typography variant="h2">Fertigkeiten</Typography>
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                {props.skills.map((item, index) => {
                    return <StyledChip key={index} label={item.description} match={item.segmentation}/>
                })}
            </Stack>
        </Box>);
}

function ProgrammskillsBox(props: { tools: MatchDataTo[] }) {
    return (
        <Box mt='20px'>
            <Typography variant="h2">Programmskills</Typography>
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                {props.tools.map((value, index) => {
                    return <StyledChip key={index} label={value.description} match={value.segmentation}/>
                })}
            </Stack>
        </Box>);
}

function SprachenBox(props: { languages: MatchLanguageRequirementsTo[] }) {
    return (
        <Box mt='20px'>
            <Typography variant="h2">Sprachen</Typography>
            <Stack mt="15px" gap={2} direction="row" flexWrap="wrap">
                {props.languages.map((value, index) => {
                    return <BaseCard key={index} id={index} title={value.language.description}
                                     description={`${value.languageLevel.description} - ${value.languageRequirement.description}`}
                                     match={value.segmentation}/>
                })}
            </Stack>
        </Box>);
}

function SoftSkillSlider(props: { jobValue: number, candidateValue: number, reverse?: boolean }) {
    let jobValue = props.jobValue;
    let candidateValue = props.candidateValue;
    let reverse = props.reverse;

    let thumb1Type;
    let thumb2Type;
    let labels;

    if (jobValue === candidateValue) {
        thumb1Type = MatchSegmentation.MATCH;
        thumb2Type = MatchSegmentation.MATCH;
        labels = [jobValue.toString(), candidateValue.toString()];
    } else if (jobValue < candidateValue) {
        if (!reverse) { // Kandidat ist rechts von Job -> Additional
            thumb1Type = MatchSegmentation.NONE;
            thumb2Type = MatchSegmentation.ADDITIONAL;
        } else {  // Kandidat ist rechts von Job, aber links ist besser -> Missing
            thumb1Type = MatchSegmentation.NONE;
            thumb2Type = MatchSegmentation.MISSING;
        }
        labels = [jobValue.toString(), candidateValue.toString()];
    } else {
        if (!reverse) { // Kandidat ist links von Job -> Missing
            thumb1Type = MatchSegmentation.MISSING;
            thumb2Type = MatchSegmentation.NONE;
        } else {  // Kandidat ist links von Job und links ist besser -> Additional
            thumb1Type = MatchSegmentation.ADDITIONAL;
            thumb2Type = MatchSegmentation.NONE;
        }
        labels = [candidateValue.toString(), jobValue.toString()];
    }

    return (
        <StyledSlider disabled={true} value={[jobValue, candidateValue]} thumb1type={thumb1Type} thumb2type={thumb2Type}
                      labels={labels} />
    )
}

function SoftSkillBox(props: { jobSkills: SoftSkillDetailsTo, candidateSkills: SoftSkillDetailsTo }) {
    let jobSkills = props.jobSkills;
    let candidateSkills = props.candidateSkills;
    return (
        <Box mt='20px'>
            <Typography variant="h2">Soft skills</Typography>
            <Grid container spacing={6} rowSpacing={3}>
                <Grid item xs={6}>
                    <Typography variant="h3">Neurotizismus</Typography>
                    <SoftSkillSlider jobValue={jobSkills.neuroticism} candidateValue={candidateSkills.neuroticism}
                                     reverse={true}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Extraversion</Typography>
                    <SoftSkillSlider jobValue={jobSkills.extraversion} candidateValue={candidateSkills.extraversion}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Gewissenhaftigkeit</Typography>
                    <SoftSkillSlider jobValue={jobSkills.conscientiousness}
                                     candidateValue={candidateSkills.conscientiousness}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Offenheit</Typography>
                    <SoftSkillSlider jobValue={jobSkills.openness} candidateValue={candidateSkills.openness}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Verträglichkeit</Typography>
                    <SoftSkillSlider jobValue={jobSkills.compatibility} candidateValue={candidateSkills.compatibility}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Machtmotiv</Typography>
                    <SoftSkillSlider jobValue={jobSkills.powerMotive} candidateValue={candidateSkills.powerMotive}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Sicherheitsmotiv</Typography>
                    <SoftSkillSlider jobValue={jobSkills.safetyMotive} candidateValue={candidateSkills.safetyMotive}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3">Leistungsmotiv</Typography>
                    <SoftSkillSlider jobValue={jobSkills.performanceMotive}
                                     candidateValue={candidateSkills.performanceMotive}/>
                </Grid>
            </Grid>
        </Box>);
}

function CandidateInfoBox(props: { aboutMe: string, aboutMeExtended: string, vision: string }) {
    return (
        <Box mt='20px'>
            <Box mb='20px'>
                <Typography variant="h2">Über mich</Typography>
                <Typography variant="body1">{props.aboutMe}</Typography>
            </Box>
            <Box mb='20px'>
                <Typography variant="h2">Vision</Typography>
                <Typography variant="body1">{props.vision}</Typography>
            </Box>
            <Box mb='20px'>
                <Typography variant="h2">Mit welcher Person, lebendig oder tot, würdest du gerne zu Abend essen und warum?</Typography>
                <Typography variant="body1">{props.aboutMeExtended}</Typography>
            </Box>
        </Box>);
}

function MatchDetails() {
    const dispatch = useDispatch();
    const match = useSelector(selectMatch);
    const status = useSelector(selectMatchStatus);
    const {matchId} = useParams<{ matchId: string }>();
    const matchIdNumber = matchId !== undefined ? parseInt(matchId) : 0

    useEffect(() => {
        dispatch(setCurrentApplicationMenuItem("Meine Matches"))
        // @ts-ignore
        dispatch(fetchMatch(matchId))
        ReactGA.event({
            category: 'match',
            action: 'KANDIDAT_DETAILS_VIEW',
            value: matchIdNumber,
        })
    }, [dispatch, matchId])

    const breadcrumbs: LinkData[] = [
        {title: "Matches", path: URI_MATCHES}
    ];

    if (!match || status === 'loading') {
        return <></>
    }

    return (
        <DetailPageContainer>
            <BreadcrumbNavigation links={breadcrumbs} lastItem={match.jobTitle}/>
            <MatchDetailsHeader {...match}/>
            <FaehigkeitenBox skills={match.skills}/>
            <ProgrammskillsBox tools={match.tools}/>
            <SprachenBox languages={match.languageRequirements}/>
            <SoftSkillBox jobSkills={match.jobSoftSkills} candidateSkills={match.candidateSoftSkills}/>
            <CandidateInfoBox {...match}/>
        </DetailPageContainer>)
}

export default MatchDetails;