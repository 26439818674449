import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {deleteJob, getJob} from "../../api/jobsApi";
import {RootState} from "../store";
import {JobDetailObjectTo} from "../../api/tos/jobs/JobDetailObjectTo";
import {URI_JOBS} from "../../config/Router";
import {NavigateFunction} from 'react-router-dom';

export interface JobsState {
    value: JobDetailObjectTo | undefined;
    status: 'ok' | 'loading' | 'failed';
    inEditMode: boolean;
}
const initialState: JobsState = {
    value: undefined,
    status: 'ok',
    inEditMode: false,
}

export const fetchJob = createAsyncThunk(
    'jobs/fetchJob',
    async (id: number) => {
        return await getJob(id).then(value => value.data)
    }
);

interface DeleteJobThunkProps {
    id: number,
    navigate: NavigateFunction,
}
export const deleteCurrentJob = createAsyncThunk(
    'jobs/deleteJob',
    async ({id, navigate}: DeleteJobThunkProps) => {
        return await deleteJob(id).then(() => {navigate(URI_JOBS)})
    }
);

export const selectJob = (state: RootState) => state.job.value
export const selectJobStatus = (state: RootState) => state.job.status
export const selectJobInEditMode = (state: RootState) => state.job.inEditMode

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        enableJobEditing: (state) => {
            state.inEditMode = true;
        },
        disableJobEditing: (state) => {
            state.inEditMode = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchJob.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchJob.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchJob.rejected, (state) => {
            state.status = 'failed'
        })
        builder.addCase(deleteCurrentJob.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(deleteCurrentJob.fulfilled, (state) => {
            state.status = 'ok'
        })
        builder.addCase(deleteCurrentJob.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export const { enableJobEditing, disableJobEditing } = jobSlice.actions;

export default jobSlice.reducer
