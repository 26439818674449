import React from 'react';
import Avatar, {AvatarProps} from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import FallbackImage from "../images/FallbackImage";

interface BenefitsAvatarProps extends AvatarProps{
    tooltipTitle: string
}
function BenefitsAvatar(props: BenefitsAvatarProps) {

    const benefitIconsDir = "/benefitsIcons/"

    return (
        <Tooltip title={props.tooltipTitle} placement="bottom">
            <Avatar sx={{ bgcolor: 'white' }} variant="rounded">
                <FallbackImage src={`${benefitIconsDir}${props.src}`} alt={props.tooltipTitle} style={{height: '70%', width: '70%'}}/>
            </Avatar>
        </Tooltip>
    );
}

export default BenefitsAvatar;