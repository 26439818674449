import * as React from "react";
import {useEffect, useState} from "react";
import {MatchOverviewObjectTo} from "../../api/tos/matches/MatchOverviewObjectTo";
import {useDispatch} from "react-redux";
import {contactCandidate, decline, showInterest} from "../../state/matches/matchSlice";
import {StyledButton} from "../buttons/StyledButton";
import {Stack, useTheme} from "@mui/material";
import ReactGA from "react-ga4";
import {useNavigate} from "react-router-dom";
import {MultilineTypography} from "../typographys/MultilineTypography";
import {URI_CHAT} from "../../config/Router";
import {useFirestoreContext} from "../../contexts/firestoreContext";

interface MatchActionDialogProps {
    match: MatchOverviewObjectTo,
}

function MatchActionDialog({match}: MatchActionDialogProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {reload} = useFirestoreContext();
    const [interested, setInterested] = useState<boolean>(match.interested);
    const [accepted, setAccepted] = useState<boolean>(match.accepted);
    const chatId = match.chatId;

    const theme = useTheme();

    useEffect(() => {
        setInterested(match.interested);
        setAccepted(match.accepted);
    }, [match]);

    const handleContactCandidateSuccess = (newChatId: string) => {
        reload();
        navigate(URI_CHAT + "?chatId=" + newChatId);
    }

    const handleClickContact = () => {
        if(chatId && chatId !== "") {
            navigate(URI_CHAT + "?chatId=" + chatId);
        }
        // @ts-ignore
        dispatch(contactCandidate({id: match.id, onSuccess: handleContactCandidateSuccess}));
    }

    const handleClickInterested = () => {
        setInterested(true)

        ReactGA.event({
            category: 'match',
            action: 'KANDIDAT_ACCEPT',
        })
        // @ts-ignore
        dispatch(showInterest(match.id));

    }

    const handleClickDecline = () => {
        ReactGA.event({
            category: 'match',
            action: 'KANDIDAT_DECLINE',
        })
        // @ts-ignore
        dispatch(decline({id: match.id, navigate: navigate}));
    };

    const showContactButton = () => {
        if (interested && accepted) {
            return (
                <StyledButton variant="contained" onClick={handleClickContact}>
                    {chatId && chatId !== "" ? "Zum Chat" : "Chat starten"}
                </StyledButton>
            )
        }
        return <></>;
    }
    const showWaitingInfo = () => {
        if (interested && !accepted) {
            return (
                <MultilineTypography variant="subtitle1">{"Du hast dieses Match bereits angefragt.\n\nDerzeit warten wir auf eine Rückmeldung der Person, ob sie interessiert ist.\n"}</MultilineTypography>
            )
        }
    }
    const showInterestedButtons = () => {
        if (!interested) {
            return (
                <>
                    <StyledButton variant="contained" onClick={handleClickInterested}>Lass uns Kontakt
                        aufnehmen</StyledButton>
                    <StyledButton variant="contained" style={{backgroundColor: theme.palette.error.main}}
                                  onClick={handleClickDecline}>Vielleicht beim nächsten Mal</StyledButton>
                </>
            )
        }
        return <></>;
    }

    return (
        <Stack gap='10px' display="flex" flex={1}>
            {showContactButton()}
            {showWaitingInfo()}
            {showInterestedButtons()}
        </Stack>
    )
}

export default MatchActionDialog;