import Box from "@mui/material/Box";
import {MultilineTypography} from "../typographys/MultilineTypography";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

function ChatEmptyScreen() {
    return (
      <Box display="flex" flexDirection="column" flex={1} width="100%" justifyContent="center" alignItems="center" gap="30px">
          <MultilineTypography variant="subtitle1" align="center">{"Sobald Du ein Perfect Match kontaktierst,\nwird Dir euer Chat hier angezeigt."}</MultilineTypography>
          <QuestionAnswerOutlinedIcon sx={{fontSize: '54px'}}/>
      </Box>
    );
}

export default ChatEmptyScreen;