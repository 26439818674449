import * as React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";

interface ContentPage2ColumnsLayoutProps {
    children: string | React.ReactElement | React.ReactElement[]
    childrenLeft?: string | React.ReactElement | React.ReactElement[]
}

function ContentPage2ColumnsLayout(props: ContentPage2ColumnsLayoutProps) {
    const style = {marginTop: '18px', padding: '10px'};
    return (
        <Grid2 container>
            <Grid2 xs={3}>
                <div style={{...style, marginLeft: '28px'}}>
                    {props.childrenLeft}
                </div>
            </Grid2>
            <Grid2 xs={9}>
                <div style={{...style, marginRight: '56px'}}>
                    {props.children}
                </div>
            </Grid2>
        </Grid2>
    )
}

export default ContentPage2ColumnsLayout;