import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box, {BoxProps} from '@mui/material/Box';
import {Link} from "react-router-dom";
import Typography, {TypographyProps} from "@mui/material/Typography";
import {Grid} from "@mui/material";
import {MatchSegmentation} from "../../api/tos/matches/MatchDetailObjectTo";

interface StyledBoxProps extends BoxProps{
    match?: MatchSegmentation,
}

const StyledBox = styled(Box)<StyledBoxProps>(({theme, match}) => {
    let styles;
    let commonStyles = {
        display: 'flex',
        borderRadius: '5px',
        padding: '10px',
        width: '100%',
    }

    switch (match) {
        case MatchSegmentation.MATCH:
            styles = {
                ...commonStyles,
                background: theme.palette.background.paper,
                border: `2px solid ${theme.palette.primary.main}`,
                color: theme.palette.text.primary
            };
            break;
        case MatchSegmentation.ADDITIONAL:
            styles = {
                ...commonStyles,
                background: theme.palette.background.paper,
                border: `2px solid ${theme.palette.secondary.main}`,
                color: theme.palette.text.primary
            };
            break;
        case MatchSegmentation.MISSING:
            styles = {
                ...commonStyles,
                background: theme.palette.background.default,
                border: `2px dashed ${theme.palette.text.disabled}`,
                color: theme.palette.text.disabled
            };
            break;
        default:
            styles = {
                ...commonStyles,
                background: theme.palette.background.paper,
                border: 'none',
                color: theme.palette.text.primary
            };
            break;
    }

    return styles;
});

const StyledInnerBox = styled(Box)<StyledBoxProps>(({theme}) => ({
    display: 'flex',
    borderRadius: '5px',
    padding: '10px',
    flex: 1,
    color: theme.palette.text.primary
}))

const StyledTypography = styled(Typography)<TypographyProps>(() => ({
    lineHeight: "1.2rem"
}))

interface BaseCardProps {
    id: number | string,
    title?: string,
    description?: string,
    onClickURI?: string,
    leftChild?: React.ReactNode,
    rightChild?: React.ReactNode,
    bottomChild?: React.ReactNode,
    topChild?: React.ReactNode,
    match?: MatchSegmentation,
    bgColor?: string,
}

function BaseCard(props: BaseCardProps) {
    const {id, title, description, onClickURI, leftChild, rightChild, topChild, bottomChild, match, bgColor} = props;

    const isClickable = !!onClickURI;

    // deactivates the link if no onClickURI is given by preventing the routing action
    const checkLinkValid = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!onClickURI) {
            event.preventDefault();
        }
    }

    const bgSx = bgColor ? {background: bgColor} : {};

    return (
        <Grid item key={id} sx={bgSx}>
            <Link to={onClickURI || ""} onClick={checkLinkValid} style={{textDecoration: 'none', cursor: isClickable ? 'pointer' : 'default'}}>
                <StyledBox flexDirection="row" match={match} sx={bgSx}>
                    {leftChild}
                    <StyledInnerBox flexDirection="column">
                        {topChild}
                        {!!title && <StyledTypography variant="h3" color="text.primary">{title}</StyledTypography>}
                        {!!description && <Typography variant="body1" color="text.primary"
                                                      paddingTop="10px">{description}</Typography>}
                        {bottomChild}
                    </StyledInnerBox>
                    {rightChild}
                </StyledBox>
            </Link>
        </Grid>
    );
}

export default BaseCard