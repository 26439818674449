import {axiosInstance, getAccessToken} from "../config/apiConfig";
import {MatchOverviewObjectTo} from "./tos/matches/MatchOverviewObjectTo";
import {AxiosResponse} from "axios";
import {MatchDetailObjectTo} from "./tos/matches/MatchDetailObjectTo";


const MATCHES_BASE_API = "api/matches"

export async function getMatches(): Promise<AxiosResponse<MatchOverviewObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${MATCHES_BASE_API}`)
}

export async function getMatch(id: number): Promise<AxiosResponse<MatchDetailObjectTo>> {
    return axiosInstance(getAccessToken()).get(`${MATCHES_BASE_API}/${id}`)
}

export async function interestMatch(id: number): Promise<AxiosResponse<void>> {
    return axiosInstance(getAccessToken()).post(`${MATCHES_BASE_API}/${id}/interested`)
}

export async function declineMatch(id: number): Promise<AxiosResponse<void>> {
    return axiosInstance(getAccessToken()).post(`${MATCHES_BASE_API}/${id}/decline`)
}

export async function contactMatch(id: number): Promise<AxiosResponse<string>> {
    return axiosInstance(getAccessToken()).post(`${MATCHES_BASE_API}/${id}/contact`)
}

export async function updateCompanyStatus(id: number, newStatus: string): Promise<AxiosResponse<void>> {
    return axiosInstance(getAccessToken()).post(`${MATCHES_BASE_API}/${id}/companyStatus?status=${newStatus !== null ? newStatus : ""}`)
}