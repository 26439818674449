import {MatchDetailObjectTo} from "../../api/tos/matches/MatchDetailObjectTo";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {contactMatch, declineMatch, getMatch, interestMatch, updateCompanyStatus} from "../../api/matchesApi";
import {RootState} from "../store";
import {NavigateFunction} from "react-router-dom";
import {URI_MATCHES} from "../../config/Router";


export interface MatchState {
    value: MatchDetailObjectTo | undefined
    status: 'ok' | 'loading' | 'failed';
}

const initialState: MatchState = {
    value: undefined,
    status: 'ok'
}

export const fetchMatch = createAsyncThunk(
    "matches/fetchMatch",
    async (id: number) => {
        return await getMatch(id).then(value => value.data)
    }
);

interface ChangeMatchThunkProps {
    id: number,
    navigate: NavigateFunction,
}

interface ChangeMatchCompanyStatusThunkProps {
    id: number,
    newStatus: string,
    onSuccess?: () => void,
}
interface ContactMatchTunkProps {
    id: number,
    onSuccess: (chatId: string) => void,
}

export const showInterest = createAsyncThunk(
    "matches/showInterest",
    async (id: number, {dispatch}) => {
        return await interestMatch(id).then(() => {dispatch(fetchMatch(id))})
    }
);

export const contactCandidate = createAsyncThunk(
    "matches/contactCandidate",
    async ({id, onSuccess}: ContactMatchTunkProps) => {
        return await contactMatch(id).then((res) => {
            const chatId = res.data;
            if(chatId) {
                onSuccess(chatId);
            }
        })
    }
);

export const decline = createAsyncThunk(
    'matches/declineMatch',
    async ({id, navigate}: ChangeMatchThunkProps) => {
        return await declineMatch(id).then(() => {navigate(URI_MATCHES)})
    }
);

export const changeCompanyStatus = createAsyncThunk(
    'matches/changeCompanyStatus',
    async ({id, newStatus, onSuccess}: ChangeMatchCompanyStatusThunkProps) => {
        return await updateCompanyStatus(id, newStatus).then(() => {
            onSuccess && onSuccess();
            return {}
        })
    }
);

export const selectMatch = (state: RootState) => state.match.value
export const selectMatchStatus = (state: RootState) => state.match.status

export const matchSlice = createSlice({
    name: 'match',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchMatch.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchMatch.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchMatch.rejected, (state) => {
            state.status = 'failed'
        })

        builder.addCase(showInterest.pending, (state) => {
        })
        builder.addCase(showInterest.fulfilled, (state, action) => {
        })
        builder.addCase(showInterest.rejected, (state) => {
        })

        builder.addCase(contactCandidate.pending, (state) => {
        })
        builder.addCase(contactCandidate.fulfilled, (state, action) => {
        })
        builder.addCase(contactCandidate.rejected, (state) => {
        })

        builder.addCase(decline.pending, (state) => {
        })
        builder.addCase(decline.fulfilled, (state, action) => {
        })
        builder.addCase(decline.rejected, (state) => {
        })

        builder.addCase(changeCompanyStatus.pending, (state) => {
        })
        builder.addCase(changeCompanyStatus.fulfilled, (state, action) => {
        })
        builder.addCase(changeCompanyStatus.rejected, (state) => {
        })
    }
})

export default matchSlice.reducer