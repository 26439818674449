import React, {Dispatch, ReactNode, SetStateAction} from "react";
import {Box, Paper, Popover, styled} from "@mui/material";
import CustomEditButton from "../buttons/CustomEditButton";
import {BoxProps} from "@mui/material/Box";

interface CustomPopoverProps {
    anchorEl: HTMLElement | null,
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>,
    children: ReactNode | ReactNode[],
    onDecline: () => void,
}
export const TitleBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px'
}));


function CustomPopover({anchorEl, setAnchorEl, children, onDecline}: CustomPopoverProps) {
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Paper style={{width: anchorEl ? anchorEl.clientWidth : 'auto'}}>
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    gap="10px"
                    padding="10px"
                    borderRadius="5px"
                    border="2px dashed gray"
                >
                    <Box display="flex" flex={1} justifyContent="right" gap="10px">
                        <CustomEditButton mode="accept" type="submit" form="multipage_form" />
                        <CustomEditButton mode="decline" onClick={() => {
                            handleClose();
                            onDecline();
                        }}/>
                    </Box>
                    {children}
                </Box>
            </Paper>
        </Popover>
    )
}

export default CustomPopover;