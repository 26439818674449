import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getToolsByFieldOfActivity} from "../../api/baseDataApi";

export interface ToolsState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: ToolsState = {
    value: [],
    status: 'ok'
}

export const fetchToolsByFieldOfActivityBusinessKey = createAsyncThunk(
    'baseData/fetchTools',
    async (businessKey: string) => {
        return await getToolsByFieldOfActivity(businessKey).then(value => value.data)
    }
);

export const selectTools = (state: RootState) => state.tools.value

export const toolsSlice = createSlice({
    name: 'tools',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchToolsByFieldOfActivityBusinessKey.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchToolsByFieldOfActivityBusinessKey.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchToolsByFieldOfActivityBusinessKey.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default toolsSlice.reducer
