import React, {useEffect, useMemo, useState} from 'react';
import PageContainer from "../../components/layout/pageLayouts/PageContainer";
import {useDispatch} from "react-redux";
import {setCurrentApplicationMenuItem} from "../../state/menu/applicationMenuSlice";
import ChatOverviewLayout from "../../components/chat/ChatOverviewLayout";
import ChatSearchBox from "../../components/chat/ChatSearchBox";
import {ChatCardContainer} from "../../components/chat/ChatCardContainer";
import ChatOverviewCard from "../../components/chat/ChatOverviewCard";
import {useChatOverview} from "../../hooks/useChatOverviewHook";
import {ChatOverviewObjectTo} from "../../api/tos/chat/ChatOverviewObjectTo";
import ChatDetails from "../../components/chat/ChatDetails";
import {useSearchParams} from "react-router-dom";

function ChatOverview() {
    const [searchParams] = useSearchParams();
    const chatId = searchParams.get("chatId");

    const dispatch = useDispatch();
    const { availableChats, loadingComplete } = useChatOverview();
    const [searchText, setSearchText] = useState("");

    const [currChatObj, setCurrChatObj] = useState<ChatOverviewObjectTo | undefined>(undefined);

    const handleSearchTextChange = (input: string) => {
        setSearchText(String(input).toLowerCase());
    }

    const filteredOverviewObjects = useMemo(() => searchText.length === 0
            ? availableChats
            : availableChats.filter((chatObj) => chatObj.title.toLowerCase().includes(searchText) || chatObj.subtitle.toLowerCase().includes(searchText))
        , [availableChats, searchText]);

    useEffect(() => {
        dispatch(setCurrentApplicationMenuItem("Chat"))
    }, [dispatch])

    useEffect(() => {
        if(!loadingComplete) {
            setCurrChatObj(undefined);
            return;
        }
        setCurrChatObj(availableChats.find((chat) => chat.chatId === chatId));
    }, [chatId, availableChats, loadingComplete])

    return (
        <PageContainer title="">
            <ChatOverviewLayout
                searchBox={<ChatSearchBox onChange={handleSearchTextChange}/>}
                overview={<ChatCardContainer>
                    {filteredOverviewObjects.map((overviewObj) => (
                        <ChatOverviewCard key={overviewObj.chatId} overviewObj={overviewObj} selected={overviewObj.chatId === currChatObj?.chatId}/>
                    ))}
                </ChatCardContainer>}
                details={<ChatDetails chat={currChatObj}/>}
                isEmpty={availableChats.length === 0}
            />
        </PageContainer>
    );
}

export default ChatOverview;