import {Box, TextField, TextFieldProps} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ChatBorders} from "./ChatBorders";

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    padding: '15px',
    borderBottom: ChatBorders,
})

const StyledTextField = styled(TextField)<TextFieldProps>({
    display: 'flex',
    flex: '1',
    '& .MuiInputBase-root': {
        borderRadius: '15px',
        height: '3rem',
        background: '#ededee',
    },
    '& .MuiInputBase-input': {
        display: 'flex',
        justifyContent: 'center',
    },
})

interface IProps {
    onChange: (input: string) => void;
}

function ChatSearchBox(props: IProps) {
    const {onChange} = props;

    const handleChange = (event: any) => {
        onChange(event.target.value ?? "");
    }

    return (
        <Container>
            <StyledTextField
                placeholder="Suche nach Talenten oder Jobs"
                onChange={handleChange}
            />
        </Container>
    )
}

export default ChatSearchBox;