import {axiosInstance, getAccessToken} from "../config/apiConfig";
import {AxiosResponse} from "axios";
import {BaseDataObjectTo} from "./tos/baseData/BaseDataObjectTo";
import {SkillDataObjectTo} from "./tos/baseData/SkillDataObjectTo";

const BASE_API = "api/basedata";

const LANGUAGES_API = BASE_API + "/languages";
const LANGUAGE_REQUIREMENTS_API = BASE_API + "/languages/languageRequirements";
const LANGUAGE_LEVEL_API = BASE_API + "/languages/languageLevels";

const JOB_SCOPES_OF_ACTIVITY_API = BASE_API + "/jobs/scopeOfActivity";
const JOB_PAY_GRADES_API = BASE_API + "/jobs/payGrades";
const JOB_LOCATIONS_API = BASE_API + "/jobs/jobLocationTypes";

const FIELD_OF_ACTIVITIES_API = BASE_API + "/fieldOfActivities";

const COMPANY_BENEFITS_API = BASE_API + "/company/benefits"

const MATCH_COMPANY_STATUS_API = BASE_API + "/matches/companyStatus"

const TOOLS_API = (fieldOfActivityKey: string) => FIELD_OF_ACTIVITIES_API + "/" + fieldOfActivityKey + "/tools";
const SKILLS_API = (fieldOfActivityKey: string) => FIELD_OF_ACTIVITIES_API + "/" + fieldOfActivityKey + "/skills";

export async function getLanguages(): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${LANGUAGES_API}`)
}
export async function getLanguageRequirements(): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${LANGUAGE_REQUIREMENTS_API}`)
}
export async function getLanguageLevels(): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${LANGUAGE_LEVEL_API}`)
}


export async function getJobScopesOfActivity(): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${JOB_SCOPES_OF_ACTIVITY_API}`)
}
export async function getJobPayGrades(): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${JOB_PAY_GRADES_API}`)
}
export async function getJobLocations(): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${JOB_LOCATIONS_API}`)
}


export async function getFieldOfActivities(): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${FIELD_OF_ACTIVITIES_API}`)
}
export async function getToolsByFieldOfActivity(fieldOfActivityBusinessKey: string): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${TOOLS_API(fieldOfActivityBusinessKey)}`)
}
export async function getSkillsByFieldOfActivity(fieldOfActivityBusinessKey: string): Promise<AxiosResponse<SkillDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${SKILLS_API(fieldOfActivityBusinessKey)}`)
}

export async function getCompanyBenefits(): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${COMPANY_BENEFITS_API}`)
}


export async function getMatchCompanyStatus(): Promise<AxiosResponse<BaseDataObjectTo[]>> {
    return axiosInstance(getAccessToken()).get(`${MATCH_COMPANY_STATUS_API}`)
}
