import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box, {BoxProps} from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import BaseCard from "./BaseCard";
import {MatchOverviewObjectTo} from "../../api/tos/matches/MatchOverviewObjectTo";
import Avatar, {AvatarProps} from "@mui/material/Avatar";
import {Badge, Tooltip, useTheme} from "@mui/material";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import {URI_CHAT, URI_MATCHES} from "../../config/Router";
import MatchCompanyStatusControl from "../controls/MatchCompanyStatusControl";
import {useNavigate} from "react-router-dom";

const StyledBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
}))

const StyledAvatar = styled(Avatar)<AvatarProps>(({theme}) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    width: 56,
    height: 56
}))

const SmallAvatar = styled(Avatar)<AvatarProps>(({theme}) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    width: 32,
    height: 32,
    left: '-5px',
    top: '5px'

}))

const AcceptedBox = styled(Box)<BoxProps>(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'end',
    gap: '5px',
    width: '160px',
    color: theme.palette.primary.main
}))

const ChatIconBox = styled(Box)<BoxProps>(({theme}) => ({
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    }
}));

const StyledTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: '#bbb',
    borderRadius: '15px',
    paddingLeft: '10px',
    paddingRight: '10px',
    whiteSpace: 'pre-wrap',
    lineHeight: '1.1',
    display: 'flex',
    alignItems: 'center',
}));

interface MatchRateAvatarProps {
    matchRate: number,
}

function MatchRateAvatar(props: MatchRateAvatarProps) {
    return (
        <StyledBox margin="5px">
            <StyledAvatar>
                <Typography variant="subtitle1" color="primary.main">
                    {`${Math.round(props.matchRate)}%`}
                </Typography>
            </StyledAvatar>
        </StyledBox>
    )
}

interface ImageAvatarProps {
    imageURL: string | undefined,
    matchRate: number,
}

function ImageAvatar(props: ImageAvatarProps) {
    const image = "/matches/matchPlaceholder.png" // TODO show actual img
    const theme = useTheme();

    return (
        <StyledBox margin="5px">
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                style={{left: '5px'}}
                badgeContent={
                    <SmallAvatar>
                        <Typography variant="body2" color="primary.main">{`${Math.round(props.matchRate)}%`}</Typography>
                    </SmallAvatar>
                }
            >
                <Avatar alt="match image" src={image} style={{border: `1px solid ${theme.palette.secondary.main}`}}/>
            </Badge>
        </StyledBox>
    )
}

function WaitingInfo() {
    return (
        <StyledTypography variant="subtitle1" align="center" height="3rem">{"Warten auf\nRückmeldung"}</StyledTypography>
    );
}


function AcceptedActions(props: MatchOverviewObjectTo) {
    const navigate = useNavigate();
    const {id, companyStatus, chatId} = props;

    const hasChat = !!chatId;

    const handleClickChat: React.MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if(hasChat) {
            navigate(URI_CHAT + "?chatId=" + chatId);
        }
    }

    return (
        <AcceptedBox>
            <Box>
                {hasChat && (
                    <Tooltip title="Zum Chat">
                        <ChatIconBox onClick={handleClickChat}>
                            <QuestionAnswerOutlinedIcon sx={{fontSize: '26px', }}/>
                        </ChatIconBox>
                    </Tooltip>
                )}
            </Box>
            <MatchCompanyStatusControl matchId={id} companyStatus={companyStatus}/>
        </AcceptedBox>
    );
}

function MatchOverviewCard(props: MatchOverviewObjectTo) {
    const {id, jobTitle, description, matchRate, accepted, interested, name, imgURL} = props;

    // const isNewMatch = !interested;
    const isOpenMatch = interested && !accepted;
    const isPerfectMatch = interested && accepted;

    const cardTitle = isPerfectMatch ? (name || "") : jobTitle;
    const onClickURI = `${URI_MATCHES}/${id}`;

    return (
        <BaseCard
            id={id}
            title={cardTitle}
            description={description}
            onClickURI={onClickURI}
            leftChild={isPerfectMatch ? <ImageAvatar imageURL={imgURL} matchRate={matchRate} /> : <MatchRateAvatar matchRate={matchRate}/>}
            rightChild={isPerfectMatch ? <AcceptedActions {...props} /> : isOpenMatch ? <WaitingInfo/> : <></>}
        />

    );
}

export default MatchOverviewCard;