import {Button, styled, Typography} from "@mui/material";
import * as React from "react";
import icon from './address-card.svg'
import {ButtonProps} from "@mui/material/Button";
import {useLocation} from "react-router-dom";
import {ROOT} from "../../../config/Router";

interface ProfileImageButtonProps {
    companyName: string
    onOpenMenuClick: (event: React.MouseEvent<HTMLElement>) => void
}

const StyledButton = styled(Button)<ButtonProps>(({theme, disabled}) => ({
    p: 0,
    boxShadow: disabled? '0px 0px 5px rgba(0, 0, 0, 0.3)': 'none',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
    }
}))

export function ProfileImageButton(props: ProfileImageButtonProps) {
    return (
        <StyledButton
            onClick={props.onOpenMenuClick}
            endIcon={<img src={icon} alt="Skillbased Logo"/>}
        >
            <Typography variant="subtitle1" color="text.primary">{props.companyName}</Typography>
        </StyledButton>
    )
}

export function OverviewButton() {
    const location = useLocation();
    const onOverviewPage = ROOT === location.pathname;

    return (
        <StyledButton disabled={onOverviewPage}>
            <Typography variant="subtitle1" color="text.primary">Meine Übersicht</Typography>
        </StyledButton>
    )
}


