import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {MatchOverviewObjectTo} from "../../api/tos/matches/MatchOverviewObjectTo";
import {RootState} from "../store";
import {getMatches} from "../../api/matchesApi";

export interface MatchesState {
    value: MatchOverviewObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: MatchesState = {
    value: [],
    status: 'ok'
}

export const fetchMatches = createAsyncThunk(
    'matches/fetchMatches',
    async () => {
        return await getMatches().then(value => value.data)
    }
);

export const selectMatches = (state: RootState) => state.matches.value

export const matchesSlice = createSlice({
    name: 'matches',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchMatches.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchMatches.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchMatches.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default matchesSlice.reducer