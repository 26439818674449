import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {CreateCompanyDetailObject} from "../../pages/company/types";

export interface CreateJobState {
    newProfile: CreateCompanyDetailObject
}

const initialState: CreateJobState = {
    newProfile: {},
}

export const selectCreateCompanyProfileObject = (state: RootState) => state.createCompanyProfile.newProfile;

export const createCompanyProfileSlice = createSlice({
    name: 'createCompanyProfile',
    initialState,
    reducers: {
        setCreateCompanyProfileObject: (state, action: PayloadAction<CreateCompanyDetailObject>) => {
            state.newProfile = action.payload;
        },
    }
})

export const {setCreateCompanyProfileObject} = createCompanyProfileSlice.actions;

export default createCompanyProfileSlice.reducer
