import {JobOverviewObjectTo} from "../../api/tos/jobs/JobOverviewObjectTo";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getJobs} from "../../api/jobsApi";
import {RootState} from "../store";

export interface JobsState {
    value: JobOverviewObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: JobsState = {
    value: [],
    status: 'ok'
}

export const fetchJobs = createAsyncThunk(
    'jobs/fetchJobs',
    async () => {
        return await getJobs().then(value => value.data)
    }
);

export const selectJobs = (state: RootState) => state.jobs.value

export const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchJobs.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchJobs.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchJobs.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default jobsSlice.reducer
