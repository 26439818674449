import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getLanguages} from "../../api/baseDataApi";

export interface LanguageState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: LanguageState = {
    value: [],
    status: 'ok'
}

export const fetchLanguages = createAsyncThunk(
    'baseData/fetchLanguages',
    async () => {
        return await getLanguages().then(value => value.data)
    }
);

export const selectLanguages = (state: RootState) => state.languages.value
export const selectLanguageByBusinessKey = (state: RootState) => {
    const languages = state.languages.value;
    return (id: string | null) => languages.find((language) => language.businessKey === id);
};

export const languagesSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchLanguages.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchLanguages.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchLanguages.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default languagesSlice.reducer
