import React, {useContext, useState} from 'react';
import {AuthContext} from "../../contexts/authContext";
import {useNavigate} from 'react-router-dom'
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import {Email, Password} from "../../components/authComponents";
import {useValidEmail, useValidPassword} from '../../hooks/useAuthHooks';
import {LOGGED_OUT_REQUEST_CODE, LOGGED_OUT_VERIFY, ROOT} from "../../config/Router";
import ReactGA from "react-ga4";

function SignIn() {

    const navigate = useNavigate();
    const authContext = useContext(AuthContext)

    const passwordResetClicked = async () => {
        navigate(LOGGED_OUT_REQUEST_CODE)
    }
    const signInClicked = async () => {
        try {
            await authContext.signInWithEmail(email, password)
            ReactGA.event({
                category: 'user',
                action: 'LOGIN',
            })
            navigate(ROOT)
        } catch (err: any) {
            if (err.code === 'UserNotConfirmedException') {
                navigate(LOGGED_OUT_VERIFY)
            } else {
                setError(err.message)
            }
        }
    }
    const {email, setEmail, emailIsValid} = useValidEmail('');
    const {password, setPassword, passwordIsValid} = useValidPassword('');
    const [error, setError] = useState('')
    const isValid = !emailIsValid || email.length === 0 || !passwordIsValid || password.length === 0

    return (
        <Paper style={{width: 480, padding: 32}}>
            {/*@ts-ignore*/}
            <Grid container direction="column" justify="center" alignItems="center">
                {/* Title */}
                <Box m={2}>
                    <Typography variant="h3">Login als Unternehmen</Typography>
                </Box>

                {/* Sign In Form */}
                <Box width="80%" m={1}>
                    <Email emailIsValid={emailIsValid} setEmail={setEmail}/>
                </Box>
                <Box width="80%" m={1}>
                    <Password label="Passwort" passwordIsValid={passwordIsValid} setPassword={setPassword}/>
                    {/*@ts-ignore*/}
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Box onClick={passwordResetClicked} mt={2}>
                            <Typography variant="body1">
                                Passwort vergessen?
                            </Typography>
                        </Box>
                    </Grid>
                </Box>

                {/* Error */}
                <Box mt={2}>
                    <Typography color="error" variant="body1">
                        {error}
                    </Typography>
                </Box>

                {/* Buttons */}
                <Box mt={2}>
                    {/*@ts-ignore*/}
                    <Grid container direction="row" justify="center">
                        <Box m={1}>
                            <Button disabled={isValid} color="primary" variant="contained" onClick={signInClicked}>
                                Jetzt anmelden
                            </Button>
                        </Box>
                    </Grid>
                </Box>
                <Box mt={2}>
                    <Box onClick={() => navigate('signup')}>
                        <Typography variant="body1">
                            Neuen Account anlegen
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Paper>
    )
}

export default SignIn;