import {Box, Button, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {BoxProps} from "@mui/material/Box";
import {ButtonProps} from "@mui/material/Button";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

const NavBox = styled(Box)<BoxProps>(({theme}) => ({
    width: '100%',
    padding: '20px',
    marginTop: '20px',
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.text.secondary,
    borderRadius: '10px',
}))

const StyledButton = styled(Button)<ButtonProps>(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    paddingLeft: '15px',
    paddingRight: '15px',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
    },
    '&:disabled': {
        backgroundColor: theme.palette.text.disabled,
        color: theme.palette.background.paper,
    },
}))

interface BottomNavigationProps {
    showPreviousButton?: boolean,
    onPrevious?: () => void,
    showNextButton?: boolean,
    onNext?: () => void,
    disableNext?: boolean,
    nextButtonText?: string,
}

function BottomNavigation(props: BottomNavigationProps) {

    return (
        <NavBox>
            <Box>
                {props.showPreviousButton &&
                    <StyledButton
                        type="reset"
                        form="multipage_form"
                        startIcon={<ArrowBackRoundedIcon/>}
                        onClick={props.onPrevious}
                    >
                        <Typography variant="subtitle1">Zurück</Typography>
                    </StyledButton>
                }
            </Box>
            <Box>
                {props.showNextButton &&
                    <StyledButton
                        type="submit"
                        form="multipage_form"
                        endIcon={<ArrowForwardRoundedIcon/>}
                        disabled={props.disableNext}
                        onClick={props.onNext}
                    >
                        <Typography variant="subtitle1">{props.nextButtonText || "Weiter"}</Typography>
                    </StyledButton>
                }
            </Box>
        </NavBox>
    )
}

export default BottomNavigation;