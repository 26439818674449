import {createTheme} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        accentColor: Palette['primary'];
    }
    interface PaletteOptions {
        accentColor: PaletteOptions['primary'];
    }
}

const theme = createTheme({
    palette: {
        background: {
            default: '#f7f5f3',
            paper: '#fff',
        },
        primary: {
            light: '#efedff',
            main: '#4d40d6'
        },
        secondary: {
          light: '#ebfaf5',
          main: '#25e6c5'
        },
        text: {
            primary: '#262626',
            secondary: '#cccccc',
            disabled: '#888888'
        },
        accentColor: {
            main: '#1365a8',
        }
    },
    typography: {
        fontFamily: ['Asap Variable'].join(","),
        h1: {
            fontSize: '34px',
            fontWeight: 'bold'
        },
        h2: {
            fontSize: '24px',
            fontWeight: 'bold'
        },
        h3: {
            fontSize: '18px',
            fontWeight: 'bold'
        },
        h5: {
            fontSize: '24px',
            fontWeight: 'lighter'
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 'bold'
        },
        subtitle2: {
            fontSize: '12px',
        },
        body1: {
            fontSize: '16px',
            fontWeight: 'normal'
        },
        body2: {
            fontSize: '11px',
            fontWeight: 'normal'
        },
        button: {
            textTransform: 'none'
        }
    }
});

export default theme;