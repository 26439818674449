import React from 'react'
import ResponsiveBottomBar from "../responsive-bottom-bar";
import ImageBackground from "../pageContent/ImageBackground";
import {Box, Paper, styled, Typography, useTheme} from "@mui/material";
import {TypographyProps} from "@mui/material/Typography";
import MobileGuard from "../../../config/MobileGuard";


export const MultilineTypography = styled(Typography)<TypographyProps>(() => ({
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
}));

function InfoBox() {
    const theme = useTheme();
    return (
        <Paper style={{width: 480, padding: 16, backgroundColor: '#444', color: theme.palette.background.default}}>
            <MultilineTypography variant="subtitle1">{"Unsere Webapp ist derzeit ausschließlich für Unternehmen verfügbar.\nAls Kandidat*In kannst du dir unsere App im Appstore oder im Google Play Store herunterladen."}</MultilineTypography>
        </Paper>
    )
}

interface LogoutOutPageContainerProps {
    children?: React.ReactElement | React.ReactElement[] | string
}

function LogoutOutPageContainer(props: LogoutOutPageContainerProps) {

    return (
        <MobileGuard>
            <div style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}>
                <header style={{
                    margin: 0,
                    width: '100%',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    backgroundColor: "white",
                    height: "140px",
                    textAlign: "center"
                }}>
                    <img style={{
                        display: "inline-block"
                    }} src="/skillbased-logo.png" alt="Skillbased Logo" width="200px"/>
                </header>
                <ImageBackground>
                    <Box display="flex" flexDirection="column" gap="15px">
                        <InfoBox/>
                        {props.children}
                    </Box>
                </ImageBackground>
                <ResponsiveBottomBar/>
            </div>
        </MobileGuard>
    )
}


export default LogoutOutPageContainer;