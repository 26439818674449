import {Box, styled, Typography} from "@mui/material";
import React, {Dispatch, SetStateAction} from "react";
import {BoxProps} from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';

interface ImageUploadProps {
    name?: string,
    label?: string,
    label2?: string,
    title?: string,
    description?: string,
    setSelected: Dispatch<SetStateAction<FileData[]>>

}

interface ImageUploadBoxProps {
    name?: string,
    setSelected: Dispatch<SetStateAction<FileData[]>>,
    color: string,
}

export interface FileData {
    title: string,
    base64Data: string,
}


function loadFile(file: Blob, fileIndex: number, files: FileData[], setData: Dispatch<SetStateAction<FileData[]>>) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        const dataString = reader.result instanceof ArrayBuffer ? null : reader.result;
        files[fileIndex].base64Data = dataString != null ? dataString : "";
        setData(files);
    };
}

function readFiles(fileList: FileList | null, setData: Dispatch<SetStateAction<FileData[]>>) {
    if (fileList != null) {
        const files: FileData[] = [];

        for (let i = 0; i < fileList.length; i++) {
            const newFile: FileData = {
                title: fileList.item(i)?.name || "",
                base64Data: "",
            }
            files.push(newFile);

            loadFile(fileList[i], i, files, setData);
        }
    }
}

const StyledBox = styled(Box)<BoxProps>(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '15px',
    border: `2px dashed ${theme.palette.text.disabled}`,
}))
interface AddBoxProps extends BoxProps {
    addboxcolor: string,
}
const AddBox = styled(Box)<AddBoxProps>(({addboxcolor}) => ({
    border:`1.5px dashed ${addboxcolor}`,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: addboxcolor,
}))

export function ImageUploadBox({setSelected, color, name}: ImageUploadBoxProps) {
    return (
        <Box height="100%" width="100%">
            <label htmlFor={name ? name : "file-upload"} style={{width: "100%", height: "100%", cursor: 'pointer'}}>
                <AddBox addboxcolor={color}>
                    <AddIcon/>
                </AddBox>
            </label>
            <input
                type="file"
                id={name ? name : "file-upload"}
                accept="image/jpeg,image/png"
                multiple
                style={{
                    opacity: 0,
                    zIndex: -1,
                    width: "100%",
                    height: "100%"
                }}
                onChange={(e) => readFiles(e.target != null ? e.target.files : null, setSelected)}
            />
        </Box>
    )
}

function ImageUpload({name, label, label2, title, description, setSelected}: ImageUploadProps) {
    const defaultTitle = "Bild hochladen";
    const defaultDescription = "Klicke, um ein Bild von deinem Computer auszuwählen";

    return (
        <Box display="flex" flex={1} flexDirection="column" paddingY="10px">
            <Typography variant="subtitle1">{label}</Typography>
            <Typography variant="body1">{label2}</Typography>
            <label htmlFor={!!name ? name : "file-upload"}>
                <StyledBox>
                    <Typography variant="subtitle1" color="primary">{title || defaultTitle}</Typography>
                    <Typography variant="body1" color="text.disabled" display="flex"
                                textAlign="center">{description || defaultDescription}</Typography>
                </StyledBox>
            </label>
            <input
                type="file"
                id={!!name ? name : "file-upload"}
                accept="image/jpeg,image/png"
                multiple
                style={{
                    opacity: 0,
                    zIndex: -1,
                    position: "absolute",
                }}
                onChange={(e) => readFiles(e.target != null ? e.target.files : null, setSelected)}
            />
        </Box>
    )
}

export default ImageUpload;