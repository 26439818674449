import {useDispatch, useSelector} from "react-redux";
import {
    deleteCurrentJob,
    disableJobEditing,
    enableJobEditing,
    selectJob,
    selectJobInEditMode
} from "../../state/jobs/jobSlice";
import {StyledButton} from "../buttons/StyledButton";
import * as React from "react";
import {useEffect, useState} from "react";
import JobActionDialog from "./JobActionDialog";
import {ButtonProps} from "@mui/material/Button";
import {Box, Dialog, DialogContent, Typography, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";
import {BoxProps} from "@mui/material/Box";
import {MultilineTypography} from "../typographys/MultilineTypography";
import {AcceptButton, ErrorButton} from "../buttons/CommonButtons";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useNavigate} from "react-router-dom";
import InfoPopup from "./InfoPopup";


function DialogButton(props: ButtonProps) {
    return (
        <StyledButton {...props} variant="contained"/>
    );
}

const ControlBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '250px',
}))

function JobStateControl() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const job = useSelector(selectJob);
    const jobInEditMode = useSelector(selectJobInEditMode);
    const [editInfoOpen, setEditInfoOpen] = useState(false);
    const theme = useTheme();
    const [open, setOpen] = useState(false)

    useEffect(() => {
        dispatch(disableJobEditing());
    }, [job?.id])

    if (!job) {
        return <></>;
    }
    const isPublished = job.status.businessKey === "PUBLISHED";

    const handleClickEdit = () => {
        if(jobInEditMode) {
            dispatch(disableJobEditing())
        } else {
            setEditInfoOpen(true);
        }
    }
    const handleAcceptEdit = () => {
        setEditInfoOpen(false);
        dispatch(enableJobEditing());
    }
    const handleDeclineEdit = () => {
        setEditInfoOpen(false);
    }

    const handleClickDelete = () => {
        setOpen(true);
    }
    const handleDeclineDelete = () => {
        setOpen(false);
    }
    const handleDelete = () => {
        setOpen(false);
        if (job) {
            // @ts-ignore
            dispatch(deleteCurrentJob({id: job.id, navigate: navigate}));
        }
    }

    return (
        <ControlBox>
            <JobActionDialog job={job} button={DialogButton}/>
            {isPublished && (
                <>
                    <DialogButton onClick={handleClickEdit}>{jobInEditMode ? 'Bearbeiten beenden' : 'Job bearbeiten'}</DialogButton>
                    <InfoPopup open={editInfoOpen} onAccept={handleAcceptEdit} acceptText="Verstanden" onAbort={handleDeclineEdit} text={"Info:\nWenn du den Job bearbeitest, werden deine Matches und angefragten Talente neu berechnet.\nDeine Perfect Matches bleiben bestehen und werden nicht angepasst."} />
                </>
            )}
            <StyledButton variant="contained" style={{backgroundColor: theme.palette.error.main}} onClick={handleClickDelete}>Job löschen</StyledButton>
            <Dialog open={open}>
                <DialogContent style={{margin: '0px', padding: 0, backgroundColor: '#444', border: 'none'}}>
                    <Box display="flex" width="100%" height="100%" flexDirection="column" alignItems="center" gap="10px"
                         padding="20px">
                        <DeleteForeverIcon style={{color: theme.palette.error.main, fontSize: '40px'}} />
                        <MultilineTypography display="flex" textAlign="center" variant="subtitle1"
                                             color="background.paper">
                            {"Willst du den Job wirklich löschen?\nDer Job kann nicht wiederhergestellt werden"}
                        </MultilineTypography>
                        <Box display="flex" flexDirection="row" justifyContent="center" gap="10px">
                            <AcceptButton variant="outlined" onClick={handleDeclineDelete}>
                                <Typography variant="subtitle1" color="background.paper">{"Nicht löschen"}</Typography>
                            </AcceptButton>
                            <ErrorButton onClick={handleDelete}>
                                <Typography variant="subtitle1" color="background.paper">{"Wirklich löschen"}</Typography>
                            </ErrorButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </ControlBox>
    )
}

export default JobStateControl;