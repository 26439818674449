import {styled, TextField, TextFieldProps, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useState} from "react";
import {ChatBorders} from "./ChatBorders";
import {StatusButton} from "../buttons/CommonButtons";
import * as React from "react";

interface IProps {
    postMessage: (text: string) => Promise<void>;
}

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '20px',
    borderTop: ChatBorders,
});

const StyledTextField = styled(TextField)<TextFieldProps>({
    width: '100%',
    marginBottom: '10px',
    '& .MuiInputBase-root': {
        borderRadius: '15px',
        background: '#ededee',
    },
})

function ChatTextField(props: IProps) {
    const {postMessage} = props;

    const [text, setText] = useState("");

    const handleTextChange = (event: any) => {
        setText(event.target.value);
    }

    const reset = () => {
        setText("");
    }

    const handleSend = () => {
        postMessage(text)
            .then(reset)
    }

    return (
        <Container>
            <StyledTextField
                value={text}
                onChange={handleTextChange}
                multiline
                minRows={4}
                maxRows={10}
                placeholder="Schreibe hier eine neue Nachricht"
            />
            <StatusButton
                onClick={handleSend}
                disabled={text.trim().length === 0}
            >
                <Typography variant="subtitle1">Senden</Typography>
            </StatusButton>
        </Container>
    );
}

export default ChatTextField;