import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {Typography} from "@mui/material";
import {CreateJobObject} from "../types";
import {FormSlider} from "../../../components/forms/FormSlider";
import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";


export type CreateJobSoftSkillsFormData = {
    neurotizismus: number,
    extraversion: number,
    gewissenhaftigkeit: number,
    offenheit: number,
    vertraeglichkeit: number,
    machtmotiv: number,
    sicherheitsmotiv: number,
    leistungsmotiv: number,
}

interface SoftSkillType {
    title: "neurotizismus" | "extraversion" | "gewissenhaftigkeit" | "offenheit" | "vertraeglichkeit" | "machtmotiv" | "sicherheitsmotiv" | "leistungsmotiv",
    name: string,
    infoText: string,
}

const softSkillData: SoftSkillType[] = [
    {
        title: "neurotizismus",
        name: "Neurotizismus",
        infoText: "Neurotizismus beschreibt die Tendenz einer Person, Emotionen eher äußerst kontrolliert zu zeigen (niedriger Wert) anstatt der Emotionen öfters freien Lauf zu lassen (hoher Wert). Personen mit einer hohen Ausprägung sind eher sensibel und weniger stabil in ihrer Stimmungslage. Sie sind häufig unsicher, nervös und haben mehr Ängste, als andere.",
    },
    {
        title: "extraversion",
        name: "Extraversion",
        infoText: "Als Extraversion bezeichnet man ein nach außen gewandtes Persönlichkeitsprofil. Extravertierte Menschen empfinden den Austausch und das Handeln innerhalb sozialer Gruppen als anregend. ",
    },
    {
        title: "gewissenhaftigkeit",
        name: "Gewissenhaftigkeit",
        infoText: "Gewissenhaftigkeit beschreibt, inwiefern eine Person zielstrebig, ordentlich und genau ist. Menschen mit einer hohen Ausprägung sind sehr diszipliniert, besonnen und haben ein hohes Maß an Selbstkontrolle.",
    },
    {
        title: "offenheit",
        name: "Offenheit",
        infoText: "Offenheit beschreibt die Aufgeschlossenheit einer Person. Jemand mit hohen Werten ist fantasievoll, neugierig und experimentierfreudig. ",
    },
    {
        title: "vertraeglichkeit",
        name: "Verträglichkeit",
        infoText: "Jemand mit hohen Verträglichkeitswerten ist herzlich, freundlich und taktvoll. Diese Person hat ein optimistisches Menschenbild und kommt gut mit anderen aus.",
    },
    {
        title: "machtmotiv",
        name: "Machtmotiv",
        infoText: "Ein hoher Wert des Machtmotives beschreibt das Bestreben, Entscheidungen zu treffen und Einfluss auf bestimmte Dinge zu haben ",
    },
    {
        title: "sicherheitsmotiv",
        name: "Sicherheitsmotiv",
        infoText: "Ein hoher Wert beschreibt das Bedürfnis nach Sicherheit und eine geringere Risikobereitschaft.",
    },
    {
        title: "leistungsmotiv",
        name: "Leistungsmotiv",
        infoText: "Ein hoher Wert beschreibt das Bestreben von Menschen, die eigene Leistungsfähigkeit unter Beweis zu stellen und die erbrachte Leistung zu messen.",
    },
]

function Skills({currentObject, onPrevious, onNext}: MultiPageComponentProps<CreateJobObject>) {

    const formContext = useForm<CreateJobSoftSkillsFormData>({
        defaultValues: {
            neurotizismus: 5,
            extraversion: 5,
            gewissenhaftigkeit: 5,
            offenheit: 5,
            vertraeglichkeit: 5,
            machtmotiv: 5,
            sicherheitsmotiv: 5,
            leistungsmotiv: 5,
        }
    })
    const {control, handleSubmit} = formContext;

    const saveData = (data: CreateJobSoftSkillsFormData) => {
        var values:number[] = [];
        softSkillData.forEach((softSkill) => {
            values.push(data[softSkill.title])
        })
        const newCreateJobObject: CreateJobObject = {
            ...currentObject,
            characteristics: values,
        }
        return newCreateJobObject;
    }

    const handlePrevious = () => {
        const data: CreateJobSoftSkillsFormData = formContext.getValues();
        onPrevious && onPrevious(saveData(data), true);
    }

    const onSubmit = (data: CreateJobSoftSkillsFormData) => {
        onNext && onNext(saveData(data), true);
    };


    useEffect(() => {
        if (currentObject) {
            currentObject.characteristics?.forEach((characteristicValue, key) => {
                if(key < softSkillData.length) {
                    formContext.setValue(softSkillData[key].title, characteristicValue);
                }
            })
        }
    }, [])

    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit)} onReset={handlePrevious}>
            <Typography variant="body1" paddingTop="10px">Bitte gib an, wie stark dein/e Kandidat/in in den einzelnen
                Skills im Vergleich zum Durchschnitt sein sollte. Gematcht werden alle KandidatInnen, die deinen
                Mindestansprüchen entsprechen.</Typography>
            {softSkillData.map((data, key) => (
                <FormSlider
                    key={key}
                    control={control}
                    name={data.title}
                    label={data.name}
                    infoText={data.infoText}
                />
            ))}
        </form>
    );
}

export default Skills;