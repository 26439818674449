import {CompanyDetailObject, CreateCompanyDetailObject} from "../pages/company/types";
import {CreateCompanyTo} from "../api/tos/company/CreateCompanyTo";
import {CompanyTo} from "../api/tos/company/CompanyTo";
import {UpdateCompanyTo} from "../api/tos/company/UpdateCompanyTo";

export const createCompanyObjectToCreateCompanyTo: (companyObject: CreateCompanyDetailObject) => CreateCompanyTo | undefined = (companyObject) => {
    const logoData = !!companyObject.logo && extractImageData(companyObject.logo);
    if (!!companyObject.companyName
        && !!companyObject.description
        && !!companyObject.employees
        && !!companyObject.street
        && !!companyObject.zipCode
        && !!companyObject.city
        && !!companyObject.benefits
        && !!logoData
    ) {
        const companyTo: CreateCompanyTo = {
            name: companyObject.companyName,
            about: companyObject.description,
            companyValues: companyObject.companyValues ?? '',
            vision: companyObject.vision ?? '',
            numberOfEmployee: parseInt(companyObject.employees),
            address: {
                street: companyObject.street,
                streetNumber: "?",
                postCode: companyObject.zipCode,
                city: companyObject.city,
            },
            benefits: companyObject.benefits,
            logo: logoData,
            logoType: "",
        }
        return companyTo;
    }
    return undefined;
}

export const companyToToCompanyObject: (companyTo: CompanyTo) => CompanyDetailObject = (companyTo) => {
    const companyObj: CompanyDetailObject = {
        companyName: companyTo.name,
        description: companyTo.about,
        companyValues: companyTo.companyValues,
        vision: companyTo.vision,
        employees: "" + companyTo.numberOfEmployee,
        street: companyTo.address.street,
        streetNumber: companyTo.address.streetNumber,
        zipCode: companyTo.address.postCode,
        city: companyTo.address.city,
        benefits: !companyTo.benefits ? [] : companyTo.benefits.map((benefit) => benefit.businessKey),
        logo: "data:image/png;base64," + companyTo.logo,
    }

    return companyObj;
}

export const companyObjectToUpdateCompanyTo: (companyObject: CompanyDetailObject, id : number) => UpdateCompanyTo | undefined = (companyObject, id) => {
    const logoData = !!companyObject.logo && extractImageData(companyObject.logo);
    if (!!companyObject.companyName
        && !!companyObject.description
        && !!companyObject.employees
        && !!companyObject.street
        && !!companyObject.zipCode
        && !!companyObject.city
        && !!companyObject.benefits
        && !!logoData
    ) {
        const updateCompanyTo: UpdateCompanyTo = {
            id: id,
            name: companyObject.companyName,
            about: companyObject.description,
            companyValues: companyObject.companyValues ?? '',
            vision: companyObject.vision ?? '',
            numberOfEmployee: parseInt(companyObject.employees),
            address: {
                street: companyObject.street,
                streetNumber: "?",
                postCode: companyObject.zipCode,
                city: companyObject.city,
            },
            benefits: companyObject.benefits,
            logo: logoData,
            logoType: "",
        }
        return updateCompanyTo;
    }
    return undefined;
}

function extractImageData(base64String: string) {
    const parts = base64String.split(',');

    if (parts.length === 2) {
        return parts[1];
    } else {
        console.error('Invalid base64 image string format');
        return undefined;
    }
}
