import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";

export const DeclineButton = styled(Button)<ButtonProps>(({theme}) => ({
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: 6,
}))

export const AcceptButton = styled(Button)<ButtonProps>(({theme}) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 6,
}))

export const OutlinedButton = styled(Button)<ButtonProps>(({theme}) => ({
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
}))

export const ErrorButton = styled(Button)<ButtonProps>(({theme}) => ({
    border: `2px solid ${theme.palette.error.main}`,
    backgroundColor: theme.palette.error.main,
    borderRadius: 6,
}))

export const StatusButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    paddingTop: 1,
    paddingBottom: 1,
    '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
    }
}))