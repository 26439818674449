import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseDataObjectTo} from "../../api/tos/baseData/BaseDataObjectTo";
import {getFieldOfActivities} from "../../api/baseDataApi";

export interface FieldOfActivitiesState {
    value: BaseDataObjectTo[]
    status: 'ok' | 'loading' | 'failed';
}
const initialState: FieldOfActivitiesState = {
    value: [],
    status: 'ok'
}

export const fetchFieldOfActivities = createAsyncThunk(
    'baseData/fetchFieldOfActivities',
    async () => {
        return await getFieldOfActivities().then(value => value.data)
    }
);

export const selectFieldOfActivities = (state: RootState) => state.fieldOfActivities.value
export const selectFieldOfActivityById = (state: RootState) => {
    const fieldOfActivities = state.fieldOfActivities.value;
    return (id: string | null) => fieldOfActivities.find((fieldOfActivity) => fieldOfActivity.businessKey === id);
};

export const fieldOfActivitiesSlice = createSlice({
    name: 'fieldOfActivities',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchFieldOfActivities.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchFieldOfActivities.fulfilled, (state, action) => {
            state.status = 'ok'
            state.value = action.payload
        })
        builder.addCase(fetchFieldOfActivities.rejected, (state) => {
            state.status = 'failed'
        })
    }
})

export default fieldOfActivitiesSlice.reducer
