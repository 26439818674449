import {CreateJobObject, JobStatus} from "../pages/jobs/types";
import {CreateJobDetailObjectTo} from "../api/tos/jobs/CreateJobDetailObjectTo";
import {JobDetailObjectTo} from "../api/tos/jobs/JobDetailObjectTo";
import {UpdateJobDetailObjectTo} from "../api/tos/jobs/UpdateJobDetailObjectTo";

export const createJobObjectToCreateJobTo: (createJobObject: CreateJobObject) => CreateJobDetailObjectTo | undefined = (createJobObject) => {
    if (createJobObject.title !== undefined
        && createJobObject.description !== undefined
        && createJobObject.remote !== undefined
        && createJobObject.zipCode !== undefined
        && createJobObject.payGrade !== undefined
        && createJobObject.scopeOfActivities !== undefined
        && createJobObject.startDate !== undefined
        && createJobObject.hoursPerWeek !== undefined
        && createJobObject.vacationDaysPerYear !== undefined
        && createJobObject.areaOfActivity !== undefined
        && createJobObject.skills !== undefined
        && createJobObject.noToolsNeeded !== undefined
        && createJobObject.tools !== undefined
        && createJobObject.priorities !== undefined
        && createJobObject.characteristics
        && createJobObject.characteristics.length === 8
        && createJobObject.languages
        && createJobObject.languages.filter((language) => language.languageId === undefined || language.levelId === undefined || language.requiredId === undefined).length === 0
    ) {
        const newJobObject: CreateJobDetailObjectTo = {
            title: createJobObject.title,
            description: createJobObject.description,
            expirationDate: createJobObject.expirationDate || "",
            status: createJobObject.status || JobStatus.NEW.toString(),
            locationType: createJobObject.remote,
            address: {
                street: "-",
                streetNumber: "-",
                postCode: createJobObject.zipCode,
                city: "-",
            },
            payGrade: createJobObject.payGrade,
            furtherEducation: !!createJobObject.trainingPossible,
            scopeOfActivities: createJobObject.scopeOfActivities,
            endDate: createJobObject.isLimited ? createJobObject.endDate || "" : "",
            startDate: createJobObject.startDate,
            hoursPerWeek: parseFloat(createJobObject.hoursPerWeek),
            vacationDaysPerYear: parseFloat(createJobObject.vacationDaysPerYear),
            fieldOfActivity: createJobObject.areaOfActivity,
            skills: createJobObject.skills,
            noToolsNeeded: createJobObject.noToolsNeeded,
            tools: createJobObject.tools,
            priorities: createJobObject.priorities,
            softSkillDetails: {
                neuroticism: createJobObject.characteristics[0],
                extraversion: createJobObject.characteristics[1],
                conscientiousness: createJobObject.characteristics[2],
                openness: createJobObject.characteristics[3],
                compatibility: createJobObject.characteristics[4],
                powerMotive: createJobObject.characteristics[5],
                safetyMotive: createJobObject.characteristics[6],
                performanceMotive: createJobObject.characteristics[7],
            },
            languageRequirements: createJobObject.languages?.map((lang) => {
                return {
                    language: lang.languageId || "",
                    languageLevel: lang.levelId || "",
                    languageRequirement: lang.requiredId || "",
                }
            }) || [],
        }
        return newJobObject;
    }
    return undefined;
}

export const createJobObjectToUpdateJobTo: (createJobObject: CreateJobObject, id: number) => UpdateJobDetailObjectTo | undefined = (createJobObject, id) => {
    const newJob = createJobObjectToCreateJobTo(createJobObject);
    if(newJob) {
        const updatedJob: UpdateJobDetailObjectTo = {
            ...newJob,
            id: id,
        }
        return updatedJob;
    }
    return undefined;
}


    export const jobDetailToToCreateJobObject: (jobTo: JobDetailObjectTo) => CreateJobObject = (jobTo) => {
    const job: CreateJobObject = {
        title: jobTo.title,
        description: jobTo.description,
        expirationDate: jobTo.expirationDate,
        status: jobTo.status.businessKey,
        remote: jobTo.locationType.businessKey,
        zipCode: jobTo.address.postCode,
        payGrade: jobTo.payGrade.businessKey,
        trainingPossible: jobTo.furtherEducation,
        scopeOfActivities: jobTo.scopeOfActivities?.map((scope) => scope.businessKey) ?? [],
        endDate: jobTo.endDate,
        startDate: jobTo.startDate,
        hoursPerWeek: "" + jobTo.hoursPerWeek,
        vacationDaysPerYear: "" + jobTo.vacationDaysPerYear,
        areaOfActivity: jobTo.fieldOfActivity.businessKey,
        skills: jobTo.skills.map((skill) => skill.businessKey),
        noToolsNeeded: jobTo.noToolsNeeded,
        tools: jobTo.tools.map((tool) => tool.businessKey),
        priorities: jobTo.priorities.map((priority) => priority.businessKey),
        characteristics: [
            jobTo.softSkillDetails.neuroticism,
            jobTo.softSkillDetails.extraversion,
            jobTo.softSkillDetails.conscientiousness,
            jobTo.softSkillDetails.openness,
            jobTo.softSkillDetails.compatibility,
            jobTo.softSkillDetails.powerMotive,
            jobTo.softSkillDetails.safetyMotive,
            jobTo.softSkillDetails.performanceMotive,
        ],
        languages: jobTo.languageRequirements?.map((lang) => {
            return {
                languageId: lang.language.businessKey,
                levelId: lang.languageLevel.businessKey,
                requiredId: lang.languageRequirement.businessKey,
            }
        }),
    }
    return job;
}

export const jobToToUpdateJobTo: (job: JobDetailObjectTo) => UpdateJobDetailObjectTo = (jobTo) => {
    return createJobObjectToUpdateJobTo(jobDetailToToCreateJobObject(jobTo), jobTo.id)!;
}