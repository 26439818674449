import {useSelector} from "react-redux";
import * as React from "react";
import {Box, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {BoxProps} from "@mui/material/Box";
import {selectMatch, selectMatchStatus} from "../../state/matches/matchSlice";
import {MatchSegmentation} from "../../api/tos/matches/MatchDetailObjectTo";
import MatchActionDialog from "./MatchActionDialog";


const ControlBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '300px',
}))
const InfoContainer = styled(Box)<BoxProps>(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px',
    width: '100%',
    border: `1px solid ${theme.palette.text.disabled}`
}))
const MatchContainer = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
}))

interface MatchBoxProps extends BoxProps {
    type: MatchSegmentation,
}

const MatchBox = styled(Box)<MatchBoxProps>(({theme, type}) => {
    switch (type) {
        case MatchSegmentation.MATCH:
            return {
                height: '2rem',
                width: '2rem',
                borderRadius: '1rem',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.light,
                border: `2px solid ${theme.palette.primary.main}`
            };
        case MatchSegmentation.ADDITIONAL:
            return {
                height: '2rem',
                width: '2rem',
                borderRadius: '1rem',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.light,
                border: `2px solid ${theme.palette.secondary.main}`
            };
        case MatchSegmentation.MISSING:
            return {
                height: '2rem',
                width: '2rem',
                borderRadius: '1rem',
                color: theme.palette.text.primary,
                backgroundColor: 'white',
                border: `2px dashed ${theme.palette.text.disabled}`

            };
        case MatchSegmentation.NONE:
            return {
                height: '2rem',
                width: '2rem',
                borderRadius: '5px',
                color: theme.palette.text.primary,
                backgroundColor: 'white',
                border: `1px solid ${theme.palette.text.disabled}`,
                opacity: 0.7,
            };
        default:
            return {
                height: 30,
                width: 15,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.primary}`,
            };
    }
});

function InfoBox() {
    return (
        <InfoContainer>
            <MatchContainer>
                <MatchBox type={MatchSegmentation.MATCH}/>
                <Typography>Match</Typography>
            </MatchContainer>
            <MatchContainer>
                <MatchBox type={MatchSegmentation.ADDITIONAL}/>
                <Typography>Zusätzliche Fähigkeit</Typography>
            </MatchContainer>
            <MatchContainer>
                <MatchBox type={MatchSegmentation.MISSING}/>
                <Typography>Fehlt</Typography>
            </MatchContainer>
            <MatchContainer>
                <MatchBox type={MatchSegmentation.NONE}/>
                <Typography>Deine Soft Skill-Angabe</Typography>
            </MatchContainer>
        </InfoContainer>
    )
}

function MatchDetailControl() {
    const match = useSelector(selectMatch);
    const status = useSelector(selectMatchStatus);

    if (!match || status === 'loading') {
        return <></>
    }

    return (
        <ControlBox>
            <MatchActionDialog match={match}/>
            <InfoBox/>
        </ControlBox>
    )
}

export default MatchDetailControl;