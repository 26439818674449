import {MultiPageComponentProps} from "../../../components/menus/MultiPageFormMenu";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {fetchToolsByFieldOfActivityBusinessKey, selectTools} from "../../../state/basedata/toolsSlice";
import {fetchSkillsByFieldOfActivityBusinessKey, selectSkills} from "../../../state/basedata/skillsSlice";
import React, {useEffect, useState} from "react";
import {CreateJobObject} from "../types";
import {Typography} from "@mui/material";
import {FormToggleButtonGroup} from "../../../components/forms/FormToggleButtonGroup";
import {BaseDataObjectTo} from "../../../api/tos/baseData/BaseDataObjectTo";

export type PrioritiesFormData = {
    priorities: string[],
}

function Priorities({currentObject, onNext, onPrevious}: MultiPageComponentProps<CreateJobObject>) {

    const tools = useSelector(selectTools);
    const skills = useSelector(selectSkills);
    const dispatch = useDispatch();

    const [priorityOptions, setPriorityOptions] = useState<BaseDataObjectTo[]>([])

    const formContext = useForm<PrioritiesFormData>({
        defaultValues: {
            priorities: [],
        }
    })
    const {formState: {errors, isValid}, control, handleSubmit} = formContext;

    const saveData = (data: PrioritiesFormData) => {
        const newCreateJobObject: CreateJobObject = {
            ...currentObject,
            priorities: data.priorities,
        }
        return newCreateJobObject;
    }

    const handlePrevious = () => {
        const data: PrioritiesFormData = formContext.getValues();
        onPrevious && onPrevious(saveData(data), isValid);
    }

    const onSubmit = (data: PrioritiesFormData) => {
        onNext && onNext(saveData(data), true);
    }

    const handleValidationFailed = () => {
        const data: PrioritiesFormData = formContext.getValues();
        onNext && onNext(saveData(data), false);
    };

    const mergeToolsAndSkills = () => {
        let chosenTools = tools
            .filter((tool) => (currentObject.tools || []).includes(tool.businessKey));

        let chosenSkills = skills
            .filter((skill) => (currentObject.skills || []).includes(skill.businessKey));

        setPriorityOptions([...chosenTools, ...chosenSkills]);
    }

    useEffect(() => {
        if (currentObject) {
            formContext.setValue("priorities", currentObject.priorities || []);
            mergeToolsAndSkills();
        }
    }, [currentObject, tools, skills]);

    useEffect(() => {
        if(currentObject.areaOfActivity && tools.length === 0) {
            // @ts-ignore
            dispatch(fetchToolsByFieldOfActivityBusinessKey(currentObject.areaOfActivity))
        }
        if(currentObject.areaOfActivity && skills.length === 0) {
            // @ts-ignore
            dispatch(fetchSkillsByFieldOfActivityBusinessKey(currentObject.areaOfActivity))
        }
    }, [currentObject])

    return (
        <form id="multipage_form" onSubmit={handleSubmit(onSubmit, handleValidationFailed)} onReset={handlePrevious}>
            <Typography variant="body1" paddingTop="10px">Welche Fertigkeiten oder Programme sind für den von dir
                angelegten Job besonders wichtig?</Typography>
            <Typography variant="body1" paddingTop="10px">Du kannst maximal 5 auswählen, die dann im Matching eine
                stärkere Gewichtung bekommen.</Typography>
            <FormToggleButtonGroup
                control={control}
                name="priorities"
                label=""
                rules={{required: true}}
                error={!!errors.priorities}
                errorMessage="Bitte mindestens eine Option auswählen"
                options={priorityOptions}
                sameSize
                maxSelect={5}
            />
        </form>
    );
}

export default Priorities;