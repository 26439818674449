import {getAuth, signInWithCustomToken} from "firebase/auth";
import {useEffect, useRef, useState} from "react";
import {getFirestoreToken} from "../api/authApi";

const EXPIRATION_THRESH_MS = 60_000;

export interface IFirestoreContext {
    signedIn: boolean;
    chatUserId: string | null;
    reload: (force?: boolean) => void;
    signOut: () => void;
}

export const useFirestore = () => {
    const [token, setToken] = useState<string | null>(null);
    const [signedIn, setSignedIn] = useState(false);
    const [chatUserId, setChatUserId] = useState<string | null>(null);
    const reloadTimerId = useRef(null);

    const retrieveChatUserId = () => {
        const auth = getAuth();
        if(!auth.currentUser) {
            setChatUserId(null)
        } else {
            auth.currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    if (!!idTokenResult.claims.userId) {
                        setChatUserId(idTokenResult.claims.userId as string);
                    } else {
                        setChatUserId(null);
                    }

                    setupReloadTimer(idTokenResult.expirationTime);
                })
                .catch(() => {
                    setChatUserId(null);
                });
        }
    }

    const loadToken = () => {
        setSignedIn(false);
        setChatUserId(null);
        getFirestoreToken()
            .then((res) => setToken(res.data))
    }

    const reload = (force?: boolean) => {
        if(!chatUserId || force) {
            loadToken();
        }
    }

    const setupReloadTimer = (expirationTime: string) => {
        const delay = Date.parse(expirationTime) - Date.now() - EXPIRATION_THRESH_MS;
        if(delay > 0) {
            // @ts-ignore
            reloadTimerId.current = setTimeout(
                () => reload(true),
                delay
            );
        }
    }

    const removeReloadTimer = () => {
        if (reloadTimerId.current) {
            clearTimeout(reloadTimerId.current);
        }
    }

    const signOut = () => {
        const auth = getAuth();

        auth.signOut()
            .then(() => {
                setToken(null);
                setSignedIn(false);
                setChatUserId(null);
            })
    }

    useEffect(() => {
        loadToken();

        return removeReloadTimer;
    }, [])

    useEffect(() => {
        if (token) {
            const auth = getAuth();
            signInWithCustomToken(auth, token)
                .then(() => {
                    retrieveChatUserId();
                    setSignedIn(true);
                })
                .catch(() => {
                    setSignedIn(false);
                });
        } else {
            setSignedIn(false);
        }
    }, [token])

    return {signedIn, chatUserId, reload, signOut}
}