import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import matchesReducer from '../state/matches/matchesSlice';
import matchReducer from '../state/matches/matchSlice';
import jobsReducer from '../state/jobs/jobsSlice';
import jobReducer from '../state/jobs/jobSlice';
import createJobReducer from '../state/jobs/createJobSlice';
import updateJobReducer from '../state/jobs/updateJobSlice';
import publishJobReducer from '../state/jobs/publishJobSlice';
import createCompanyProfileReducer from '../state/company/createNewProfileSlice';
import companyReducer from '../state/company/companySlice';
import multiPageFormReducer from '../state/multipageform/multipageFormSlice';
import applicationMenuReducer from '../state/menu/applicationMenuSlice';
import languagesReducer from '../state/basedata/languagesSlice';
import languageLevelsReducer from './basedata/languageLevelsSlice';
import languageRequirementsReducer from './basedata/languageRequirementsSlice';
import jobScopesOfActivityReducer from './basedata/jobScopesOfActivitySlice';
import jobPayGradesReducer from './basedata/jobPayGradesSlice';
import jobLocationsReducer from './basedata/jobLocationsSlice';
import fieldOfActivitiesReducer from './basedata/fieldOfActivitiesSlice';
import toolsReducer from './basedata/toolsSlice';
import skillsReducer from './basedata/skillsSlice';
import companyBenefitsReducer from './basedata/companyBenefitsSlice';
import matchCompanyStatusReducer from './basedata/matchCompanyStatusSlice';


export const store = configureStore({
    reducer: {
        matches: matchesReducer,
        match: matchReducer,
        jobs: jobsReducer,
        job: jobReducer,
        createJob: createJobReducer,
        updateJob: updateJobReducer,
        publishJob: publishJobReducer,
        createCompanyProfile: createCompanyProfileReducer,
        company: companyReducer,
        multiPageForm: multiPageFormReducer,
        applicationMenu: applicationMenuReducer,
        languages: languagesReducer,
        languageLevels: languageLevelsReducer,
        languageRequirements: languageRequirementsReducer,
        jobScopesOfActivity: jobScopesOfActivityReducer,
        jobPayGrades: jobPayGradesReducer,
        jobLocations: jobLocationsReducer,
        fieldOfActivities: fieldOfActivitiesReducer,
        tools: toolsReducer,
        skills: skillsReducer,
        companyBenefits: companyBenefitsReducer,
        matchCompanyStatus: matchCompanyStatusReducer,
    },
    devTools: true
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
