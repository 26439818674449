import React from 'react'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import MatchesOverview from "../pages/matches/MatchesOverview";
import ContentPage3ColumnsLayout from "../components/layout/pageContent/ContentPage3ColumnsLayout";
import CreateNewJobButton from "../components/buttons/CreateNewJobButton";
import ChatOverview from "../pages/chat/ChatOverview";
import JobsOverview from "../pages/jobs/JobsOverview";
import NewJob, {newJobPages} from "../pages/jobs/NewJob";
import SignIn from "../pages/auth/SignIn";
import ApplicationMenu from "../components/menus/ApplicationMenu";
import MultiPageForm from "../components/layout/pageContent/MultiPageForm";
import {LinkData} from "../components/commonTypes";
import MatchDetails from "../pages/matches/MatchDetails";
import JobDetails from "../pages/jobs/JobDetails";
import NewCompanyProfile, {newProfilePages} from "../pages/company/NewCompanyProfile";
import MultiPageFormMenu from "../components/menus/MultiPageFormMenu";
import Guard from "./Guard";
import CompanyProfile from "../pages/company/CompanyProfile";
import JobStateControl from "../components/controls/JobStateControl";
import LandingPage from "../pages/common/LandingPage";
import ForgotPassword from "../pages/auth/ForgotPassword";
import RequestCode from "../pages/auth/RequestCode";
import VerifyCode from "../pages/auth/VerifyCode";
import SignUp from "../pages/auth/SignUp";
import LogoutOutPageContainer from "../components/layout/pageLayouts/LogoutOutPageContainer";
import PageLayout from "../components/layout/PageLayout";
import MatchDetailControl from "../components/controls/MatchDetailControl";
import NewJobControl from "../components/controls/NewJobControl";
import ContentPage2ColumnsLayout from "../components/layout/pageContent/ContentPage2ColumnsLayout";

export const URI_MATCHES = "/matches"
export const URI_MATCHES_DETAILS = URI_MATCHES + "/:matchId"
export const URI_JOBS = "/jobs"
export const URI_JOBS_DETAILS = URI_JOBS + "/:jobId"
export const URI_COMPANY_NEW_PROFILE = "/company/new"
export const URI_COMPANY_PROFILE = "/company"
export const URI_COMPANY_PROFILE_WELCOME = "/company?welcome=true"


export const URI_CHAT = "/chat"
export const ROOT = "/"
export const URI_UNREGISTERED = "/signup"
export const URI_LOGOUT = URI_UNREGISTERED + "?logout=true"
export const URI_JOBS_NEW = URI_JOBS + "/new"


export const LOGGED_OUT_SIGN_UP = "/signup"
export const LOGGED_OUT_LOGGED_OUT = "/logout"
export const LOGGED_OUT_VERIFY = "/verify"
export const LOGGED_OUT_REQUEST_CODE = "/requestcode"
export const LOGGED_OUT_FORGOT_PASSWORD = "/forgotpassword"

export const appBarMenuItems: LinkData[] = [
    {title: "Profil bearbeiten", path: URI_COMPANY_PROFILE},
    {title: "Zahlungsdaten", path: ""},
]

export const applicationMenuItems: LinkData[] = [
    {title: "Übersicht", path: ROOT},
    {title: "Meine Jobs", path: URI_JOBS},
    {title: "Meine Matches", path: URI_MATCHES},
    {title: "Chat", path: URI_CHAT},
];

const signedOut = createBrowserRouter([
    {path: ROOT, element: <LogoutOutPageContainer><SignIn/></LogoutOutPageContainer>},
    {path: LOGGED_OUT_SIGN_UP, element: <LogoutOutPageContainer><SignUp/></LogoutOutPageContainer>},
    {path: LOGGED_OUT_VERIFY, element: <LogoutOutPageContainer><VerifyCode/></LogoutOutPageContainer>},
    {path: LOGGED_OUT_REQUEST_CODE, element: <LogoutOutPageContainer><RequestCode/></LogoutOutPageContainer>},
    {path: LOGGED_OUT_FORGOT_PASSWORD, element: <LogoutOutPageContainer><ForgotPassword/></LogoutOutPageContainer>},
    {path: LOGGED_OUT_LOGGED_OUT, element: <LogoutOutPageContainer><LandingPage/></LogoutOutPageContainer>},
]);
const router = createBrowserRouter([
    {
        path: ROOT,
        element: <PageLayout><Guard><ContentPage3ColumnsLayout childrenLeft={<ApplicationMenu/>} childrenRight={
            <CreateNewJobButton/>}><Dashboard/></ContentPage3ColumnsLayout></Guard></PageLayout>
    },
    {
        path: URI_MATCHES,
        element: <PageLayout><Guard><ContentPage3ColumnsLayout childrenLeft={<ApplicationMenu/>} childrenRight={
            <CreateNewJobButton/>}><MatchesOverview/></ContentPage3ColumnsLayout></Guard></PageLayout>
    },
    {
        path: URI_CHAT,
        element: <PageLayout><Guard><ContentPage2ColumnsLayout childrenLeft={<ApplicationMenu/>}><ChatOverview/></ContentPage2ColumnsLayout></Guard></PageLayout>
    },
    {
        path: URI_JOBS,
        element: <PageLayout><Guard><ContentPage3ColumnsLayout childrenLeft={<ApplicationMenu/>} childrenRight={
            <CreateNewJobButton/>}><JobsOverview/></ContentPage3ColumnsLayout></Guard></PageLayout>
    },
    {
        path: URI_JOBS_NEW,
        element: <PageLayout><Guard><MultiPageForm menuItems={newJobPages}
                                       childrenLeft={<MultiPageFormMenu
                                           items={newJobPages}/>} childrenRight={<NewJobControl/>}><NewJob/></MultiPageForm></Guard></PageLayout>
    },
    {
        path: URI_JOBS_DETAILS,
        element: <PageLayout><Guard><ContentPage3ColumnsLayout
            childrenLeft={<ApplicationMenu/>} childrenRight={<JobStateControl />}><JobDetails/></ContentPage3ColumnsLayout></Guard></PageLayout>
    },
    {
        path: URI_MATCHES_DETAILS,
        element: <PageLayout><Guard><ContentPage3ColumnsLayout
            childrenLeft={<ApplicationMenu/>} childrenRight={<MatchDetailControl/>}><MatchDetails/></ContentPage3ColumnsLayout></Guard></PageLayout>
    },
    {
        path: URI_COMPANY_NEW_PROFILE,
        element: <PageLayout disableMenu><Guard><MultiPageForm menuItems={newProfilePages}
                                       childrenLeft={<MultiPageFormMenu items={newProfilePages}/>}><NewCompanyProfile/></MultiPageForm></Guard></PageLayout>
    },
    {
        path: URI_COMPANY_PROFILE,
        element: <PageLayout><Guard><CompanyProfile/></Guard></PageLayout>
    }
]);

export function SignedOutRouter() {
    return (
        <RouterProvider router={signedOut}/>
    )
}

function Router() {
    return (
        <RouterProvider router={router}/>
    )
}

export default Router;