import BaseCard from "../cards/BaseCard";
import * as React from "react";
import {MessageObjectTo} from "../../api/tos/chat/MessageObjectTo";
import {getTimestampLabel} from "../../config/firestoreConfig";
import {MultilineTypography} from "../typographys/MultilineTypography";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {Typography} from "@mui/material";
import {forwardRef, useEffect} from "react";

interface IProps {
    message: MessageObjectTo;
    isNew?: boolean;
    onRender: () => void;
}

const TitleBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px'
})

const StyledTypography = styled(Typography)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    marginTop: '-20px',
}));


const ChatMessageCard = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const {message, isNew, onRender} = props;

    useEffect(() => {
        onRender();
    }, [])

    return (
        <div ref={ref}>
            <BaseCard
                id={""}
                topChild={(
                    <>
                        {isNew && <StyledTypography>Neu</StyledTypography>}
                        <TitleBox>
                            <Typography variant="subtitle1" >{message.authorName}</Typography>
                            <Typography>{getTimestampLabel(message.sentAt)}</Typography>
                        </TitleBox>
                    </>
                )}
                bottomChild={(
                    <MultilineTypography>{message.text}</MultilineTypography>
                )}
            />
        </div>
    );
});

export default ChatMessageCard