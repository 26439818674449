import {ReactNode, useState} from "react";
import NavbarTabs from "../../controls/NavbarTabs";
import {Box} from "@mui/material";
import {MultilineTypography} from "../../typographys/MultilineTypography";

interface TogglePageItem {
    title: string;
    description: string;
    content: ReactNode;
}

interface IProps {
    items: TogglePageItem[];
}

function TogglePages(props: IProps) {
    const { items } = props;
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <Box display="flex" flex={1} flexDirection="column">
            <MultilineTypography variant="subtitle1" py={1}>{items[tabIndex].description}</MultilineTypography>
            <NavbarTabs tabs={items.map((item) => item.title)} setTabIndex={setTabIndex} />
            {items[tabIndex].content}
        </Box>
    );
}

export default TogglePages;
